import { API } from "./index";
import { get, post, remove, put } from "../net";
import qs from "querystring";

const getIdFromStatementType = statementType =>
    ({ "budget": 3, "forecast": 4, 3: 3, 4: 4, "cashFlow": 6, 6: 6 }[statementType]);

class ProjectionsService {
    /**
     * Fetch a list of calculations and amounts associated with a particular set of criteria
     * @param {'budget'|'forecast'|'cashflow'} statementType Statement Type
     * @param {number} companyId Company ID
     * @param {number} year Year
     * @param {number} month Month
     */
    async getProjections(statementType, companyId, year, month) {
        let statementTypeId = getIdFromStatementType(statementType);
        if(!statementTypeId)
            throw new Error(`Unknown statement type ${statementType}!`);

        let request = get({
            endpoint: `projections/${companyId}/calculations?${qs.stringify({ statementTypeId, year, month })}`,
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    /**
     * Insert or update a set of calculations and amounts
     * @param {'budget'|'forecast'|'cashflow'} statementType Statement Type
     * @param {number} companyId Company ID
     * @param {number} year Year
     * @param {number} month Month
     * @param {Object} calculations Calculations
     */
    async updateProjections(statementType, companyId, year, month, calculations, draftStatusId, notes) {
        let statementTypeId = getIdFromStatementType(statementType);
        if(!statementTypeId)
            throw new Error(`Unknown statement type ${statementType}!`);

        let request = post({
            endpoint: `projections/${companyId}/calculations`,
            data: { statementTypeId, year, month, calculations, draftStatusId, notes: notes.map((x, i) => ({ label: x.Label, type: x.ValueType, value: x.Value, order: i + 1 })) },
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async deleteProjections(statementType, companyId, year, month) {
        let statementTypeId = getIdFromStatementType(statementType);
        if(!statementTypeId)
            throw new Error(`Unknown statement type ${statementType}!`);

        let request = remove({
            endpoint: `projections/${companyId}?${qs.stringify({ statementTypeId, year, month })}`,
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async getDraftStatus(statementType, companyId, year, month) {
        let statementTypeId = getIdFromStatementType(statementType);
        if(!statementTypeId)
            throw new Error(`Unknown statement type ${statementType}!`);

        let request = get({
            endpoint: `projections/${companyId}/status?${qs.stringify({ statementTypeId, year, month })}`,
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async updateDraftStatus(statementType, companyId, year, month, draftStatusId) {
        let statementTypeId = getIdFromStatementType(statementType);
        if(!statementTypeId)
            throw new Error(`Unknown statement type ${statementType}!`);

        let request = put({
            endpoint: `projections/${companyId}/status`,
            data: { statementTypeId, year, month, draftStatusId },
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async setLockStatus(statementType, companyId, year, month, locked) {
        let statementTypeId = getIdFromStatementType(statementType);
        if(!statementTypeId)
            throw new Error(`Unknown statement type ${statementType}!`);

        let request = put({
            endpoint: `projections/${companyId}/locked`,
            data: { statementTypeId, year, month, locked },
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async getNotes(statementType, companyIds, startYear, startMonth, endYear, endMonth) {
        let statementTypeId = getIdFromStatementType(statementType);
        if(!statementTypeId)
            throw new Error(`Unknown statement type ${statementType}!`);

        if(!Array.isArray(companyIds))
            companyIds = [companyIds];

        let request = get({
            endpoint: `projections/notes?${qs.stringify({ statementTypeId, companyIds, startYear, startMonth, endYear, endMonth })}`,
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }
}

const inst = new ProjectionsService();
export default inst;