//Super cheap event pipeline, for when app state just isn't necessary
//Plus, using the DOM for events is really lame
let handlers = {};

export const on = (event, handler) => {
	if(!handlers.hasOwnProperty(event)) handlers[event] = [];
    handlers[event].push(handler);
};

export const off = (event, handler) => {
    if(!handlers.hasOwnProperty(event)) return null;
    if(handlers[event].includes(handler))
        handlers[event].splice(handlers[event].indexOf(handler), 1);
};

export const one = (event, handler) => {
    on(event, (e) => {
        handler(e);

        if(handlers.hasOwnProperty(event.type) && handler in handlers[event.type]) {
            handlers[event.type].splice(handlers[event.type].indexOf(handler), 1);
        }
    });
};

export const clear = (key = null) => {
    if(key) delete handlers[key];
    else handlers = {};
};

export const emit = (event) => {
	if(handlers.hasOwnProperty(event.type))
		handlers[event.type].forEach(h => h(event));
};

export default {
    on, one, off, clear, emit
};