import React from "react";
import { observer } from "mobx-react";

import UselessLink from "~/components/material/UselessLink";

export default observer(({
    companies,
    onTryAddCompany,
    onDeleteCompany,
    onSelectCompany
}) => (
    <div className="col-md-4 p-0 pr-1 tableFixHead mh-100 overflow-hidden d-flex flex-column">
        <h4 className="my-1 ml-2">
            Company&nbsp;&nbsp;<UselessLink onClick={onTryAddCompany}>
                <h6 className="d-inline">(Add Company)</h6>
            </UselessLink>
        </h4>
        <div className="mh-100 overflow-hidden overflow-y">
            <table className="table table-no-bottom-margin table-bordered table-striped table-hover instant mb-0">
                <thead>
                    <tr>
                        <th className="w-0 bold text-center">&nbsp;</th>
                        <th className="w-0 bold">Company</th>
                    </tr>
                </thead>
                <tbody>
                    {!companies.length && (
                        <tr>
                            <td colSpan="2">Please add a company</td>
                        </tr>
                    )}
                    {companies.map(company => (
                        <tr key={company.value}>
                            <td>
                                <UselessLink onClick={() => onDeleteCompany(company.value)}>&times;</UselessLink>
                            </td>
                            <td>
                                <UselessLink onClick={() => onSelectCompany(company)}>{company.label}</UselessLink>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </div>
));