import React from "react";
import { observer } from "mobx-react";
import classNames from "~/lib/classNames";

import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import NotificationDropdown from "./components/NotificationDropdown";

import NotificationsStore from "~/state/NotificationsStore";

import state from "./state";
import "./index.css";

export default observer(() => {
    let hasImportant = NotificationsStore.hasImportantNotification;
    let count = NotificationsStore.notificationCount;

    return (
        <React.Fragment>
            <IconButton buttonRef={state.buttonRef} onClick={state.toggleNotifications}>
                <Badge badgeContent={count} max={9} classes={{ badge: classNames("notification-dot", hasImportant && "has-important") }}>
                    <Icon>
                        {count == 0 ? "notifications_none" : hasImportant ? "notification_important" : "notifications"}
                    </Icon>
                </Badge>
            </IconButton>
            <NotificationDropdown visible={state.open} element={state.btn} onClose={state.toggleNotifications} />
        </React.Fragment>
    );
});