export default theme => ({
    formControl: {
        display: "block",
        width: "100%"
    },

    chips: {
        display: "flex",
        flexWrap: "wrap"
    },

    chip: {
        margin: theme.spacing(0.25)
    },

    boldchip: {
        margin: theme.spacing(0.25),
        fontWeight: "bold"
    },

    closeSection: {
        display: "block",
        height: "2.2rem",
        background: "#fff"
    },

    box: {
        maxHeight: "100%"
    }
});