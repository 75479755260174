import store from "~/store";

export { default as Metadata } from "./meta";
export { default as Company } from "./company";
export { default as Division } from "./division";
export { default as PortalUser } from "./portaluser";
export { default as Auth } from "./auth";
export { default as AccountMap } from "./accountmapping";
export { default as Statement } from "./statement";
export { default as Projections } from "./projections";
export { default as Notifications } from "./notifications";
export { default as Scheduling } from "./scheduling";
export { default as TermsAndConditions } from "./termsAndConditions"; 
export { default as AnalyticsLinksAPI } from "./analyticsLinks";

//For animated buttons, progress bars, etc.
//Wrap around a service method, like this:
//const saveMapping = withWatcher(AccountMappingService.saveMapping)(...);
export const withWatcher = (func) => (watcher) => func.bind({
    watcher: e => {
        e.progress = Math.round((e.loaded * 100) / e.total);
        return watcher(e);
    }
});

export class APIRequest {
    constructor(request, response, error) {
        this.req = request;
        this.res = response;
        this.error = error;

        if(response) {
            if(response.isError) {
                this.error = response.data;
            } else {
                if(Array.isArray(response)) {
                    this.payload = response;
                } else {
                    if(response.data) {
                        this.payload = response.data;
                    } else this.payload = response;
                }
            }
        }
    }

    toAction(type) {
        return {
            type,
            payload: this.payload
        };
    }

    dispatchAs(type) {
        store.dispatch({
            type,
            payload: this.payload
        });
    }
}

export const API = {
    handleResponse: async function(request, payload, error, successActionType = null, errorActionType = null) {
        let _ = await request;
        let req = new APIRequest(_, payload, error);
        
        if(error) {
            if(errorActionType) {
                store.dispatch({
                    type: errorActionType,
                    payload: error
                });
            }
        } else {
            if(successActionType) {
                store.dispatch({
                    type: successActionType,
                    payload
                });
            }
        }

        return req;
    },

    error: function(req, ex) {
        return new APIRequest(req, null, ex);
    },

    dispatch: function(type, action) {
        store.dispatch({ type, action });
    }
};