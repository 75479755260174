import React from "react";
import { injectState } from "~/hoc/Mobx";

import TextField from "./TextField";

import state from "../state";

@injectState(state, false)
export default class DetailsView extends React.Component {
    render() {
        let {
            name, setName,
            target, setTarget,
            token
        } = this.props;

        return (
            <React.Fragment>
                <TextField autoFocus label="Integration Name"
                    inputProps={{ minLength: 4, maxLength: 48 }}
                    value={name}
                    onChange={e => setName(e.target.value)} />
                <TextField label="Access Token"
                    inputProps={{ minLength: 64, maxLength: 64 }}
                    value={token}
                    disabled />
                <TextField label="Integration Target"
                    inputProps={{ minLength: 12 }}
                    value={target}
                    onChange={e => setTarget(e.target.value)} />
            </React.Fragment>
        );
    }
}