import { SET_TOKEN } from "~/actions/definitions";
import { PortalUser as UserService } from "~/services/api";
import { get } from "./net";
import { store } from "~/store";
import Permissions from "~/services/PermissionsDictionary";
import SocketService from "~/services/SocketService";

export const isValidToken = (token) => 
    token && token.length && token !== "undefined";

export async function loadLocalToken(cb) {
    let localToken = localStorage.getItem("token");

    if(isValidToken(localToken)) {
        store.dispatch({ type: SET_TOKEN, payload: { token: localToken } });

        try {
            let { error } = await UserService.getAccountDetails();
            if(error) return cb(false);

            return cb(true);
        } catch(ex) {
            return cb(false);
        }
    } else return cb(false);
}

export function getCurrentToken() {
    let localToken = localStorage.getItem("token");
    let stateToken = store.getState().auth.token;

    if(stateToken) return stateToken;
    return localToken; // loadLocalToken() first?
}

export async function setToken(token) {
    localStorage.setItem("token", token);
    await SocketService.connect();
    await SocketService.authenticate();
}

export function deleteToken() {
    localStorage.removeItem("token");
    Permissions.reset();
    global.refreshPerms();
}

export function refreshToken() {
    return get({
        endpoint: "security/valid"
    });
}

export const isAuthenticated = () => {
    let { token } = store.getState().auth;
    return isValidToken(token);
};

//This will automatically renew the token every 10 minutes, with a five-minute buffer between the token expiration in case things slow down or go awry
export const TOKEN_JUGGLE_FREQUENCY = 1000 * 60 * 10;
export const juggleToken = () => {
    if(isAuthenticated()) {
        refreshToken().then(({ error, payload, status }) => {
            if(status === 403 || error === "Token is invalid")
                return global.forceLogout(false);

            if(error) {
                console.error(`Error while juggling tokens: ${error}`);
                return global.forceLogout(false);
            }
            
            if(payload && payload.token)
                store.dispatch({ type: SET_TOKEN, payload: { token: payload.token } });
        });
    }
};
export function startTokenJuggler() {
    juggleToken();
    return setInterval(() => juggleToken(), TOKEN_JUGGLE_FREQUENCY);
}