import React from "react";
import Icon from "@material-ui/core/Icon";

const styles = {
    container: {
        position: "relative",
        width: "100%",
        height: 0,
        transform: "translate(0, 2px)"
    },
    overlap: {
        position: "relative",
        transition: "all 450ms"
    }
};

export default ({
    toggled = false,
    icon,
    toggledIcon,
    style = {}
}) => (
    <div style={styles.container}>
        <div style={{...styles.overlap, transform: "translateY(-50%) rotate(" + (toggled ? "90deg" : 0) + ")", opacity: toggled ? 0 : 1}}>
            <Icon style={{...style}}>{icon}</Icon>
        </div>

        <div style={{...styles.overlap, transform: "translateY(-150%) rotate(" + (toggled ? 0 : "-90deg") + ")", opacity: toggled ? 1 : 0}}>
            <Icon style={{...style}}>{toggledIcon}</Icon>
        </div>
    </div>
);