//This looks like jQuery, but it's totally not.
//Used for things like class toggling to animate text input fields
export const what = function (obj) {
    if (obj && obj.toString) {
        return obj.toString().match(/ (\w+)/)[1];
    } else return "null/undefined";
};

export const isReactComponent = (dom) => {
    for (let key in dom) {
        if (key.startsWith("__reactInternalInstance$")) {
            const fiberNode = dom[key];

            if (fiberNode.stateNode)
                return true;
        }
    }
    return false;
};

export const getReactComponent = (dom) => {
    for (let key in dom) {
        if (key.startsWith("__reactInternalInstance$")) {
            const fiberNode = dom[key];
            
            if (fiberNode && fiberNode.return && fiberNode.return.stateNode) {
                return fiberNode.return.stateNode;
            }
        }
    }
    return null;
};

class ElemUtil {
    constructor(el) {
        if (el instanceof NodeList) {
            this.elements = [].slice.call(el);
        } else if (Array.isArray(el)) {
            this.elements = [...el];
        } else if (typeof el === "string") {
            this.elements = [].slice.call(document.querySelectorAll(el));
        } else if (el instanceof Element || el instanceof Document) {
            this.elements = [el];
        } else {
            this.elements = [];
        }
    }

    get length() {
        return this.elements.length;
    }

    get(i = 0) {
        if (this.elements.length) {
            if (this.elements[i] instanceof ElemUtil) return this.elements[i].get();

            return this.elements[i];
        } else return undefined;
    }

    serialize(json = true) {
        let inputs = $(this.elements[0]).find("input,textarea,select");

        let out = {};
        inputs.forEach(elem => {
            let val = $.getValueOfInput(elem);
            out[elem.getAttribute("name") || elem.getAttribute("id")] = val;
        });

        if (json) return out;

        let stringified = Object.getOwnPropertyNames(out).reduce((a, c) => `${a}&${c}=${encodeURIComponent(out[c])}`, "");
        return stringified.length ? stringified.substring(1) : "";
    }

    val(value = null) {
        if (arguments.length) {
            this.elements.forEach(elem => {
                elem.value = value;
                // elem.fireEvent('onchange');
            });
            return this;
        } else if (this.elements.length) {
            return $.getValueOfInput(this.elements[0]);
        } else return undefined;
    }

    focus(all = false) {
        if (this.elements.length) {
            if (all) {
                this.elements.forEach(el => el.focus());
            } else this.elements[0].focus();
        }
        return this;
    }

    blur() {
        this.elements.map(elem => elem.blur());
        return this;
    }

    forEach(...args) {
        this.elements.forEach.apply(this.elements, args);
    }

    map(...args) {
        this.elements.map.apply(this.elements, args);
    }

    reduce(...args) {
        this.elements.reduce.apply(this.elements, args);
    }

    filter(...args) {
        this.elements.filter.apply(this.elements, args);
    }

    addClass(name) {
        this.elements.forEach(elem => elem.classList.add(name));
        return this;
    }

    removeClass(name) {
        this.elements.forEach(elem => elem.classList.remove(name));
        return this;
    }

    toggleClass(name) {
        this.elements.forEach(elem => elem.classList.toggle(name));
        return this;
    }

    hasClass(name) {
        let answers = this.elements.map(elem => elem.classList.contains(name));
        return !(answers.includes(false));
    }

    _siblings(selector = null, me = false) {
        let siblings = [];

        //Moved outside for-loop to avoid JS hint, which is annoying.
        let checkSiblingsAndSelector = c => {
            if (me) return c.matches(selector);
            return c !== elem && c.matches(selector);
        };

        let checkSiblings = c => {
            if (me) return true;
            return c !== elem;
        };

        for (var elem of this.elements) {
            if (selector) {
                siblings.push(...Array.prototype.filter.call(elem.parentNode.children, checkSiblingsAndSelector));
            } else {
                siblings.push(...Array.prototype.filter.call(elem.parentNode.children, checkSiblings));
            }
        }

        return siblings;
    }

    siblings(selector = null, me = false) {
        return this._siblings(selector, me).map(c => $(c));
    }

    parent(selector) {
        if (selector) {
            return $(this.elements.reduce((set, x) => {
                let parent = x.parentNode;

                if (parent && parent instanceof Element) {
                    if (!parent.matches(selector)) {
                        let upper = $(parent).parent(selector);

                        if (upper && upper.length) {
                            set.push(...upper.elements);
                        }
                    } else set.push(parent);
                }

                return set;
            }, []));
        } else return $(this.elements.map(x => x.parentNode));
    }

    is(selector) {
        for (var elem of this.elements) {
            if (!elem.matches(selector)) return false;
        }
        return true;
    }

    hasParent(selector) {
        let target = this.elements[0].parentElement;

        while(target && target !== document.body) {
            if(target.matches(selector))
                return true;

            target = target.parentElement;
        }

        return false;
    }

    find(selector) {
        let found = [];
        this.elements.forEach(x => {
            let descendants = x.querySelectorAll(selector);

            if (descendants)
                found.push(...([].slice.call(descendants)));
        });
        return $(found);
    }

    trigger(name, data = undefined) {
        let event = null;
        if (window.CustomEvent) {
            event = new CustomEvent(name, data);
        } else {
            event = document.createEvent("CustomEvent");
            event.initCustomEvent(name, true, true, data);
        }

        this.elements.forEach(elem => elem.dispatchEvent(event));
        return this;
    }

    toReact() {
        let components = [];

        this.elements.forEach(elem => {
            let component = getReactComponent(elem);

            if (component !== null)
                components.push(component);
        });

        return components.length === 1 ? components[0] : components;
    }
}

export default function $(func = null) {
    if(typeof func === "function") {
        return ready(func);
    } else return new ElemUtil(...arguments);
}

$.on = (event, handler, element = null) => {
    if (element) {
        if (element === window) return window.addEventListener(event, handler);
        if (element === document) return document.addEventListener(event, handler);

        element.addEventListener(event, handler);
    } else {
        window.addEventListener(event, handler);
    }
};

function ready(fn) {
    if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
        fn();
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
}

$.ready = ready;

let getTime = () => +new Date();
$.animate = ({ elem, style, unit, from, to, time, prop }) => {
    if (!elem) return;

    let start = getTime();
    let timer = setInterval(() => {
        let step = Math.min(1, (getTime() - start) / time);

        if (prop) {
            elem[style] = (from + step * (to - from))+unit;
        } else {
            elem.style[style] = (from + step * (to - from))+unit;
        }
        
        if (step === 1)
            return clearInterval(timer);
    }, 25);

    if (prop) {
          elem[style] = from+unit;
    } else {
          elem.style[style] = from+unit;
    }
};

$.getValueOfInput = (elem) => {
    let { nodeName = "", type, checked, value, options } = elem;
    if (nodeName.toUpperCase() === "INPUT") {
        if (["text", "hidden", "password", "button", "reset", "submit", "email", "number"].includes(type.toLowerCase())) {
            return value;
        } else if (["checkbox", "radio"].includes((type)) && checked) {
            return checked || false;
        }
    } else if (nodeName === "TEXTAREA") {
        return value;
    } else if (nodeName === "SELECT") {
        if (type === "select-one") {
            return value;
        } else if (type === "select-multiple") {
            return options.reduce((x, a) => {
                if (a.selected)
                    x.push(a.value);
                return x;
            }, []);
        }
    }
};

$.scrollTop = () => {
    window.scroll({top: 0, left: 0, behavior: "smooth" });
};

export function mixClass() { return [...arguments].join(" "); }
global.$ = $;