import React from "react";
import classNames from "~/lib/classNames";
import genId from "shortid";

import NotificationService from "~/services/api/notifications";

import { withSnackbar } from "notistack";
import { action, observable, computed, toJS } from "mobx";

import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";

import getNotificationExtras from "~/services/NotificationParser";
import findIndex from "lodash/findIndex";

class NotificationsStore {
    @observable notifications = [];

    @computed
    get notificationCount() {
        return this.notifications.filter(x => !x.read).length;
    }

    @computed
    get hasImportantNotification() {
        return !!this.notifications.find(x => !x.read && x.priority == 2);
    }

    @computed
    get hasNewNotifications() {
        return !!this.notifications.find(x => !x.read);
    }

    @action
    modifyNotification = ({
        uid, ...newProps
    }) => {
        let index = findIndex(this.notifications, x => x.uid == uid);

        if(index > -1) {
            let old = toJS(this.notifications[index]);
            let notification = {
                ...old,
                ...newProps
            };

            if(notification.metadata && typeof notification.metadata == "string")
                notification.metadata = JSON.parse(notification.metadata) || {};
    
            let extras = getNotificationExtras(notification);
    
            notification = {
                ...notification,
                ...extras
            };

            this.notifications[index] = notification;
    
            if(notification.priority == 1000 && !notification.read) {
                notification.popupId = showNotification(notification);
            }
        }
    }

    @action
    createNotification = ({
        uid = genId(),
        showDuration,
        deletable = true,
        pinned = false,
        read = false,
        priority = 0, //-1 is trivial (never popup), 0 is normal (popup if new), 1 is important (always popup)
        type = "info:generic",
        icon = "outlined_flag",
        spinningIcon = false,
        contents,
        color = "green",
        details = "",
        metadata = {},
        date = +new Date(),
        actions
    }) => {
        let notification = { uid, showDuration, deletable, pinned, priority, type, icon, spinningIcon, color, contents, details, read, date, metadata, actions };

        if(metadata && typeof metadata == "string")
            notification.metadata = JSON.parse(metadata) || {};

        let extras = getNotificationExtras(notification);

        notification = {
            ...notification,
            ...extras
        };
    
        if(priority == 1000 && !read) {
            notification.popupId = showNotification(notification);
        }

        //We have to get funky because things may get pinned and then later notifications could be added, but pinned are supposed to stay at the top...
        let added = false;
        for(let i = 0; i < this.notifications.length; i++) {
            if(notification.pinned && !this.notifications[i].pinned) {
                this.notifications.splice(i, 0, notification); added = true; break;
            } else if(notification.pinned && this.notifications[i].pinned) {
                this.notifications.splice(i, 0, notification); added = true; break;
            } else if(!notification.pinned && !this.notifications[i].pinned) {
                this.notifications.splice(i, 0, notification); added = true; break;
            }
        }

        if(!added) { //If all else fails, just put it in the bottom of the list.
            this.notifications.push(notification);
        }
    }

    @action
    replaceNotifications = (notifications = []) => {
        this.notifications.replace([]);
        for(let notif of notifications) {
            this.createNotification(notif);
        }
    }

    @action
    deleteNotification = id => {
        let index = findIndex(this.notifications, x => x.uid == id);

        if(index > -1) {
            let notification = this.notifications.find(x => x.uid == id);
            hideNotification(notification);
            this.notifications.splice(index, 1);
        }
        
        NotificationService.clearById(id);
    }

    @action
    markAllRead = () => {
        this.notifications.forEach(x => {
            x.read = true;
            hideNotification(x);
        });

        NotificationService.markAsRead();
    }

    @action
    markAsRead = id => {
        let notification = this.notifications.find(x => x.uid == id);

        if(notification) {
            notification.read = true;
            hideNotification(notification);
        }
        
        NotificationService.markAsRead(id);
    }

    @action
    clearSimilar = uid => {
        let { type } = this.notifications.find(x => x.uid == uid);

        this.notifications.replace(this.notifications.filter(x => x.type != type));
        return NotificationService.clearByType(type);
    }

    @action clearForDivision = uid => {
        let { metadata } = this.notifications.find(x => x.uid == uid);
        if(!metadata || !metadata.companyId) return;

        this.notifications.replace(this.notifications.filter(x => x.metadata && x.metadata.companyId ? x.metadata.companyId != metadata.companyId : true));
        return NotificationService.clearForCompany(metadata.companyId);
    }

    @action
    clearAll = () => {
        this.notifications.forEach(hideNotification);
        this.notifications.replace([]);
        NotificationService.clearAll();
    }
}

let _show = () => null;
let _hide = () => null;

@withSnackbar
export class Provider extends React.Component {
    componentDidMount() {
        _show = this.showNotification;
        _hide = this.hideNotification;
    }

    wrapAction = (notification, x) => () => {
        store.markAsRead(notification.uid);
        return x.onClick(notification);
    }

    hideNotification = notification => {
        if(notification && notification.popupId) {
            this.props.closeSnackbar(notification.popupId);
            delete notification.popupId;
        }
    }

    showNotification = notification => {
        let {
            icon = "text_fields",
            showDuration = 7000,
            contents,
            actions
        } = notification;

        let render = (
            <div className={classNames("notification", (!contents || !contents.length) && "empty")}>
                <Icon className="notification-icon">{icon}</Icon>
                <span className="notification-body">{contents}</span>
            </div>
        );

        let extras = {};

        if(actions && actions.notificationActions) {
            if(actions.notificationActions === "dismiss") {
                extras.action = (
                    <Button size="small" color="primary">Dismiss</Button>
                );
            } else if(Array.isArray(actions.notificationActions)) {
                extras.action = actions.notificationActions.map(x => (
                    <Button size="small" color="primary" key={genId()} onClick={this.wrapAction(notification, x)}>{x.label}</Button>
                ));
            }
        }

        return this.props.enqueueSnackbar(render, {
            autoHideDuration: showDuration,
            ...extras
        });
    }

    render = () => null;
}

export const showNotification = (...args) => _show(...args);
export const hideNotification = (...args) => _hide(...args);

const store = new NotificationsStore();
export default store;

global.NotificationsStore = store;
global.showNotification = showNotification;
global.hideNotification = hideNotification;