import getSubdomain from "~/lib/subdomain";

export const TENANT_DETAILS = "tenant-details";
export const GET_TENANT = "get-tenant";

export const getTenantDetails = (tenant) => {
    // let request = get({
    //     endpoint: 'tenant/details',
    //     data: {
    //         params: { tenant }
    //     },
    //     auth: false
    // });

    return {
        type: TENANT_DETAILS,
        // payload: request
        payload: {
            payload: {
                color: "blue",
                name: "FinLogic LLC"
            }
        }
    };
};

export const getCurrentTenant = () => {
    let opts = {type: GET_TENANT, payload: null};
    let subdomain = getSubdomain();

    if(subdomain) opts.payload = subdomain;
    return opts;
};