import React from "react";
import { Route } from "react-router-dom";
import except from "except";

import Template from "~/views/_template";

export default class TemplatedRoute extends React.Component {
    render() {
        let { render, component: Component } = this.props;
        if(render) {
            return <Route {...this.props} />;
        } else {
            return (
                <Route render={(props) => (
                    <Template>
                        <Component {...props} />
                    </Template>
                )} {...except(this.props, "component", "render")} />
            );
        }
    }
}