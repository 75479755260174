import { API } from "./index";
import { get, post, BASE_URL } from "../net";
import qs from "querystring";

class ImportService {
    async uploadImportFile(month, year, divisionId, statementTypeId, useParentMappings, file) {
        let form = new FormData();
        form.append("month", month);
        form.append("year", year);
        form.append("companyId", divisionId);
        form.append("statementTypeId", statementTypeId);
        form.append("useParentMappings", useParentMappings);
        form.append("file", file);
        form.append("ignoreDate", true);
        
        let request = post({
            endpoint: "import",
            form: true,
            data: form,
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.error(request, ex);
        }
    }

    async saveImport(mapping, batchId) {
        let request = post({
            endpoint: batchId ? "/import/failedImports/" + encodeURIComponent(batchId) : "import/save",
            data: { importmodel: mapping, fileId: mapping.fileId },
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.error(request, ex);
        }
    }

    async batchManualImport(imports) {
        let request = post({
            endpoint: "integration/qb/manualImportBatch",
            data: { imports },
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.error(request, ex);
        }
    }
}

class AccountMappingService extends ImportService {
    async getExistingMappings(companyId, statementType) {
        let request = get({
            endpoint: `accountmapping?${qs.stringify({ companyId, statementType })}`,
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.error(request, ex);
        }
    }

    async uploadMappingFile(divisionId, mappingTypeId, file) {
        let form = new FormData();
        form.append("companyId", divisionId);
        form.append("mappingType", mappingTypeId);
        form.append("file", file);
        
        let request = post({
            endpoint: "accountmapping",
            form: true,
            data: form,
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.error(request, ex);
        }
    }

    async saveMapping(mapping, isUpdate) {
        let endpoint;

        if (isUpdate) {
            endpoint = "accountmapping/update";
        } else {
            endpoint = "accountmapping/save";
        }

        let request = post({
            endpoint: endpoint,
            data: { accountmapping: mapping },
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.error(request, ex);
        }
    }

    async manualImport(companyId, year, month, reportType) {
        let request = post({
            endpoint: "integration/qb/manualImport",
            data: { companyId, year, month, reportType },
            watcher: this.watcher,
            overrideTimeout: true
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.error(request, ex);
        }
    }

    redirectToQuickbooksOnlineOAuth(opts) {
        window.authorizingQuickbooks = true;
        window.location.href = `${BASE_URL}/integration/qb/oauthBegin?params=${encodeURIComponent(JSON.stringify(opts))}`;
    }

    async qboManualImport(companyId, year, month, reportType) {
        let request = post({
            endpoint: "/integration/qbo/manualImport",
            data: { companyId, year, month, reportType },
            watcher: this.watcher,
            overrideTimeout: true
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.error(request, ex);
        }
    }

    async getStandardAccounts(statementType, companyId = -1) {
        let request = get({
            endpoint: `accountmapping/fetchStandardAccounts?${qs.stringify({ statementType, companyId })}`,
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.error(request, ex);
        }
    }

    async addStandardAccount(data) {
        let request = post({
            endpoint:  `accountmapping/addStandardAccount`,
            data,
            watcher: this.watcher
        });
        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.error(request, ex);
        }
    }

    async getLatestMonthForIncomeStatement(year, divisionId) {
        let form = new FormData();
        form.append("month", 1);
        form.append("year", year);
        form.append("companyId", divisionId);
        form.append("statementTypeId", 2);
        form.append("file", "_fake");

        let request = post({
            endpoint: "import",
            form: true,
            data: form,
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.error(request, ex);
        }
    }

    async fetchFailedImport(batchId) {
        let request = get({
            endpoint: "import/failedImports/" + encodeURIComponent(batchId),
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.error(request, ex);
        }
    }

    async customStandardAccountExists(standardAccount, statementType) {
        let request = post({
            endpoint:  `accountmapping/customStandardAccountExists`,
            data: { standardAccount, statementType },
            watcher: this.watcher
        });
        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.error(request, ex);
        }
    }

    safe = {
        getStandardAccounts: async (statementTypeId, companyId = -1) => {
            let targetStatementTypeId = [3,4].includes(statementTypeId) ? 2 : statementTypeId;

            try {
                let req = await this.getStandardAccounts(targetStatementTypeId, companyId);
                if(req.error) return [];

                if(req.payload && Array.isArray(req.payload.standardAccounts)) {
                    return req.payload.standardAccounts;
                } else return [];
            } catch(ex) {
                return [];
            }
        },
        getStandardAccountsWithProjection: async ({statementType, companyId, year, month}) => {
            let request = get({
                endpoint: `accountmapping/fetchStandardAccounts?${qs.stringify({ statementType, companyId, year, month })}`,
                watcher: this.watcher
            });
    
            try {
                let req = await request;
                if(req.payload && Array.isArray(req.payload.standardAccounts)) {
                    return req.payload.standardAccounts;
                } else return [];
            } catch(ex) {
                return API.error(request, ex);
            }
        }
    }
}

export default new AccountMappingService();