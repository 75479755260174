import React from "react";
import { observer } from "mobx-react";

import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Tooltip from "~/components/material/Tooltip";

import SchedulingState from "../state";

export default observer(() => (
    <div className="d-flex flex-row justify-content-between align-items-center">
        {SchedulingState.page != 0 && (
            <Tooltip placement="left" title="Go to Page 1">
                <IconButton onClick={() => SchedulingState.jumpToPage(0)} text-color="white" className="m-0">
                    <Icon>first_page</Icon>
                </IconButton>
            </Tooltip>
        )}
        <IconButton onClick={SchedulingState.previousPage} text-color="white" className="m-0">
            <Icon>chevron_left</Icon>
        </IconButton>
        <span>Page {SchedulingState.page + 1} / {SchedulingState.totalPages + 1}</span>
        <IconButton onClick={SchedulingState.nextPage} text-color="white" className="m-0">
            <Icon>chevron_right</Icon>
        </IconButton>
        <Tooltip placement="left" title="Create new Schedule">
            <IconButton onClick={SchedulingState.onCreateSchedule} className="ml-3" text-color="white">
                <Icon>playlist_add</Icon>
            </IconButton>
        </Tooltip>
    </div>
));