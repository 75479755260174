import React from "react";
import AsyncComponent from "~/components/AsyncComponent";

import Dialog from "@material-ui/core/Dialog";
import Icon from "@material-ui/core/Icon";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";

import classNames from "~/lib/classNames";
import generateUid from "shortid";

const Transition = props => (
    <Slide direction="up" {...props} />
);

let _showPopup = e => null;

export const showPopup = props =>
    _showPopup(props);

export default showPopup;

export class PopupProvider extends AsyncComponent {
    popups = {};

    state = {
        popups: []
    }

    componentWillMount() {
        _showPopup = async({ onClose = x => x, ...popupProps }) => {
            let popupId = generateUid();

            await this.updateState({ popups: [...this.state.popups, { key: popupId, props: popupProps }] });
            let modal = this.popups[popupId];
            let result = await modal.popup();
            await this.updateState({ popups: this.state.popups.filter(x => x.key != popupId) });
            delete this.popups[popupId];
            
            return onClose(result);
        };
    }

    render() {
        let { popups } = this.state;
        return popups.reduce((_, popup) => (
            <Popup key={popup.key} ref={ref => this.popups[popup.key] = ref} {...popup.props} />
        ), []);
    }
}

export class Popup extends React.PureComponent {
    id = generateUid();

    state = {
        open: false
    }

    open = this.popup;

    popup = () => new Promise(resolve => {
        this.promise = resolve;
        this.setState({ open: true });
    });

    onClose = (ok = false) => this.setState({ open: false }, () => {
        if(this.promise) {
            this.promise(ok);
            this.promise = null;
        }
    });

    render() {
        let { title, shrinkAwayFromNav = false, actions, children, className, color = "green", textColor = "white", classes = {}, barProps = {}, ...otherProps } = this.props;
        let { open } = this.state;

        let Children = null;
        let Action = null;

        if(typeof children == "function") {
            Children = children;
        } else {
            Children = () => children;
        }

        if(typeof actions == "function") {
            Action = actions;
        } else {
            Action = () => actions;
        }

        return (
            <Dialog closeAfterTransition fullScreen open={open} onClose={() => this.onClose(false)} className={className} classes={{ paper: classes.paper, container: classNames(shrinkAwayFromNav && "cfofxn-offset") }} BackdropProps={{ classes: { root: classNames(shrinkAwayFromNav && "cfofxn-offset-backdrop") } }} TransitionComponent={Transition} {...otherProps}>
                <AppBar className={classes.appbar} {...barProps}>
                    <Toolbar text-color={textColor} color={color} className={classes.toolbar}>
                        <IconButton color="inherit" onClick={() => this.onClose(false)} aria-label="Close" className={classes.closeButton}>
                            <Icon children="close" />
                        </IconButton>
                        
                        {title && (
                            <Typography variant="h6" color="inherit" className={classNames("flex-1", classes.title)} children={title} />
                        )}

                        <Action closePopup={this.onClose} />
                    </Toolbar>
                </AppBar>

                <Children closePopup={this.onClose} />
            </Dialog>
        );
    }
}