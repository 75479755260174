import React from "react";
import { Link } from "react-router-dom";

export default React.forwardRef((props, ref) => {
    let onClickHandler = e => {
        e.preventDefault();
        if(props.onClick && !props.disabled)
            props.onClick(e);
    };

    return (
        <Link to="#/" {...props} innerRef={ref} onClick={onClickHandler}>{props.children}</Link>
    );
});