import React from "react";
import ReactDOM from "react-dom";
import "./assets";
import App, { ErrorBoundary } from "./app";
import { unregister } from "./registerServiceWorker";
import { Manager } from "react-popper";

import Permissions from "~/services/PermissionsDictionary";

class Application extends React.Component {
    constructor() {
        super();
        global.refreshPerms = () => this.app.forceUpdate();
    }

    render() {
        return (            
            <ErrorBoundary>
                <Manager>
                    <App ref={ref => this.app = ref} />
                </Manager>
            </ErrorBoundary>
        );
    }
}

ReactDOM.render(<Application />, document.getElementById("root"));
unregister();

//We want to clean everything up and log them out when they leave the site.
window.onbeforeunload = () => {
    if(!Permissions.hasGeneralPermission("tweaks.noLogout") && !window.authorizingQuickbooks) {
        localStorage.clear();
    }
};