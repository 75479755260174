import React from "react";

import Radio from "@material-ui/core/Radio";
import WeekDaySelector from "../../DayOfWeekSelector";
import { TimePicker } from "material-ui-pickers";

import moment from "moment";
import isEqual from "lodash/isEqual";

import * as LanguageService from "~/services/ui/LanguageService";

export default class MonthlyFields extends React.PureComponent {
    state = {
        mode: 1,
        intervalDays: 1,
        intervalMonths: 0,

        targetMatch: 1,
        targetDay: {},
        targetMonths: 0,

        time: moment.now()
    }

    componentDidMount() {
        let { initialData } = this.props;

        if(initialData) {
            if(initialData.details.mode == "interval") {
                this.setState({
                    mode: 1,
                    intervalDays: initialData.details.dates.day,
                    intervalMonths: initialData.details.dates.month,
                    time: moment(initialData.details.time)
                });
            } else {
                this.setState({
                    mode: 2,
                    targetMatch: initialData.details.dates.day,
                    targetDay: { [initialData.details.dates.weekday]: true },
                    targetMonths: initialData.details.dates.month,
                    time: moment(initialData.details.time)
                });
            }
            
        }
    }

    onChangeField = name => val => {
        if(val.target) {
            this.setState({ [name]: val.target.value });
        } else {
            this.setState({ [name]: val });
        }
    };

    componentDidUpdate(prevProps, prevState) {
        let { mode, intervalDays, intervalMonths, targetMatch, targetDay, targetMonths, time } = this.state;

        if(!isEqual(this.state, prevState)) {
            if(mode == 1) {
                if(intervalDays > 0 && intervalMonths > 0 && time)
                    this.props.onChange({ mode: "interval", dates: { day: intervalDays, month: intervalMonths }, time });
            } else {
                if(targetMatch > 0 && targetDay && Object.keys(targetDay).length && targetMonths && time)
                    this.props.onChange({ mode: "specific", dates: { day: targetMatch, weekday: Object.keys(targetDay)[0], month: targetMonths }, time });
            }
        }
    }

    render() {
        let { mode, intervalDays, intervalMonths, targetMatch, targetDay, targetMonths, time } = this.state;

        return (
            <React.Fragment>
                <div className="radio-group">
                    <div className="option">
                        <Radio checked={mode == 1} onClick={() => this.setState({ mode: 1 })} />
                        <div className="d-flex align-items-center nowrap">
                            Execute on the {(
                                <input type="text" className="small-size m-0 ml-3 mr-0 text-center"
                                    value={intervalDays}
                                    onFocus={e => e.target.setSelectionRange(0, e.target.value.length)}
                                    onChange={this.onChangeField("intervalDays")} />
                            )}{LanguageService.getOrdinal(intervalDays)} day of every {(
                                <input type="text" className="small-size m-0 mx-3 text-center"
                                    value={intervalMonths}
                                    onFocus={e => e.target.setSelectionRange(0, e.target.value.length)}
                                    onChange={this.onChangeField("intervalMonths")} />
                            )} months
                        </div>
                    </div>
                    <div className="option">
                        <Radio className="align-items-start" checked={mode == 2} onClick={() => this.setState({ mode: 2 })} />
                        <div className="d-flex flex-column" style={{marginTop:".75rem"}}>
                            <div className="d-flex align-items-center nowrap">
                                Execute on the {(
                                    <input type="text" className="small-size m-0 ml-3 mr-0 text-center"
                                        value={targetMatch}
                                        onFocus={e => e.target.setSelectionRange(0, e.target.value.length)}
                                        onChange={this.onChangeField("targetMatch")} />
                                )}{LanguageService.getOrdinal(targetMatch)}&nbsp;&nbsp;&nbsp;{(
                                    <WeekDaySelector isRadio
                                        value={targetDay} onChange={this.onChangeField("targetDay")} />
                                )}
                            </div>
                            <div className="d-flex align-items-center nowrap">
                                of every {(
                                    <input type="text" className="small-size m-0 mx-3 text-center"
                                        value={targetMonths}
                                        onFocus={e => e.target.setSelectionRange(0, e.target.value.length)}
                                        onChange={this.onChangeField("targetMonths")} />
                                )} months
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ml-2 d-flex flex-row align-items-center">
                    Execute import at {(
                        <TimePicker className="mx-3" value={time} onChange={this.onChangeField("time")} />
                    )}
                </div>
            </React.Fragment>
        );
    }
}