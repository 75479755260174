import { observable, action } from "mobx";

export default class CfoAssistantState {
    @observable open = false;
    @observable modalOpen = false;
    @observable topic = {};

    @action onToggle = e => {
        e.preventDefault();
        e.stopPropagation();
        this.open = !this.open;
    }

    @action onClose = () => this.open = false;

    @action onSelectTopic = topic => {
        this.topic = topic;
        this.open = false;
        this.modalOpen = true;
    }

    @action onCloseModal = () => {
        this.topic = {};
        this.modalOpen = false;
    }
}