import { minLength } from "~/lib/validation";
import getSubdomain from "~/lib/subdomain";

export default () => {
    let subdomain = getSubdomain();
    
    return [
        {
            name: "alias",
            label: "Company Alias",
            type: (subdomain && subdomain.length) ? "hidden" : "text",
            defaultValue: (subdomain && subdomain.length) ? subdomain : undefined,
            required: true,
            props: {
                icon: "building"
            }
        },
        {
            name: "email",
            label: "Email Address",
            type: "email",
            required: true,
            props: {
                icon: "envelope"
            }
        },
        {
            name: "password",
            label: "Password",
            type: "password",
            validation: [ minLength(8) ],
            required: true,
            props: {
                icon: "unlock-alt"
            }
        }
    ];
};