import React from "react";
import { observer } from "mobx-react";

import Popover from "@material-ui/core/Popover";
import NotificationList from "../NotificationList";

import NotificationStore from "~/state/NotificationsStore";

@observer
export default class NotificationDropdown extends React.Component {
    wrapper = React.createRef();

    get anchor() {
        return {
            horizontal: "right",
            vertical: "bottom"
        };
    }

    get position() {
        return {
            horizontal: "right",
            vertical: "top"
        };
    }

    wrapClickHandler = item => {
        let { onClose } = this.props;

        return e => {
            e.preventDefault();
            e.stopPropagation();

            NotificationStore.markAsRead(item.uid);
            item.actions.listAction(item);
            return onClose();
        };
    }

    render() {
        let { visible = false, element, onClose } = this.props;

        return (
            <Popover open={visible} anchorEl={element} anchorOrigin={this.anchor} transformOrigin={this.position} onClose={onClose}>
                <div className="notification-list-wrapper" ref={this.wrapper}>
                    <NotificationList wrapClickHandler={this.wrapClickHandler} wrapper={this.wrapper} />
                </div>
            </Popover>
        );
    }
}