import PermissionsDictionary, { defaults } from "~/definitions/Permissions";
import get from "lodash/get";
import set from "lodash/set";

const specialGet = (a, b, c) => {
    let x = get(a, b, c);

    if(!!!x) return c;
    return x;
};

const isValidPermission = (perms, path, companyId = null) => {
    let perm = specialGet(perms, path, false);

    if(Array.isArray(perm)) {
        return !!companyId ? perm.includes(companyId) : !!perm.length;
    } else if(typeof perm === "boolean") {
        return perm === true;
    } else {
        return !!perm;
    }
};

//TODO: Update on JWT change
export class NewPermissionsDictionary {
    permissions = {
        ...defaults
    };

    setPermission = (path, val) => set(this.permissions, path, val);
    isAdmin = () => specialGet(this.permissions, "admin.admin", []).includes(null);
    hasCompanyAdmin = () => specialGet(this.permissions, "admin.admin", []).length > 0;
    isCompanyAdmin = companyId => isValidPermission(this.permissions, "admin.admin", companyId) || this.isAdmin();

    hasPermission = path => isValidPermission(this.permissions, path) || this.isAdmin();
    hasPermissionForCompany = (path, companyId) => isValidPermission(this.permissions, path, companyId) || this.isCompanyAdmin(companyId);
    // hasPermissionForDivision = (path, divisionId, parentCompanyId) =>
    //     isValidPermission(this.permissions, path, divisionId)
    //     || isValidPermission(this.permissions, path, parentCompanyId)
    //     || this.isCompanyAdmin(divisionId)
    //     || this.isCompanyAdmin(parentCompanyId);
    hasPermissionForDivision = (path, divisionId, companyId) => this.hasPermissionForCompany(path, divisionId) || this.isCompanyAdmin(companyId);
    
    //View = 1
    //Create = 2
    //Update = 3
    //Delete = 4
    //Admin = 1000
    getPermissionLevel = (company, module) => {
        let level = 0;
        if(company === -1) {
            //Admins are by default high-ranking
            if(this.isAdmin()) return 1000;

            if(this.hasGeneralPermission(`${module}.read`)) level++;
            if(this.hasGeneralPermission(`${module}.create`) || this.hasGeneralPermission(`${module}.trigger`) || this.hasGeneralPermission(`${module}.import`)) level++;
            if(this.hasGeneralPermission(`${module}.update`)) level++;
            if(this.hasGeneralPermission(`${module}.delete`)) level++;
            if(this.hasGeneralPermission(`${module}.unfinalize`)) level++;
        } else {
            //Admins are by default high-ranking
            if(this.isCompanyAdmin(company)) return 1000;

            if(this.hasPermissionForCompany(`${module}.read`, company)) level++;
            if(this.hasPermissionForCompany(`${module}.create`, company) || this.hasPermissionForCompany(`${module}.trigger`, company) || this.hasPermissionForCompany(`${module}.import`, company)) level++;
            if(this.hasPermissionForCompany(`${module}.update`, company)) level++;
            if(this.hasPermissionForCompany(`${module}.delete`, company)) level++;
            if(this.hasPermissionForCompany(`${module}.unfinalize`, company)) level++;
        }

        return level;
    }

    hasGeneralPermission = path => {
        let val = specialGet(this.permissions, path, false);

        if(Array.isArray(val) && val.length) return true;
        if(val === true) return true;

        if(/^tweaks\./g.test(path)) //Do not check for admin to enable the tweaks module
            return false;

        return this.isAdmin() || this.hasCompanyAdmin();
    }

    getPermissionNode = path => specialGet(this.permissions, path, false);

    update(dictionary) {
        this.permissions = defaults;
        
        if(dictionary) {
            Object.keys(PermissionsDictionary).forEach(module => {
                let nodes = PermissionsDictionary[module].map(node => `${module}.${node}`);
                for(let node of nodes) {
                    let target = dictionary.filter(x => x.Permission === node);
                    let current = this.getPermissionNode(node);
                    if(target.length) {
                        if(Array.isArray(current)) {
                            this.setPermission(node, [...current, ...target.map(x => x.CompanyId)]);
                        } else if(current !== true) {
                            this.setPermission(node, true);
                        }
                    }
                }
            });
        }
    }

    reset() {
        this.permissions = {
            ...defaults
        };
    }
}

const inst = new NewPermissionsDictionary();
export default inst;