import React from "react";
import { Item } from "~/components/material/Dropdown";
import Permissions from "~/services/PermissionsDictionary";

export const PermissionBasedItem = ({ module, level = 0, ...other }) => {
    if(Permissions.getPermissionLevel(-1, module) > level){
        return (
            <Item {...other} />
        );
    } else return null;
};

export const NodeBasedItem = ({ node, ...other }) => {
    if(Array.isArray(node) && node.some(x => Permissions.hasGeneralPermission(x))) {
        return (
            <Item {...other} />
        );
    } else if(Permissions.hasGeneralPermission(node)){
        return (
            <Item {...other} />
        );
    } else return null;
};