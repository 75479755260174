import React from "react";

import CompanyDropdown from "~/components/form/fields/CompanyDropdown";
import DivisionDropdown from "~/components/form/fields/DivisionDropdown";
import Select from "~/components/material/Select";
import SelectChip from "~/components/form/SelectChip";

import * as dictionaries from "./dictionaries";
import * as LanguageService from "~/services/ui/LanguageService";

import get from "lodash/get";

export default class ScheduleEditorModal extends React.PureComponent {
    state = {
        name: get(this.props, "initialData.name", "Untitled Schedule"),
        period: get(this.props, "initialData.details.period", null),
        company: get(this.props, "initialData.details.company", null),
        divisions: get(this.props, "initialData.details.divisions", null),
        statementTypes: get(this.props, "initialData.details.statementTypes", null)
    }

    componentDidMount() {
        this.props.modalProps.hideOkay = true;

        if(this.state.period && typeof this.state.period == "string") {
            this.setState({
                period: dictionaries.periods.find(x => x.value == this.state.period)
            });
        }
    }

    componentDidUpdate() {
        let { period, company, divisions, statementTypes, mode, dates, time, name } = this.state;
        let { modalProps, setResult } = this.props;

        if(period && company && divisions && statementTypes && dates && time) {
            modalProps.hideOkay = false;

            setResult({
                details: {
                    period : period.value,
                    company: company.id,
                    divisions,
                    statementTypes,
                    mode,
                    dates,
                    time,
                    name
                },
                name, time, period,
                statementTypes,
                target: company.name
            });
        } else {
            modalProps.hideOkay = true;
        }
    }

    onSelectPeriod = period => this.setState({ period });
    onChangeCompany = company => this.setState({ company });
    onChangeDivision = (divisions = []) => this.setState({ divisions: divisions.map(x => x.value) });
    onChangeStatementTypes = (statementTypes = []) => this.setState({ statementTypes: statementTypes.map(x => x.value) });

    render() {
        let { name, period, company, divisions, statementTypes } = this.state;

        let Contents = () => null;

        if(period) {
            Contents = dictionaries.fields[period.value] || (() => null);
        }
        
        let companyId = !isNaN(Number(company)) ? company : get(company, "id", null);
        let companyName = get(company, "name", "Company Level");
        
        return (
            <React.Fragment>
                <input type="text" className="d-block w-100 mb-4"
                    value={name}
                    onFocus={e => e.target.setSelectionRange(0, e.target.value.length)}
                    onChange={e => this.setState({ name: e.target.value })} />

                <Select placeholder="Select a Period" data={dictionaries.periods} value={period} onChange={this.onSelectPeriod} />
                {!!period && (
                    <CompanyDropdown useNewBehavior shouldUpdateOnInit
                        value={companyId}
                        onSelectCompany={this.onChangeCompany} />
                )}
                {!!companyId && (
                    <DivisionDropdown multiple
                        companyId={companyId} 
                        includeCompany={true}
                        companyLabel={companyName}
                        value={divisions}
                        onSelectDivision={this.onChangeDivision}
                        selectedCompany={{}} />
                )}
                {!!divisions && divisions.length > 0 && (
                    <Select multiple value={statementTypes}
                        placeholder="Select a Statement Type"
                        valueComponent={SelectChip}
                        data={[
                            { label: LanguageService.getBalanceSheetName(), value: 1 },
                            { label: LanguageService.getIncomeStatementName(), value: 2 }
                        ]} onChange={this.onChangeStatementTypes} />
                )}
                {!!statementTypes && statementTypes.length > 0 && (
                    <Contents initialData={this.props.initialData} {...this.state} onChange={newState => this.setState(newState)} />
                )}
            </React.Fragment>
        );
    }
}