import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import UselessLink from "../UselessLink";

export default class ImageCard extends Component {
    render() {
        let {
            image,
            className,
            style = {},
            title,
            href,
            "background-icon": bkgIcon,
            "rotate-icon": rotateIcon = "false",
            enabled = true,
            "icon-text-color": iconTextColor = "",
            "icon-color": iconColor,
            "icon-color-shade": iconColorShade = "2",
            titleProps = {},
            innerCardProps = {},
            "text-color": textColor,
            ...otherProps
        } = this.props;
        const TagName = href && enabled ? Link : (href ? UselessLink : "div");

        if(image) {
            style.backgroundImage = `url(${image})`;
            style.color = "rgb(0,200,83) !important";
        }
        
        return (
            <TagName to={href} className={["card card-image", className].join(" ")} style={style} {...otherProps}>
                <div className="text-center d-flex align-items-center rgba-black-strong pt-7 px-0 card-inner-wrap" text-color={textColor}>
                    {bkgIcon && (
                        <div className="card-bkg" text-color={iconTextColor} color={iconColor} color-shade={iconColorShade}>
                            <FontAwesomeIcon icon={bkgIcon} transform={{ rotate: rotateIcon === "true" ? 180 : 0 }} />
                        </div>
                    )}
                    <div {...innerCardProps} className={["card-inner", innerCardProps.className].join(" ")}>
                        <h3 {...titleProps} className={["card-title pt-2", titleProps.className].join(" ")}><strong>{title}</strong></h3>
                        {this.props.children}
                    </div>
                </div>
            </TagName>
        );
    }
}