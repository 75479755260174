import React, { Component } from "react";
import { connect } from "react-redux";
import { setPageTitle } from "~/actions/ui";
import { getBalanceSheetName, getIncomeStatementName } from "~/services/ui/LanguageService";
import Tile from "./components/Tile";
import Permissions from "~/services/PermissionsDictionary";
import { showConfirmationModal } from "~/components/material/Modal/ConfirmationModal";
import TermsAndConditions from "../termsAndConditions";
import { PortalUser as UserService, TermsAndConditions as TermsAndConditionsService } from "~/services/api";
import Alert from "~/services/ui/alerts";
import { isResponsive } from "~/hoc/Responsive";
import BookKeepingLinksModal from "~/components/Notifications/components/BookKeepingLinksModal";
import AnalyticsLinksModal from "~/components/Notifications/components/AnalyticsLinksModal";

const stateMap = ({ company ,auth: { account }}) => ({ company, account });

@isResponsive
@connect(stateMap, { setPageTitle })
export default class Index extends Component {
    constructor(props) {
        super(props);
        props.setPageTitle(null);
    }

    async componentDidMount(){
        const { payload } = await TermsAndConditionsService.getLatestTermsAndConditions();
        let {account} = this.props; 
        let acceptedTerms = !account.AcceptTerms;
        if(acceptedTerms || account.termsAndConditionsId === null || account.termsAndConditionsId < payload[0].TermsAndConditionsId){
        let ok = await showConfirmationModal({
            okayLabel: "Accept",
            cancelLabel:"Reject",
            className: "modal-xl",
            children: () => (
                <div className="modal-terms">
                    <TermsAndConditions/>
                </div>
            )
        }); 
       
        if(ok){
            account.termsAndConditionsId = payload[0].TermsAndConditionsId;
            try {
                    account.AcceptTerms = true;
                    let req = await UserService.updateUser(account);
                    if(req.error) {
                        Alert.showError(req.error);
                    }
            } catch(ex) {
                console.log(ex);
                return Alert.showGenericError();
            }
        } else {
            global.forceLogout(false);
        }
    }
    }
    getLink(mode){
        return this.props.isMobile ? `/admin/income?mode=${mode}` : `/admin/${mode}`;
    }

    render() {
        return (
            <div className="container">
                <div className="row justify-content-md-center">
                    <Tile title="Account Import"
                        enabled={Permissions.getPermissionLevel(-1, "import") > 1}
                        className="tileBezel"
                        iconStyle={{ backgroundSize: "5rem", backgroundPosition: "center 1rem" }}
                        color="deep-blue"
                        link="/admin/import"
                        text-color="white"
                        icon="/res/refrsh.png" />
                    <Tile title="Analytics"
                        icon-text-color="shadow green"
                        className="tileBezel"
                        enabled={Permissions.hasGeneralPermission("analytics.read")}
                        link="/admin/income?mode=analytics"
                        color="white"
                        icon={["fas", "analytics"]} />
                    <Tile title="System Reports"
                        icon-text-color="shadow green"
                        className="tileBezel"
                        enabled={Permissions.hasGeneralPermission("systemReports.read")}
                        link="/admin/reports"
                        color="white"
                        icon={["far", "file-chart-line"]} />
                    <Tile title={getBalanceSheetName()}
                        enabled={Permissions.hasGeneralPermission("balancesheet.read")}
                        className="tileBezel"
                        color="white"
                        icon-text-color="shadow green"
                        link="/admin/balance"
                        icon="balance-scale" />
                    <Tile title={getIncomeStatementName()}
                        enabled={Permissions.hasGeneralPermission("incomestatement.read")}
                        className="tileBezel"
                        link="/admin/income"
                        color="white"
                        icon-text-color="shadow green"
                        icon={["far", "money-bill-alt"]} />
                    <Tile title="Cash Flow"
                        enabled={Permissions.getPermissionLevel(-1, "cashFlow")}
                        className="tileBezel"
                        link="/admin/cashFlow"
                        color="white"
                        icon-text-color="shadow green"
                        icon={["far", "money-check-alt"]} />
                    <Tile title="Budget"
                        enabled={Permissions.getPermissionLevel(-1, "budget")}
                        className="tileBezel"
                        link="/admin/budget"
                        color="white"
                        icon-text-color="shadow green"
                        icon="calculator" />
                    <Tile title="Forecast" style={{stroke: "#0f0"}}
                        enabled={Permissions.getPermissionLevel(-1, "forecast")}
                        className="tileBezel"
                        link="/admin/forecast"
                        icon="/res/forecast.svg"
                        color="white"
                        icon-text-color="shadow green"
                        rotate="true" />
                    <Tile title="Financial Notes"
                        enabled={Permissions.getPermissionLevel(-1, "notes")}
                        className="tileBezel"
                        link="/admin/notes"
                        color="white"
                        icon-text-color="shadow green"
                        icon="sticky-note" />
                </div>
                {Permissions.getPermissionNode("preferences.showQuickLinks") === true && (
                    <div className="row my-4 justify-content-between align-items-center" style={{ paddingLeft: 15, paddingRight: 15 }}>

                        <a target="_blank" rel="noopener noreferrer" href="http://bill.com/">
                            <img
                                src="/res/bill.com-icon.svg"
                                alt="bill.com icon"
                                title="Vendor Management"
                                height={65}
                            />
                        </a>

                        <BookKeepingLinksModal userId={this.props?.account?.PortalUserId} isMobile={false} />

                        <AnalyticsLinksModal userId={this.props?.account?.PortalUserId} isMobile={false} />


                        <a target="_blank" rel="noopener noreferrer"
                            href="https://quickbooks.intuit.com/sign-in-offer/">
                            <img
                                src="/res/quickbooks.svg"
                                alt="General Ledger"
                                title="General Ledger"
                                height={65}
                            />
                        </a>
                    </div>
                )}
            </div>
        );
    }
}