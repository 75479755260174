import React, { Component } from "react";
import UselessLink from "./UselessLink";
import except from "lodash/omit";

export class TabView extends Component {
    constructor() {
        super();
        this.state = {
            currentPage: 0
        };
    }

    changeTab(e, i) {
        this.setState({
            currentPage: i
        });
    }

    renderTabs() {
        let { children, ...otherProps } = this.props;

        return (
            <div className="tabs-wrapper"> 
                <ul className="nav classic-tabs" {...otherProps} role="tablist">
                    {React.Children.map(children, (element,i) => {
                        if (!React.isValidElement(element)) return null;
                        let { title } = element.props;
                        let classes = ["nav-link", "waves-effect"];
                        if(i === this.state.currentPage) classes.push("active show");

                        return (
                            <li className="nav-item" key={i}>
                                <UselessLink
                                    className={classes.join(" ")}
                                    data-toggle="tab" role="tab"
                                    onClick={e => this.changeTab(e, i)}
                                >{title}</UselessLink>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                {this.renderTabs()}
                <div className="tab-content card">
                    {React.Children.map(this.props.children, (element,i) => {
                        if (!React.isValidElement(element)) return null;
                        return React.cloneElement(element, {
                            ...element.props,
                            active: this.state.currentPage === i,
                            key: i
                        });
                    })}
                </div>
            </React.Fragment>
        );
    }
}

export class Page extends Component {
    render() {
        return (
            <div className={["tab-pane fade", this.props.active ? "in active show" : undefined].join(" ")} role="tabpanel" {...except(this.props, "active", "title")}>
                {this.props.children}
            </div>
        );
    }
}