import React from "react";
import Loadable from "react-loadable";
import { Switch, Route } from "react-router-dom";
import { NotFound } from "~/views/Errors";
import LoadingSpinner from "~/components/LoadingSpinner";

const StatementView = Loadable({
    loader: () => import("~/views/admin/statement/StatementView"),
    loading: LoadingSpinner
});

export default ({ match }) => (
    <Switch>
        <Route path={`${match.url}/`} render={(props) => {
            let mode = "income-statement";

            if(props.location.search) {
                let params = new URLSearchParams(props.location.search);
                mode = params.get("mode") || "income-statement";
            }
            
            return <StatementView mode={mode} />;
        }} />
        <Route component={NotFound} />
    </Switch>
);