//Stores all action types

//Auth
export const AUTHENTICATED = "user.auth.authenticated";
export const UNAUTHENTICATED = "user.auth.deauthenticated";
export const AUTHENTICATION_ERROR = "user.auth.fail";
export const SET_TOKEN = "token.update";

export const FETCH_ACCOUNT = "user.getAccount";
export const ACCOUNT_ERROR = "user.getAccount.fail";

export const PASSWORD_RESET_ERROR = "user.passwordReset.fail";
export const PASSWORD_RESET_SENT = "user.passwordReset.sent";
export const PASSWORD_RESET = "user.passwordReset.success";

//Tenant
export const TENANT_DETAILS = "tenant.getDetails";
export const GET_TENANT = "tenant.get";

//Company
export const CREATE_COMPANY = "company.create";
export const CREATE_COMPANY_ERROR = "company.create.error";
export const UPDATE_COMPANY = "company.update";
export const UPDATE_COMPANY_ERROR = "company.update.error";

export const FETCH_COMPANIES = "company.getAll";
export const FETCH_COMPANIES_ERROR = "company.getAll.error";

//Division
export const CREATE_DIVISION = "division.create";
export const CREATE_DIVISION_ERROR = "division.create.error";
export const UPDATE_DIVISION = "division.update";
export const UPDATE_DIVISION_ERROR = "division.update.error";

export const FETCH_DIVISIONS = "division.getAll";
export const FETCH_DIVISIONS_ERROR = "division.getAll.error";

//User
export const CREATE_USER = "user.create";
export const CREATE_USER_ERROR = "user.create.error";
export const UPDATE_USER = "user.update";
export const UPDATE_USER_ERROR = "user.update.error";
export const FETCH_USERS = "user.get";
export const FETCH_USERS_ERROR = "user.get.error";

//UI
export const SET_PAGE_TITLE = "page.setTitle";
export const COLLAPSE_DROPDOWNS = "page.collapseEverything";

//UI - Company
export const CHANGE_GLOBAL_COMPANY = "user.selectCompany";
export const GET_NOTES = "notes.get";
export const POST_NOTES = "notes.post";

//Reporting
export const STASH_ANALYTICS_REPORT_DATA = "report.analytics.stashSelections";