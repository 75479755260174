import { action, observable } from "mobx";

class NotificationState {
    @observable open = false;
    @observable btn = null;

    @action buttonRef = ref => this.btn = ref;
    @action openNotifications = () => this.open = true;
    @action closeNotifications = () => this.open = false;
    @action toggleNotifications = () => this.open = !this.open;
}

const state = new NotificationState();
export default state;