import { API } from "./index";
import { get, post, remove } from "../net";

class NotificationService {
    async getNotifications() {
        let request = get({
            endpoint: "portaluser/notifications",
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async markAsRead(notificationId) {
        let request = post({
            endpoint: notificationId ? `portaluser/notifications/read?id=${notificationId}` : "portaluser/notifications/read",
            data: {},
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async clearAll() {
        let request = remove({
            endpoint: "portaluser/notifications",
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async clearByType(notificationType) {
        let request = remove({
            endpoint: `portaluser/notifications?type=${encodeURIComponent(notificationType)}`,
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async clearForCompany(companyId) {
        let request = remove({
            endpoint: `portaluser/notifications?companyId=${encodeURIComponent(companyId)}`,
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async clearById(notificationId) {
        let request = remove({
            endpoint: `portaluser/notifications?notificationId=${encodeURIComponent(notificationId)}`,
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async createNotification(data) {
        let request = post({
            endpoint: "portaluser/notifications/test",
            data,
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }
}

const inst = new NotificationService();
export default inst;