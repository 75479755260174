import { API } from "./index";
import { get, post, remove} from "../net";
import qs from "querystring";

class MetadataService {
    async getUserRoles() {
        let request = get({
            endpoint: "security/roles",
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async getIndustries() {
        let request = get({
            endpoint: "metadata/industries",
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async getAccountingSystems() {
        let request = get({
            endpoint: "metadata/accountingsystems",
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async getTrackingTypes() {
        let request = get({
            endpoint: "metadata/trackingtypes",
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async getMonthsWithData(companyIds, year, statementType) {
        let request = post({
            endpoint: "metadata/getmonthswithdata",
            data: { companyIds: (Array.isArray(companyIds) ? companyIds : [companyIds]).map(x => Number(x)), year, statementType },
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async getUploadMethods() {
        let request = get({
            endpoint: "metadata/uploadmethods",
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async getMissingMonthsInIncomeStatement(companyId, year, endMonth) {
        let request = get({
            endpoint: `metadata/getMissingMonthsInIncomeStatement?${qs.stringify({ companyId, year, endMonth })}`,
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async getYearsWithData(companyIds, statementTypeId) {
        let request = get({
            endpoint: `metadata/getYearsWithData?${qs.stringify({ companyIds, statementTypeId })}`,
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async getClassList(companyFile) {
        let request = get({
            endpoint: `integration/qb/getClassList?${qs.stringify({ companyFile: btoa(companyFile) })}`,
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async getAliasSets() {
        let request = get({
            endpoint: `metadata/accountSets`,
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async getAliasSetById(setId) {
        let request = get({
            endpoint: `metadata/accountSets/${setId}`,
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async saveAccountSets(name, aliases = [], setId = -1) {
        let request = post({
            endpoint: `metadata/accountSets`,
            watcher: this.watcher,
            data: {
                setId: setId === -1 ? null : setId,
                name,
                aliases
            }
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async getLatestDateWithData(statementTypeId, companyId) {
        let query = qs.stringify({ statementTypeId, companyId });
        let request = get({
            endpoint: `metadata/latestDateWithData?${query}`,
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async getQBOClassList(parentCompanyId) {
        let request = get({
            endpoint: `integration/qb/getQBOClassList?${qs.stringify({ parentCompanyId })}`,
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    safe = {
        getIndustries: async() => {
            try {
                let req = await this.getIndustries();
                if(req.error) return [];

                if(req.payload && Array.isArray(req.payload))
                    return req.payload;

                return [];
            } catch(ex) {
                return [];
            }
        },

        getMonthsWithData: async(companyIds, year, statementType) => {
            try {
                let req = await this.getMonthsWithData(companyIds, year, statementType);
                if(req.error) return [];

                if(req.payload && Array.isArray(req.payload))
                    return req.payload;

                return [];
            } catch(ex) {
                return [];
            }
        },

        getYearsWithData: async(companyIds, statementType) => {
            try {
                let req = await this.getYearsWithData(companyIds, statementType);
                if(req.error) return [];

                if(req.payload && Array.isArray(req.payload))
                    return req.payload.map(x => x.Year);

                return [];
            } catch(ex) {
                return [];
            }
        },

        getAliasSets: async() => {
            try {
                let req = await this.getAliasSets();
                if(req.error) return [];

                if(req.payload && Array.isArray(req.payload))
                    return req.payload.map(x => x.Year);

                return [];
            } catch(ex) {
                return [];
            }
        },

        getAliasSetById: async(id) => {
            try {
                let req = await this.getAliasSetById(id);
                if(req.error) return { Name: null, Aliases: [] };

                return {
                    Name: req.payload[0].Name,
                    Aliases: JSON.parse(req.payload[0].Aliases) || []
                };
            } catch(ex) {
                return { Name: null, Aliases: [] };
            }
        },

        getLatestDateWithData: async(statementTypeId, companyId) => {
            try {
                let req = await this.getLatestDateWithData(statementTypeId, companyId);
                if(req.error) return { year: null, month: null };

                return req.payload;
            } catch(ex) {
                return { year: null, month: null };
            }
        },

        getLatestDateWithDataForTypes: async(statementTypeIds, companyId) => {
            let dates = await Promise.all(statementTypeIds.map(id => this.safe.getLatestDateWithData(id, companyId)));

            let val = { year: null, month: null };

            for(let date of dates) {
                if(!date.year || !date.month) continue;

                if(date.year >= val.year && date.month > val.month) {
                    val.year = date.year;
                    val.month = date.month;
                }
            }

            return val;
        },

        getLatestDateWithDataForCompanies: async(statementTypeId, companyIds) => {
            let companies = await Promise.all(companyIds.map(id => this.safe.getLatestDateWithData(statementTypeId, id)));

            let val = { year: null, month: null };

            for(let latest of companies) {
                if(!latest.year || !latest.month) continue;

                if(latest.year > val.year) {
                    val.year = latest.year;
                    val.month = latest.month;
                } else if(latest.year == val.year && latest.month > val.month) {
                    val.year = latest.year;
                    val.month = latest.month;
                }
            }

            return val;
        }
    }

    async getFilters(companyId, statementTypeId) {
        let request = get({
            endpoint: `report/filters?${qs.stringify({ companyId, statementTypeId })}`,
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async getAllFilters() {
        let getAll=true;
        let companyId=0, statementTypeId=0;
        let request = get({
            endpoint: `report/filters?${qs.stringify({ getAll, companyId, statementTypeId })}`,
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async getFilter(filterOptionId) {
        let request = get({
            endpoint: `report/filter/${filterOptionId}`,
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async getFilterByName(filterName) {
        let request = get({
            endpoint: `report/filters/${filterName}`,
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async saveFilter({ filterOptionId, name, defaultFilter, companyId, statementTypeId, standardAccountIds, globalFilter}) {
        let request = post({
            endpoint: "report/filters",
            data: { filterOptionId, name, defaultFilter, companyId, statementTypeId, standardAccountIds, globalFilter},
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async deleteFilter(filterOptionId) {
        let request = remove({
            endpoint: `report/filter/${filterOptionId}`,
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async copyFilter(data) {
        let request = post({
            data,
            endpoint: "report/copy/filters",
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }
}

const inst = new MetadataService();
export default inst;

window.MetadataService = inst;