import React, { Component } from "react";
import { connect } from "react-redux";
import except from "lodash/omit";
import Alert from "~/services/ui/alerts";
import { Select } from "~/components/material";
import { Company as CompanyService } from "~/services/api";
import Permissions from "~/services/PermissionsDictionary";
import uniqBy from "lodash/uniqBy";
import orderBy from "lodash/orderBy";
import get from "lodash/get";

const stateMap = ({ company }) => ({
    _company: company
});

class CompanyDropdown extends Component {
    constructor(props) {
        super(props);
        let state = { companies: null, selectedCompany: null };

        if(props._company && !props.isImportant) {
            state.selectedCompany = props._company.CompanyId;
        }

        if(props.value) {
            state.value = props.value;
        }

        this.state = state;
    }

    refetch() {
        this.setState({ companies: null }, async () => {
            let {
                onError = e => {
                    console.log(e);
                    Alert.showGenericError();
                }
            } = this.props;

            try {
                let { error, res } = await CompanyService.getAllCompanies();
                if(error) {
                    this.setState({ companies: [] });
                    return onError(error);
                }

                if(res) {
                    let data = uniqBy(res.map(x => ({ label: x.Name, value: x.CompanyId, raw: x })), x => x.value);

                    if(this.props.companyFilter) {
                        if(typeof this.props.companyFilter === "function") {
                            let filter = this.props.companyFilter();
                            data = data.filter(x => !(filter.includes(x.value)));
                        } else {
                            data = data.filter(x => !(this.props.companyFilter.includes(x.value)));
                        }
                    } else if(this.filter) {
                        data = data.filter(x => !(this.filter.includes(x.value)));
                    }

                    if(this.props.requiresPermission) {
                        let { requiresPermission } = this.props;

                        if(typeof requiresPermission === "string") {
                            data = data.filter(x => Permissions.hasPermissionForCompany(requiresPermission, x.value));
                        } else {
                            data = data.filter(x => requiresPermission(x));
                        }
                    }

                    data = orderBy(data, x => x.label.toLowerCase());
                    if(this.props.addNone) {
                        data.unshift({ label: "None", value: -1, __none: true });
                    }

                    this.setState({ companies: data }, this.preselectCheck);
                } else {
                    this.setState({ companies: [] }, this.preselectCheck);
                }
            } catch(error) {
                this.setState({ companies: [] });
                return onError(error);
            }
        });
    }

    componentDidMount() {
        let { _company } = this.props;
        this.refetch();

        if(_company && !this.props.isImportant) {
            this.onSelectCompany({
                label: _company.Name,
                value: _company.CompanyId,
                raw: _company
            }, true);
        }
    }

    preselectCheck = () => {
        let { initialData, shouldUpdateOnInit, value } = this.props;

        if(initialData && initialData.companyId) {
            let company = this.state.companies.find(x => x.value == initialData.companyId);
            if(company) {
                this.onSelectCompany(company, true);
            }
        } else if(shouldUpdateOnInit && value && !isNaN(Number(value))) {
            let company = this.state.companies.find(x => x.value == value);
            if(company) {
                this.onSelectCompany(company, true);
            }
        }
    }

    onSelectCompany(option, fake = false) {
        if(option) {
            if(fake) {
                this.setState({ selectedCompany: option.value }, () => {
                    if(this.props.onChange && this.props.useNewBehavior) {
                        this.props.onChange(option);
                    }
                    
                    if(this.props.onChange && !this.props.useNewBehavior) {
                        this.props.onChange({ id: option.value, name: option.label, raw: option.raw });
                    }

                    if(this.props.input && this.props.useNewBehavior)
                        this.props.input.onChange(option);

                    if(this.props.input && !this.props.useNewBehavior)
                        this.props.input.onChange(option.value);

                    if(this.props.onSelectCompany) {
                        this.props.onSelectCompany({ id: option.value, name: option.label, raw: option.raw }, true);
                    }

                    if(this.props.genericChangeHandler) {
                        this.props.genericChangeHandler(option.value);
                    }
                });
            } else {
                this.setState({ selectedCompany: option.value }, () => {
                    if(this.props.onChange && this.props.useNewBehavior) {
                        this.props.onChange(option);
                    }
                    
                    if(this.props.onChange && !this.props.useNewBehavior) {
                        this.props.onChange({ id: option.value, name: option.label, raw: option.raw });
                    }

                    if(this.props.input && this.props.useNewBehavior)
                        this.props.input.onChange(option);

                    if(this.props.input && !this.props.useNewBehavior)
                        this.props.input.onChange(option.value);

                    if(this.props.onSelectCompany) {
                        this.props.onSelectCompany({ id: option.value, name: option.label, raw: option.raw });
                    }

                    if(this.props.genericChangeHandler) {
                        this.props.genericChangeHandler(option.value);
                    }
                });
            }
        }    
    }

    static getDerivedStateFromProps(nextProps, oldState) {
        if(nextProps.input) {
            if(nextProps.input.value) {
                let { value } = nextProps.input;

                if(value === "" && oldState.selectedCompany)
                    return { selectedCompany: null };

                if(value.value && value.value !== oldState.selectedCompany)
                    return { selectedCompany: value.value === "" ? null : value.value };

                if(value !== "")
                    return { selectedCompany: value };

                return null;
            } else if(oldState.selectedCompany) {
                return { selectedCompany: null };
            }
        }

        if(oldState.selectedCompany === get(nextProps, "_company.CompanyId"))
            return null;

        if(nextProps.value === oldState.selectedCompany)
            return null;

        if(oldState.selectedCompany && nextProps.value && nextProps.value === oldState.selectedCompany) {
            return null;
        } else {
            if(!nextProps.isImportant && nextProps._company) {
                return {
                    selectedCompany: nextProps._company.CompanyId
                };
            } else if(!nextProps.isImportant && nextProps.value) {
                return {
                    selectedCompany: nextProps.value
                };
            } else return null;
        }
    }

    // componentWillReceiveProps(nextProps) {
    //     let company = null;

    //     if(nextProps._company && nextProps._company.CompanyId)
    //         company = nextProps._company.CompanyId;

        
    //     if(company !== this.state.selectedCompany && !nextProps.isImportant && !this.state.selectedCompany) {
    //         this.setState({ selectedCompany: company });
    //     }
    // }

    render() {
        let message = (
            this.state.companies ? (
                this.state.companies.length ? "Select a Company" : "No Companies Available"
            ) : "Loading companies..."
        );
        
        return (
            <Select
                {...except(this.props, "onSelectCompany", "onChange", "onError")}
                placeholder={message}
                data={this.state.companies}
                value={this.state.selectedCompany}
                onChange={opt => this.onSelectCompany(opt)} />
        );
    }
}

const out = connect(stateMap)(CompanyDropdown);
//Copy the prototype over, since connect() returns a wrapper
// out.prototype.refetch = CompanyDropdown.prototype.refetch;
export default out;