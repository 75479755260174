import { API } from "./index";
import { get, put, post } from "../net";
import { objectKeysToLowerCase } from "~/lib/validation";

import {
    UPDATE_COMPANY, UPDATE_COMPANY_ERROR,
    CREATE_COMPANY, CREATE_COMPANY_ERROR,
    FETCH_COMPANIES, FETCH_COMPANIES_ERROR
} from "~/actions/definitions";

class CompanyService {
    async updateCompany(data) {
        let request = put({
            endpoint: `company/${data.CompanyId}`,
            data: objectKeysToLowerCase(data),
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error, UPDATE_COMPANY, UPDATE_COMPANY_ERROR);
        } catch(ex) {
            return API.handleResponse(request, null, ex, UPDATE_COMPANY, UPDATE_COMPANY_ERROR);
        }
    }

    async createCompany(data) {
        let request = post({
            endpoint: "company",
            data: objectKeysToLowerCase(data),
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error, CREATE_COMPANY, CREATE_COMPANY_ERROR);
        } catch(ex) {
            return API.handleResponse(request, null, ex, CREATE_COMPANY, CREATE_COMPANY_ERROR);
        }
    }

    async getAllCompanies() {
        let request = get({
            endpoint: "company",
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error, FETCH_COMPANIES, FETCH_COMPANIES_ERROR);
        } catch(ex) {
            return API.handleResponse(request, null, ex, FETCH_COMPANIES, FETCH_COMPANIES_ERROR);
        }
    }

    async getCompanyById(companyId) {
        let request = get({
            endpoint: `company/${companyId}`,
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error, FETCH_COMPANIES, FETCH_COMPANIES_ERROR);
        } catch(ex) {
            return API.handleResponse(request, null, ex, FETCH_COMPANIES, FETCH_COMPANIES_ERROR);
        }
    }
    async getUsersAssignedToCompany(companyId) {
        let request = get({
            endpoint: `company/${companyId}/users`,
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }
}

const inst = new CompanyService();
export default inst;