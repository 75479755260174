import React from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

export default Component => class Collapsible extends React.PureComponent {
    state = {
        expanded: false
    };

    collapse = () =>
        this.setState({ expanded: false });

    toggle = () =>
        this.setState({ expanded: !this.state.expanded });

    render() {
        return (
            <ClickAwayListener onClickAway={this.collapse}>
                <Component
                    expanded={this.state.expanded}
                    toggleExpanded={this.toggle}
                    {...this.props} />
            </ClickAwayListener>
        );
    }
};