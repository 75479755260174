import React, { Component } from "react";

export default class ProgressButton extends Component {
    state = {
        stage: 0,
        percent: 0,
        disabled: !!this.props.disabled
    }

    componentDidUpdate(props) {
        if(props.disabled !== this.props.disabled) {
            this.setState({ disabled: this.props.disabled });
        }
    }

    setPolling = (on = true) => new Promise(resolve => {
        if(on) {
            this.setState({ stage: 1, disabled: true }, resolve);
        } else {
            this.setState({ stage: 0, disabled: false }, resolve);
        }
    });
    
    disable = () =>
        new Promise(resolve => this.setState({ disabled: true }, resolve))

    enable = () =>
        new Promise(resolve => this.setState({ disabled: false }, resolve));

    onClickButton = e => {
        let { stage } = this.state;
        let { onClick = e => null, manual = false } = this.props;

        if(stage === 1) {
            e.preventDefault();
            return false;
        }

        if(stage === 0 && !manual) {
            this.setState({ stage: 1 }, onClick);
        } else return onClick(e);
    }

    setPercent = (percent, autoComplete = false) => {
        if(autoComplete) {
            this.setState({ percent }, () => this.done(true));
        } else {
            this.setState({ percent });
        }
    }

    done(success = -1, cb) {
        let promise = new Promise(resolve => {
            this.setState({ stage: (success === -1 ? 0 : (success ? 2 : 3)), percent: 0, disabled: false }, () => {
                setTimeout(() => {
                    if([2,3].includes(this.state.stage)) {
                        this.setState({ stage: 0, disabled: this.props.disabled }, resolve);
                    } else return resolve();
                }, 2000);
            });
        });

        if(cb) {
            promise = promise.then(cb);
        }

        return promise;
    }

    reset = () => new Promise(resolve => {
        this.setState({ stage: 0, percent: 0, disabled: this.props.disabled }, resolve);
    });

    render() {
        let { stage, percent, disabled } = this.state;

        let wrapperProps = {
            color: this.props.color,
            "text-color": this.props["text-color"],
            "color-shade": this.props["color-shade"],
            type: this.props.type,
            disabled: this.state.disabled
        };

        let className = ["progress-button", "shadowed", this.props.className];
        let progressClass = ["progress-inner"];
        let contentClass = ["content"];

        if(stage === 1) {
            className.push("state-loading");
            className.push("disabled");
        } else {
            progressClass.push("notransition");
        }

        if(stage === 2) {
            className.push("state-success");
            wrapperProps.color = "green";
        }

        if(stage === 3) {
            className.push("state-error");
            wrapperProps.color = "red";
        }

        if(disabled) {
            className.push("disabled");
        }
        
        return (
            <button className={className.join(" ")} data-style="shrink" data-horizontal onClick={this.onClickButton} {...wrapperProps}>
                <span className={contentClass.join(" ")}>{this.props.children}</span>
                <span className="progressBar" {...wrapperProps}>
                    <span className={progressClass.join(" ")} style={{ width: `${percent}%` }}></span>
                </span>
                {stage === 1 && (
                    <div className="ltr-dot-loader animated fadeIn">
                        <div /> <div /> <div /> <div />
                    </div>
                )}
            </button>
        );
    }
}