import { API } from "./index";
import { get, put, post } from "../net";
import { objectKeysToLowerCase } from "~/lib/validation";

import {
    UPDATE_DIVISION, UPDATE_DIVISION_ERROR,
    CREATE_DIVISION, CREATE_DIVISION_ERROR,
    FETCH_DIVISIONS, FETCH_DIVISIONS_ERROR
} from "~/actions/definitions";

class DivisionService {
    async updateDivision(data) {
        let request = put({
            endpoint: `division/${data.CompanyId}`,
            data: objectKeysToLowerCase(data),
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error, UPDATE_DIVISION, UPDATE_DIVISION_ERROR);
        } catch(ex) {
            return API.handleResponse(request, null, ex, UPDATE_DIVISION, UPDATE_DIVISION_ERROR);
        }
    }

    async createDivision(data) {
        let request = post({
            endpoint: "division",
            data: objectKeysToLowerCase(data),
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error, CREATE_DIVISION, CREATE_DIVISION_ERROR);
        } catch(ex) {
            return API.handleResponse(request, null, ex, CREATE_DIVISION, CREATE_DIVISION_ERROR);
        }
    }

    async getAllByParent(companyId) {
        let request = get({
            endpoint: `company/${companyId}/divisions`,
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error, FETCH_DIVISIONS, FETCH_DIVISIONS_ERROR);
        } catch(ex) {
            return API.handleResponse(request, null, ex, FETCH_DIVISIONS, FETCH_DIVISIONS_ERROR);
        }
    }

    safe = {
        getAllByParent: async(companyId) => {
            try {
                let req = await this.getAllByParent(companyId);
                if(req.error) return [];

                if(req.payload && Array.isArray(req.payload))
                    return typeof req.payload[0].Divisions == "string" ? JSON.parse(req.payload[0].Divisions) : req.payload[0].Divisions;

                return [];
            } catch(ex) {
                return [];
            }
        }
    }
}

const inst = new DivisionService();
export default inst;