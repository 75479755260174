import React from "react";
import ReactDOM from "react-dom";
import AsyncComponent from "~/components/AsyncComponent";
import UselessLink from "~/components/material/UselessLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import MetaService from "~/services/api/meta";
import AbstractForm from "~/components/generic/GenericForm";
import ConfirmationModal from "~/components/material/Modal/ConfirmationModal";
import { MONTHS } from "./MonthSelector";

import shortid from "shortid";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import findIndex from "lodash/findIndex";

const TYPES = {
    2: "Actual",
    3: "Budget",
    4: "Forecast"
};

const form = ({ targetData: state }, { selectedCompanies, selectTypes = false, statementType }) => {
    let out = [{
        name: "statementTypeId",
        type: "select",
        data: Object.keys(TYPES).map(value => ({ name: TYPES[value], val: value })),
        placeholder: "Select a Type"
    }, {
        name: "date", type: "date",
        autoUpdateSelectionFilters: true,
        ignoreFilters: false,
        statementType: selectTypes ? state.statementTypeId : statementType,
        selectedCompanies: selectedCompanies,
        disabled: selectTypes && !state.statementTypeId
    }];

    return out;
};

export default class AnalyticsDatesSelector extends AsyncComponent {
    state = {
        dates: Array(this.props.exact ? this.props.exact : 0).fill(0).map(x => ({ year: null, month: null, id: shortid() })),
        dateFilters: {},
        yearFilters: [],
        targetData: {}
    }

    componentWillMount() {
        this.uuuuuid = +new Date();
    }

    updateYearsWithData = async() => {
        let { targetData } = this.state;
        let statementType = get(targetData, "type");

        if(statementType) {
            let yearFilters = await MetaService.safe.getYearsWithData(this.props.selectedCompanies, statementType);
            this.setState({ yearFilters });
        }
    }

    updateParent = () => {
        let { input, onChange = e => null } = this.props;
        get(input, "onChange", onChange)(this.state.dates);
    }

    componentDidUpdate(props) {
        let { exact, disabled, value, input } = this.props;
        let { dates } = this.state;

        if(exact !== props.exact && exact) {
            this.setState({
                dates: Array(exact).fill(0).map(x => ({ year: null, month: null, id: shortid() })),
                dateFilters: {},
                yearFilters: []
            });
        } else if(props.input && this.props.input) {
            let oldVal = get(props.input, "value", props.value);
            let val = get(input, "value", value);

            if(oldVal == "" || !oldVal)
                oldVal = [];

            if(val == "" || !val)
                val = [];

            if(!isEqual(oldVal, val)) {
                this.setState({ dates: val, dateFilters: {}, yearFilters: [] }, this.updateParent);
            }
        } else if(!isEqual(props, this.props.value) && !isEqual(this.props.value, dates) && this.props.value != null) {
            this.setState({ dates: this.props.value || [], dateFilters: {}, yearFilters: [] }, this.updateParent);
        } else if(props.disabled !== disabled || !isEqual(props.selectedCompanies, this.props.selectedCompanies)) {
            this.updateYearsWithData();
        }
    }

    edit = id => async() => {
        let target = this.state.dates.find(x => x.id === id);
        await this.updateState({
            targetData: {
                statementTypeId: get(target, "type"),
                date: get(target, "date")
            }
        });

        let cont = await this.modal.async.popup();
        if(cont) {
            let newData = [...this.state.dates];
            let target = newData.find(x => x.id === id);
            target.type = this.state.targetData.statementTypeId;
            target.date = this.state.targetData.date;

            this.setState({ dates: newData, targetData: {} }, this.updateParent);
        }
    }

    removeDate = id => () => {
        let dates = [...this.state.dates];
        dates.splice(findIndex(dates, x => x.id === id), 1);

        this.setState({ dates }, this.updateParent);
    }

    onAddRow = () => {
        this.setState({
            dates: [
                ...this.state.dates,
                { date: { year: null, month: null }, id: shortid() }
            ]
        }, this.updateParent);
    }

    render() {
        let { label, disabled, max = 12, exact } = this.props;
        let { dates = [], targetData } = this.state;

        return (
            <React.Fragment>
                <table className="w-100">
                    <colgroup>
                        {!!!exact && (<col width="0" />)}
                        <col />
                        <col />
                    </colgroup>
                    <tbody>
                        {!!!exact && (
                            <tr>
                                <td colSpan="2" className="p-0">
                                    <h3 className="pull-left p-0 pt-1">{label}</h3>
                                    <button className="btn btn-sm pull-right" color="green" text-color="white" onClick={this.onAddRow} disabled={disabled || dates.length === max}>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </button>
                                </td>
                            </tr>
                        )}
                        {dates.map(({ type, date, id }) => {
                            return (
                                <tr key={id}>
                                    {!!!exact && (
                                        <td className="p-1" style={{width: "3rem"}}>
                                            <UselessLink disabled={disabled} onClick={this.removeDate(id)}><h3>&times;</h3></UselessLink>
                                        </td>
                                    )}
                                    <td>
                                        <button disabled={disabled} type="button" className="btn btn-success w-100" color="green" text-color="white" onClick={this.edit(id)}>
                                            {date && !!type ? (
                                                `${MONTHS[date.month - 1]} ${date.year} ${TYPES[type]}`
                                            ) : (
                                                "Select a Date"
                                            )}
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                        {!dates.length && (
                            <tr>
                                <td colSpan={1 + (!!!exact)}>Add a date using the plus button</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {ReactDOM.createPortal((
                    <ConfirmationModal ref={r => this.modal = r} hideOkay={!!!targetData || !!!targetData.date || !!!targetData.statementTypeId || !!!targetData.date.year || !!!targetData.date.month}>
                        <AbstractForm useNewBehavior name={"analyticsDateEditor" + this.uuuuuid} fields={form(this.state, this.props)} data={targetData} onSubmit={e => null} onUpdate={targetData => this.setState({ targetData })} />
                    </ConfirmationModal>
                ), document.querySelector("#root"))}
            </React.Fragment>
        );
    }
}