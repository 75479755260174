import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "~/lib/elemutil";
import except from "except";

export default class Navbar extends Component {
    toggleNavigation(e) {
        e.preventDefault();
        let btn = e.target;
        $(btn.getAttribute("data-target")).toggleClass("show");
    }

    onGoWordpress = global.forceLogout;

    render() {
        let {
            name = "\u00A0",
            logo = null,
            nodropdown = true,
            fixed = false
        } = this.props;

        let className = [
            "navbar navbar-expand-lg",
            fixed ? "fixed-top" : undefined
        ].join(" ");
    
        return (
            <nav className={className} {...except(this.props, "name", "fixed", "logo", "links", "nodropdown", "name-color")}>
                <Link className="navbar-brand" to="/" onClick={window.location.pathname == "/login" ? this.onGoWordpress : undefined} text-color={this.props["name-color"] || "black"}>
                    {logo ? <img src={logo} alt='' className="img-fluid" /> : <span>{name}</span>}
                </Link>
                {!nodropdown && (
                    <button className="navbar-toggler waves-effect" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={e => this.toggleNavigation(e)}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                )}
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    {this.props.children}
                    {this.props.navComponents}
                </div>
            </nav>
        );
    }
}