import React from "react";
import ReactDOM from "react-dom";
import AsyncComponent from "~/components/AsyncComponent";
import UselessLink from "~/components/material/UselessLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import MetaService from "~/services/api/meta";
import AbstractForm from "~/components/generic/GenericForm";
import ConfirmationModal from "~/components/material/Modal/ConfirmationModal";
import { MONTHS } from "./MonthSelector";

import shortid from "shortid";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import findIndex from "lodash/findIndex";

const TYPES = {
    2: "Income Statement",
    3: "Budget",
    4: "Forecast"
};

const SHORT_MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const form = ({ targetData: state }, { selectedCompanies, selectTypes = false, statementType }) => {
    let out = [
        {
            name: "startDate", type: "date",
            autoUpdateSelectionFilters: true,
            statementType: selectTypes ? state.statementTypeId : statementType,
            selectedCompanies: selectedCompanies,
            disabled: selectTypes && !state.statementTypeId
        },
        {
            name: "endDate", type: "date",
            autoUpdateSelectionFilters: true,
            statementType: selectTypes ? state.statementTypeId : statementType,
            selectedCompanies: selectedCompanies,
            disabled: selectTypes && !state.statementTypeId
        }
    ];

    if(selectTypes) {
        out.unshift({
            name: "statementTypeId", type: "select", data: Object.keys(TYPES).map(value => ({ name: TYPES[value], val: value })), placeholder: "Select a Type"
        });
    }

    return out;
};

export default class MultipleDateRangeSelector extends AsyncComponent {
    state = {
        dates: Array(this.props.exact ? this.props.exact : 0).fill(0).map(x => ({ year: null, month: null, id: shortid() })),
        dateFilters: {},
        yearFilters: [],
        targetData: {}
    }

    updateMonthFilters = (id, cb) => async() => {
        let date = this.state.dates.find(x => x.id === id);

        let data = await MetaService.safe.getMonthsWithData(this.props.selectedCompanies, date.year, this.props.statementType);
        this.setState({
            dateFilters: {
                ...this.state.dateFilters,
                [date.year]: data
            }
        }, cb);
    }

    updateYearsWithData = async() => {
        let yearFilters = await MetaService.safe.getYearsWithData(this.props.selectedCompanies, this.props.statementType);
        let latest = await MetaService.safe.getLatestDateWithDataForCompanies(this.props.statementType, this.props.selectedCompanies);
        this.setState({ yearFilters, dates: this.state.dates.map(x => ({ ...x, year: x.year ? x.year : latest.year, month: x.year ? x.month : latest.month, id: x.id })) });
    }

    updateParent = () => {
        let { input, onChange = e => null } = this.props;
        get(input, "onChange", onChange)(this.state.dates);
    }

    componentDidMount() {
        let { input, value, selectedCompanies } = this.props;

        if(input) {
            let newVal = get(input, "value", value);

            if(newVal && Array.isArray(newVal) && selectedCompanies) {
                this.setState({ dates: newVal, dateFilters: {}, yearFilters: [] }, this.updateParent);
            }
        }
    }

    componentDidUpdate(props) {
        let { exact, disabled, input, value } = this.props;
        
        if(exact !== props.exact && exact) {
            this.setState({
                dates: Array(exact).fill(0).map(x => ({ year: null, month: null, id: shortid() })),
                dateFilters: {},
                yearFilters: []
            }, this.updateYearsWithData);
        } else if(input) {
            let oldVal = get(props, "input.value", props.value);
            let newVal = get(input, "value", value);

            if(!isEqual(oldVal, newVal) && !isEqual(newVal, this.state.dates)) {
                if(exact) {
                    this.setState({
                        dates: Array(exact).fill(0).map(x => ({ year: null, month: null, id: shortid() })),
                        dateFilters: {},
                        yearFilters: []
                    }, this.updateYearsWithData);
                } else {
                    this.setState({ dates: newVal || [], dateFilters: {}, yearFilters: [] }, this.updateParent);
                }
            } else if(props.disabled !== disabled || !isEqual(props.selectedCompanies, this.props.selectedCompanies)) {
                this.updateYearsWithData();
            }
        } else if(props.disabled !== disabled || !isEqual(props.selectedCompanies, this.props.selectedCompanies)) {
            this.updateYearsWithData();
        }
    }

    edit = id => async() => {
        let target = this.state.dates.find(x => x.id === id);
        await this.updateState({
            targetData: {
                statementTypeId: get(target, "type"),
                startDate: get(target, "startDate"),
                endDate: get(target, "endDate")
            }
        });

        let cont = await this.modal.async.popup();

        if(cont) {
            let newData = [...this.state.dates];
            let target = newData.find(x => x.id === id);
            target.type = this.state.targetData.statementTypeId;
            target.startDate = this.state.targetData.startDate;
            target.endDate = this.state.targetData.endDate;
            
            let index = newData.findIndex(x => x.id === id);
            newData[index].year = target.endDate.year;

            this.setState({ dates: newData, targetData: {} }, this.updateParent);
        }
    }

    removeDate = id => () => {
        let dates = [...this.state.dates];
        dates.splice(findIndex(dates, x => x.id === id), 1);

        this.setState({ dates }, this.updateParent);
    }

    onAddRow = () => {
        this.setState({
            dates: [
                ...this.state.dates,
                { year: null, month: null, id: shortid() }
            ]
        }, this.updateParent);
    }

    onChangeYear = id => ({ value }) => {
        let dates = [...this.state.dates];
        let date = dates.find(x => x.id === id);
        date.year = value;
        date.month = null;

        this.setState({ dates }, this.updateMonthFilters(id, this.updateParent));
    }

    onChangeMonth = id => ({ value }) => {
        let dates = [...this.state.dates];
        dates.find(x => x.id === id).month = value;

        this.setState({ dates }, this.updateParent);
    }

    render() {
        let { label, disabled, max = 12, exact } = this.props;
        let { dates = [], targetData } = this.state;

        return (
            <React.Fragment>
                <table className="w-100">
                    <colgroup>
                        {!!!exact && (<col width="0" />)}
                        <col />
                        <col />
                    </colgroup>
                    <tbody>
                        {!!!exact && (
                            <tr>
                                <td colSpan="2" className="p-0">
                                    <h3 className="pull-left p-0 pt-1">{label}</h3>
                                    <button className="btn btn-sm pull-right" color="green" text-color="white" onClick={this.onAddRow} disabled={disabled || dates.length === max}>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </button>
                                </td>
                            </tr>
                        )}
                        {dates.map(({ type, startDate, endDate, id }) => {
                            return (
                                <tr key={id}>
                                    {!!!exact && (
                                        <td className="p-1" style={{width: "3rem"}}>
                                            <UselessLink disabled={disabled} onClick={this.removeDate(id)}><h3>&times;</h3></UselessLink>
                                        </td>
                                    )}
                                    <td className="pr-1">
                                        <button disabled={disabled} type="button" className="btn btn-success w-100 m-0" color="green" text-color="white" onClick={this.edit(id)}>
                                            {startDate && startDate.year && startDate.month && endDate && endDate.month && endDate.year && (this.props.selectTypes ? !!type : true) ? (
                                                this.props.selectTypes
                                                    ? `${SHORT_MONTHS[startDate.month - 1]} ${startDate.year} - ${SHORT_MONTHS[endDate.month - 1]} ${endDate.year} ${TYPES[type]}`
                                                    : `${MONTHS[startDate.month - 1]} ${startDate.year} - ${MONTHS[endDate.month - 1]} ${endDate.year}`
                                            ) : (
                                                "Select a Date Range"
                                            )}
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                        {!dates.length && (
                            <tr>
                                <td colSpan={1 + (!!!exact)}>Add a date using the plus button</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {ReactDOM.createPortal((
                    <ConfirmationModal ref={r => this.modal = r}>
                        <AbstractForm useNewBehavior name="dateRangeEditor" fields={form(this.state, this.props)} data={targetData} onSubmit={e => null} onUpdate={targetData => this.setState({ targetData })} />
                    </ConfirmationModal>
                ), document.querySelector("#root"))}
            </React.Fragment>
        );
    }
}