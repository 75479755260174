import React from "react";
import { showConfirmationModal } from "~/components/material/Modal/ConfirmationModal";

import CompanyDropdown from "~/components/form/fields/CompanyDropdown";

import Permissions from "~/services/PermissionsDictionary";

class CompanyModalBody extends React.PureComponent {
    state = {
        company: null
    };

    componentDidMount() {
        this.props.modalProps.hideOkay = true;
    }

    onChangeCompany = company => {
        this.setState({ company }, () => {
            this.props.setResult(company);
            this.props.modalProps.hideOkay = false;
        });
    }

    render() {
        let { selectedCompanies } = this.props;

        return (
            <CompanyDropdown isImportant useNewBehavior
                requiresPermission={x => Permissions.getPermissionLevel(x.value, "import") > 0 || [...(JSON.parse(x.raw.Divisions) || [])].find(x => Permissions.getPermissionLevel(x.CompanyId, "import") > 0) }
                companyFilter={() => selectedCompanies.map(x => x.value)}
                onChange={this.onChangeCompany} />
        );
    }
}

export default async(companyList) => {
    let data = null;
    let ok = await showConfirmationModal({
        title: "Add a Company",
        showTitle: true,
        okayLabel: "Add",
        children: props => (
            <CompanyModalBody {...props} setResult={r => data = r} selectedCompanies={companyList} />
        )
    });

    return ok ? data : null;
};