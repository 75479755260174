import { action, observable } from "mobx";
import { BASE_URL } from "~/services/net";

import Alert from "~/services/ui/alerts";
import DivisionService from "~/services/api/division";
import showAddCompanyModal from "~/views/admin/import/components/MultipleImportView/components/CompanyModalBody";

import difference from "lodash/difference";
import uniq from "lodash/uniq";

const random32bit = () => {
    let u = new Uint32Array(1);
    window.crypto.getRandomValues(u);
    let str = u[0].toString(16).toUpperCase();
    return "00000000".slice(str.length) + str;
};

const generateToken = () => {
    let out = "";

    for(let i = 0; i < 8; i++) {
        out += random32bit();
    }

    return out;
};

class IntegrationSetupState {
    @observable ready = true;
    @observable selectedTab = 1;
    @observable selectedCompany = null;

    @observable name = "CFOfxn Connector";
    @observable target = BASE_URL;
    @observable token = generateToken();

    @observable companies = [];
    @observable divisions = [];

    @observable selectedDivisions = [];

    @action setReady = ready => this.ready = ready;
    @action setName = name => this.name = name;
    @action setTarget = target => this.target = target;

    @action reset = () => {
        this.ready = true;
        this.selectedTab = 1;
        this.selectedCompany = null;
        this.name = "CFOfxn Connector";
        this.target = BASE_URL;
        this.token = generateToken();
        this.companies.replace([]);
        this.divisions.replace([]);
        this.selectedDivisions.replace([]);
    }

    @action selectTab = id => this.selectedTab = id;

    @action addCompany = company => {
        this.companies.push(company);
    }

    @action deleteCompany = companyId => {
        this.companies.replace(this.companies.filter(x => x.value != companyId));

        if(this.selectedCompany && companyId == this.selectedCompany.value) {
            this.selectedCompany = null;
        }
    }

    @action selectCompany = async(company) => {
        try {
            let divisions = await DivisionService.safe.getAllByParent(company.value) || [];
            // divisions = divisions.filter(x => {
            //     if(x.AccountingSystemId != 2) return false;
            //TODO: Filter by valid QB setup!
            // });

            this.selectedCompany = company;
            this.divisions.replace(divisions.map(x => ({
                id: x.CompanyId,
                name: x.Company
            })));
        } catch(ex) {
            console.error(ex);
            return Alert.showGenericError();
        }
    }

    @action onTryAddCompany = async() => {
        let company = await showAddCompanyModal(this.companies);

        if(company) {
            this.companies.push(company);
        }
    }

    @action onSelectDivision = id => {
        if(this.selectedDivisions.includes(id)) {
            this.selectedDivisions.splice(this.selectedDivisions.indexOf(id), 1);
        } else {
            this.selectedDivisions.push(id);
        }
    }

    @action onSelectAllDivisions = () => {
        let ids = this.divisions.map(x => x.id);

        if(difference(ids, this.selectedDivisions).length) {
            this.selectedDivisions.replace(uniq([...this.selectedDivisions, ...ids]));
        } else {
            this.selectedDivisions.replace(this.selectedDivisions.filter(x => ids.indexOf(x) == -1));
        }
    }
}

const state = new IntegrationSetupState();
export default state;