import React, { Component } from "react";
import { Select } from "~/components/material";
import except from "lodash/omit";

export default class YearSelector extends Component {
    constructor(props) {
        super(props);

        let date = new Date();
        let { start = date.getUTCFullYear() - 25 } = props;
        let YEARS = [];
    
        let MAXYEAR;

        if(props.end) {
            MAXYEAR = props.end;
        } else {
            MAXYEAR = date.getUTCFullYear() + 1;
        }
        
        for(let i = start; i < MAXYEAR; i++) {
            YEARS.push(i);
        }

        if(props.order && props.order === "descending") {
            this.YEARS = YEARS.reverse();
        } else {
            this.YEARS = YEARS;
        }
    }

    getYears() {
        let { yearFilter, yearsWithData, disableYearsWithData = false } = this.props;

        if(yearFilter) {
            return this.YEARS.filter(x => yearFilter.includes(x)).map((year) => ({ label: year, value: year }));
        } else if(yearsWithData) {
            return this.YEARS.map(item => {
                if(yearsWithData.includes(item)) {
                    return { label: item + " (Data Exists)", value: item, disabled: disableYearsWithData };
                } else return { label: item, value: item };
            });
        } else return this.YEARS.map((year) => ({ label: year, value: year }));
    }

    render() {
        return (
            <Select
                {...except(this.props, "start", "end")}
                isClearable={false}
                data={this.getYears()} />
        );
    }
}