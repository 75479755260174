import React from "react";
import Loadable from "react-loadable";
import { Switch, Route } from "react-router-dom";
import { NotFound } from "~/views/Errors";
import LoadingSpinner from "~/components/LoadingSpinner";

const UserListView = Loadable({
    loader: () => import("~/views/admin/user/grid/UserListView"),
    loading: LoadingSpinner
});

const UserEditView = Loadable({
    loader: () => import("~/views/admin/user/UserEditView"),
    loading: LoadingSpinner
});

export default ({ match }) => (
    <Switch>
        <Route path={`${match.url}/new`} render={props => (<UserEditView {...props} newUser={true} />)} />
        <Route path={`${match.url}/:id`} component={UserEditView} />
        <Route path={`${match.url}/`} component={UserListView} />
        <Route component={NotFound} />
    </Switch>
);