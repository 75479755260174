import React from "react";
import { observer } from "mobx-react";

import AutoImportActions from "./components/AutoImportActions";

import showPopup from "~/components/Popup";

import SchedulingState from "./state";

//So far it works great with up to 100k records... If you need to test beyond that, I pity you.
// let testData = Array(100000).fill(0).map((_,i) => ({
//     uid: i,
//     name: `Test Schedule ${i + 1}`,
//     time: +new Date(),
//     date: +new Date(),
//     target: "Unknown"
// }));

const AutoImportEditor = observer(class AutoImportEditor extends React.Component {
    componentDidMount() {
        SchedulingState.loadSchedulesFromServer();
    }

    componentWillReact() {
        if(this.scrollBody) {
            this.scrollBody.scroll({ top: 0, left: 0, behavior: "smooth" }); //Smooth-scroll to the top of the list 
        }
    }

    render() {
        let rows = SchedulingState.currentPage;

        return (
            <div className="d-block tableFixHead w-100" ref={r => this.scrollBody = r}>
                <table className="table table-bordered table-striped table-hover instant card-body pb-0 mb-0">
                    <thead>
                        <tr>
                            <th className="w-100 p-2 bold">Schedule Name</th>
                            <th className="w-0 p-2 bold">Run Time</th>
                            <th className="w-0 p-2 bold">Occurrence</th>
                            <th className="w-0 p-2 bold">Import Target</th>
                            <th className="w-0 p-2 bold"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {!rows.length && (
                            <tr>
                                <td colSpan="5">No schedules found.</td>
                            </tr>
                        )}
                        {rows.map(x => (
                            <tr key={x.uid}>
                                <td className="nowrap">{x.name}</td>
                                <td className="nowrap">{x.time}</td>
                                <td className="nowrap">{x.date}</td>
                                <td className="nowrap">{x.target}</td>
                                <td>
                                    <button className="m-0 btn btn-block btn-success" color="green" onClick={() => SchedulingState.onEditSchedule(x.uid)}>
                                        Edit
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
});

export default () => showPopup({
    title: "Auto-Import Schedule Editor",
    shrinkAwayFromNav: true,
    children: <AutoImportEditor />,
    actions: <AutoImportActions />,
    disableEnforceFocus: true,
    classes: {
        paper: "d-flex flex-column mh-100 overflow-hidden",
        appbar: "position-relative"
    }
});