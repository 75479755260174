import { store } from "~/store";
import get from "lodash/get";

let shortMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
let fullMonths = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const getMonthName = (id, short = false) => (short ? shortMonths : fullMonths)[id - 1] || "Invalid Month";

export const getBalanceSheetName = () =>
    get(store.getState(), "company.BalanceSheet", "Balance Sheet");

export const getIncomeStatementName = () =>
    get(store.getState(), "company.IncomeStatement", "Income Statement");

export const getStatementTypeName = id =>
    [getBalanceSheetName(), getIncomeStatementName(), "Budget", "Forecast", "Analytics", "Cash Flow"][id - 1];

export const parseDate = (date, mode = "short", prefix = "", suffix = "") => {
    if(mode == "short") {
        if(!date.month || !date.year) return null;
        return prefix + shortMonths[date.month - 1] + " " + date.year + suffix;
    } else if(mode == "full") {
        if(!date.month || !date.year) return null;
        return prefix + fullMonths[date.month - 1] + " " + date.year + suffix;
    } else if(mode == "short.range") {
        return `${parseDate(date.startDate, "short", prefix, "")} - ${parseDate(date.endDate, "short", "", suffix)}`;
    } else if(mode == "full.range") {
        return `${parseDate(date.startDate, "full", prefix, "")} - ${parseDate(date.endDate, "full", "", suffix)}`;
    }

    return null;
};

export const getOrdinal = n =>
    n < 11 || n > 13 ? ["st", "nd", "rd", "th"][Math.min((n - 1) % 10, 3)] : "th";