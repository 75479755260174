import { observable, action, toJS } from "mobx";

class ReportingDefaultsStore {
    @observable company = null;
    @observable division = null;

    @action setData = (company, divisions) => {
        if(company && divisions && divisions.length) {
            this.company = company.raw;
            this.division = divisions[0].raw;
        } else {
            this.company = null;
            this.division = null;
            this.divisions = null;
        }
    }

    getCompany = () =>
        toJS(this.company);

    getDivision = () =>
        toJS(this.division);

    getDivisionId = () => {
        if(!this.division) return null;
        return toJS(this.division).CompanyId;
    }

    getCompanyId = () => {
        if(!this.company) return null;
        return toJS(this.company).CompanyId;
    }

    getCompanyName = () => {
        if(!this.company) return null;
        return toJS(this.company).Name;
    }

    getCompanyOption = (key = "value") => {
        if(!this.company) return null;
        let company = toJS(this.company);

        if(key == "value") {
            return {
                label: company.Name,
                value: company.CompanyId,
                raw: company
            };
        } else if(key == "id") {
            return {
                name: company.Name,
                id: company.CompanyId,
                raw: company
            };
        } else return null;
    }

    getDivisionOption = (key = "value") => {
        if(!this.division) return null;
        let division = toJS(this.division);

        if(key == "value") {
            return [{
                label: division.Company,
                value: division.CompanyId,
                raw: division
            }];
        } else if(key == "id") {
            return [{
                name: division.Company,
                id: division.CompanyId,
                raw: division
            }];
        } else return null;
    }
}

const singleton = new ReportingDefaultsStore();
export default singleton;