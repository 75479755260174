import React from "react";
import { Select } from "~/components/material";
import { Division as DivisionService } from "~/services/api";
import Permissions from "~/services/PermissionsDictionary";
import Alert from "~/services/ui/alerts";
import except from "lodash/omit";
import orderBy from "lodash/orderBy";

import SelectChip from "~/components/form/SelectChip";

export default class DivisionDropdown extends React.PureComponent {
    constructor(props) {
        super(props);
        let state = { divisions: null, raw: null, selectedDivision: null };

        if(props.value) {
            state.selectedDivision = props.value;
        } else if(props.input && !props.ignoreInput) {
            let val = props.input.value;

            if(Array.isArray(val) && val.length && val[0].id) {
                state.selectedDivision = val.map(x => ({
                    label: x.name,
                    value: x.id,
                    raw: x.raw
                }));
            } else state.selectedDivision = val;
        }

        this.state = state;
    }

    async refetch(companyId = null) {
        let {
            requiredCompanyPermission,
            includeCompany,
            useMultiOutput,
            companyLabel,
            selectedCompany
        } = this.props;

        if(companyId) {
            let {
                onError = e => {
                    console.error(e);
                    Alert.showGenericError();
                }
            } = this.props;

            try {
                let { error, res } = await DivisionService.getAllByParent(companyId);
                if(error) {
                    this.setState({ divisions: [] });
                    return onError(error);
                }

                let divisions = res[0].Divisions || [];
                
                divisions = orderBy(divisions.map(x => ({ label: x.Company, value: x.CompanyId, raw: x })), x => x.label.toLowerCase());

                if(requiredCompanyPermission) {
                    divisions = divisions.filter(x => Permissions.hasPermissionForCompany(this.props.requiredCompanyPermission, x.value));
                }

                if(includeCompany) {
                    if(useMultiOutput) {
                        divisions.unshift({
                            value: companyId,
                            label: res[0].Name,
                            raw: { CompanyId: companyId, Company: res[0].Name },
                            disabled: requiredCompanyPermission ? !Permissions.hasPermissionForCompany(requiredCompanyPermission, companyId) : false
                        });
                    } else {
                        divisions.unshift({
                            value: companyId,
                            label: companyLabel,
                            raw: selectedCompany,
                            disabled: requiredCompanyPermission ? !Permissions.hasPermissionForCompany(requiredCompanyPermission, companyId) : false
                        });
                    }
                }

                this.setState({ divisions, raw: res[0].Divisions || [], selectedDivision: null }, this.preselectCheck);
            } catch(error) {
                this.setState({ divisions: [], raw: [] });
                return onError(error);
            }
        } else {
            this.setState({ divisions: [], raw: [] });
        }
    }

    preselectCheck = () => {
        let { initialData, multiple } = this.props;

        if(multiple) {
            if(initialData && initialData.divisionId && Array.isArray(initialData.divisionId)) {
                let division = this.state.divisions.filter(x => initialData.divisionId.includes(x.value));
                
                if(division.length) {
                    this.onSelectDivision(division, true);
                }
            }
        } else {
            if(initialData && initialData.divisionId) {
                let division = this.state.divisions.find(x => x.value == initialData.divisionId);
                
                if(division) {
                    this.onSelectDivision(division, true);
                }
            }
        }
    }

    static getDerivedStateFromProps(nextProps, oldState) {
        if(nextProps.value === oldState.selectedDivision)
            return null;

        if(nextProps.value) {
            let value = nextProps.value;

            if(Array.isArray(value) && !nextProps.multiple) {
                value = value[0];
            }

            return {
                selectedDivision: value
            };
        } else {
            return null;
        }
    }

    componentDidMount() {
        this.refetch(this.props.companyId);
    }

    componentDidUpdate(prevProps) {
        if(this.props.companyId !== prevProps.companyId || this.props.companyLabel != prevProps.companyLabel) {
            this.refetch(this.props.companyId);
        } else if(!this.props.multiple && Array.isArray(this.state.selectedDivision) && this.state.divisions && this.state.selectedDivision) {
            this.setState({ selectedDivision: this.state.selectedDivision[0] }, () => this.onSelectDivision(this.state.selectedDivision, true));
        } else if(prevProps.multiple != this.props.multiple && this.props.multiple && this.state.divisions && this.state.selectedDivision) {
            this.setState({ selectedDivision: [this.state.selectedDivision] }, () => this.onSelectDivision(this.state.selectedDivision, true));
        }
    }

    onSelectDivision = (option, fake = false) => {
        let { onSelectDivision = this.props.onChange || (() => null), multiple, newMultiFormat, useMultiOutput, input, genericChangeHandler } = this.props;

        if(!option) return;

        if(!Array.isArray(option) && option.value === 0) {
            return this.onSelectAll();
        } else if(Array.isArray(option) && option.find(x => x.value === 0)) {
            return this.onSelectAll();
        }

        let order = orderBy(option, "value");

        if(useMultiOutput) {
            let onChangeHandler = genericChangeHandler ? genericChangeHandler : input ? input.onChange : onSelectDivision;

            if(multiple) {
                this.setState({ selectedDivision: order }, () => onChangeHandler(
                    order.filter(x => x && x.value).map(x => ({
                        id: x.value,
                        name: x.label,
                        raw: x.raw
                    }))
                ));
            } else if(option) {
                this.setState({ selectedDivision: option.value }, () => onChangeHandler([{
                    id: option.value,
                    name: option.label,
                    raw: option.raw
                }]));
            } 
        } else {
            if(multiple) {
                this.setState({ selectedDivision: order }, () => onSelectDivision(
                    !newMultiFormat ? order : (
                        order.map(x => ({
                            id: x.value,
                            name: x.label,
                            raw: x.raw
                        }))
                    ),
                    fake
                ));
            } else if(option) {
                this.setState({ selectedDivision: option.value }, () => onSelectDivision(option, fake));
            }
        }
    }

    onSelectAll = () => {
        this.setState({
            selectedDivision: this.state.divisions
        }, () => this.onSelectDivision(this.state.divisions));
    }

    render() {
        let { divisions, selectedDivision } = this.state;
        let { multiple, ignoreInput, showSelectAll } = this.props;

        let message = (
            divisions ? (
                divisions.length ? "Select a Division" : "No Divisions Available"
            ) : "Loading divisions..."
        );

        let passedProps = ["onSelectCompany", "onChange", "onError", "input", "showSelectAll", "genericChangeHandler"];

        if(ignoreInput) {
            passedProps.push("ignoreInput", "input");
        }

        let val = selectedDivision;

        if(!multiple && Array.isArray(val)) {
            val = val[0];
        }

        let data = divisions ? [...divisions] : null;
        if(multiple && showSelectAll && data && data.length) {
            data.unshift({
                label: "All Divisions",
                value: 0
            });
        }

        return (
            <Select
                {...except(this.props, ...passedProps)}
                placeholder={message} 
                data={data}
                value={val}
                onChange={this.onSelectDivision}
                valueComponent={multiple ? SelectChip : undefined} />
        );
    }
}