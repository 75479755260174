import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Card, UselessLink } from "~/components/material";
import { setPageTitle } from "~/actions/ui";

class GenericErrorPage extends Component {
    constructor(props) {
        super(props);
        props.setPageTitle("");
    }

    render() {
        return (
            <div className="container">
                <Card top-icon="exclamation-triangle" top-color="red" border-color="red" className="animated fadeInUp">
                    <h2>{this.props.message}</h2>
                    <p>{this.props.details}</p>
                </Card>
            </div>
        );
    }
}

const NotFoundPage = withRouter((props, context) => (
    <GenericError
        message="404 Not Found"
        details={(
            <React.Fragment>
                Sorry, the resource you are looking for does not exist. <UselessLink onClick={props.history.goBack}>Go back</UselessLink>
            </React.Fragment>
        )} />
));

export const GenericError = connect(null, { setPageTitle })(GenericErrorPage);
export const NotFound = connect(null, { setPageTitle })(NotFoundPage);