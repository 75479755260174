import React, { Component } from "react";
import Alert from "~/services/ui/alerts";
import $ from "~/lib/elemutil";

import Popup from "~/components/popups/Popup";
import AbstractForm from "~/components/generic/GenericForm";
import ScrollUpFob from "~/components/buttons/ScrollUpFob";
import { PortalUser as UserService } from "~/services/api";
import ProgressButton from "~/components/buttons/ProgressButton";

const fields = [
    { name: "FirstName", label: "First Name", type: "text", required: true },
    { name: "MiddleName", label: "Middle Name", type: "text" },
    { name: "LastName", label: "Last Name", type: "text", required: true },
    { name: "Email", label: "Email Address", type: "text", editable: false },
    { name: "Phone", label: "Phone Number", type: "phone" }
];

export default class ProfileModal extends Component {
    onScrollTop() {
        let { popup } = this.popup.refs;

        $.animate({
            elem: popup,
            style: "scrollTop",
            unit: "",
            from: 0,
            to: popup.offsetTop,
            time: 1000,
            prop: true
        });
    }

    fixFields(values) {
        values.Active = values.Active ? 1 : 0;
        return Object.keys(values).reduce((opts, opt) => {
            opts[opt] = values[opt];
            if(values[opt] === null)
                delete opts[opt];
            return opts;
        }, {});
    }

    async resetPassword() {
        try {
            let { error } = await UserService.forgotPassword(this.props.data.Email);
            if(error) return Alert.showError(error);
            
            Alert.showMessage("The link to reset your password has been sent to your email address. It may take up to a few minutes for it to arrive.");
        } catch(ex) {
            console.log(ex);
            Alert.showGenericError();
        }
    }

    onSubmit = async(values) => {
        await this.submitBtn.setPolling();
        try {
            let { error } = await UserService.updateProfile(this.fixFields(values));

            if(error) {
                console.log(error);
                await this.submitBtn.done(false);
                return Alert.showError(error);
            }

            await this.submitBtn.done(true);
            // await Alert.async.showMessage("Profile successfully updated!");
            return this.onHide(true);
        } catch(ex) {
            console.log(ex);
            Alert.showGenericError();
            await this.submitBtn.done(false);
        }
    }

    onHide(reload = false) {
        let { onHide = () => null } = this.props;
        let { popup } = this.popup.refs;

        let cb = (
            !reload ? undefined : () => window.location.reload() //TODO: refetch user information instead of reloading, which logs you out
        );

        $(popup).addClass("slideOutUp");
        $(".scroll-top-fob").addClass("hidden");
        setTimeout(() => onHide(cb), 1000);
    }

    render() {
        let { visible = false, data } = this.props;
        if(!visible) return null;

        return (
            <React.Fragment>
                <Popup title="User Profile" className="animated slideInDown" ref={ref => this.popup = ref}>
                    <AbstractForm name="userProfile" fields={fields} onSubmit={this.onSubmit} data={data}>
                        <div a-dock="top-right">
                            <button type="button" className="btn btn-flat" onClick={e => this.resetPassword()}>Reset Password</button>
                            <ProgressButton manual ref={ref => this.submitBtn = ref} type="submit" color="green" text-color="white" className="btn">Save</ProgressButton>
                            <button type="button" className="btn btn-flat" onClick={() => this.onHide()}>Cancel</button>
                        </div>
                    </AbstractForm>
                </Popup>
                <ScrollUpFob onClick={e => this.onScrollTop()} className="animated fadeIn" />
            </React.Fragment>
        );
    }
}