import React from "react";
import { observer } from "mobx-react";

import Icon from "@material-ui/core/Icon";
import Tooltip from "~/components/material/Tooltip";
import UselessLink from "~/components/material/UselessLink";

import NotificationStore from "~/state/NotificationsStore";

export default observer(() => (
    <div className="header">
        <p className="notifications-title">Notifications</p>
        <div className="button-section">
            {NotificationStore.hasNewNotifications && (
                <Tooltip placement="left" title="Mark All Read">
                    <UselessLink onClick={NotificationStore.markAllRead} className="waves-effect">
                        <Icon>playlist_add_check</Icon>
                    </UselessLink>
                </Tooltip>
            )}
            {!!NotificationStore.notifications.length && (
                <Tooltip placement="left" title="Clear All">
                    <UselessLink onClick={NotificationStore.clearAll} className="waves-effect">
                        <Icon>check</Icon>
                    </UselessLink>
                </Tooltip>
            )}
        </div>
    </div>
));