import { withStyles as renderWithStyles } from "@material-ui/core/styles";
export { default as classNames } from "~/lib/classNames";

/**
 * Composes multiple JSS stylesheets into one
 * @param {...(function|Object.<string, Object.<string, string>>)} styles Styles to compose
 */
export const withStyles = (...styles) => Component => {
    //TODO: Add style-level overrides instead of just className-level
    let style = (...args) => styles.reduce((stylesheet, style) => {
        if(typeof style === "function")
            return {...stylesheet, ...style(...args)};

        return {
            ...stylesheet,
            ...style
        };
    }, {});

    return renderWithStyles(style)(Component);
};

export const mergeStyles = (...styles) => (...args) => {
    return styles.reduce((stylesheet, style) => {
        if(typeof style === "function")
            return {...stylesheet, ...style(...args)};

        return {
            ...stylesheet,
            ...style
        };
    }, {});
};