import { API } from "./index";
import { get } from "../net";

class TermsAndConditionsService {
    async getLatestTermsAndConditions() {
        let request = get({
            endpoint: "terms-and-conditions",
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }
}

const inst = new TermsAndConditionsService();
export default inst;