import React, { Component } from "react";
import { connect } from "react-redux";
import except from "lodash/omit";
import get from "lodash/get";
import { Link, withRouter } from "react-router-dom";
import UserProfileModal from "./components/ProfileModal";
import CompanyDropdown from "~/components/form/fields/CompanyDropdown";
import { Navbar, UselessLink, ConfirmationModal } from "~/components/material";
import Dropdown, { Item, Header } from "~/components/material/Dropdown";
import { showConfirmationModal } from "~/components/material/Modal/ConfirmationModal";
import Alert from "~/services/ui/alerts";
import Alerts from "react-s-alert";
import BackButton from "~/components/material/BackButton";

import { RedirectProvider } from "~/routes";
import { getBalanceSheetName, getIncomeStatementName } from "~/services/ui/LanguageService";
import { PortalUser as UserService } from "~/services/api";
import { logout } from "~/actions/auth";
import { CHANGE_GLOBAL_COMPANY } from "~/actions/definitions";
import { PermissionBasedItem, NodeBasedItem } from "./components/DropdownItems";
import { PermissionModulesBasedHeader } from "./components/AdminHeader";
import ReportingDefaultsModalBody from "./components/ReportingDefaultsBody";
import ReportingDefaultsStore from "~/state/ReportingDefaultsStore";
import { isResponsive } from "~/hoc/Responsive";
import CfoAssistant from "~/components/CfoAssistant";
import Notifications from "~/components/Notifications";
import openDebugNotificationModal from "~/components/Notifications/components/NotificationTestModal";
import openScheduleEditor from "~/views/scheduling";
import showIntegrationWizard from "~/views/admin/integrationsetup";

const stateMap = ({ auth: { token, account }, ui: { title }, company }) => ({
    authenticated: token && token.length,
    account,
    title,
    company
});

const selectCompany = (payload) => ({
    type: CHANGE_GLOBAL_COMPANY, payload
});

@isResponsive
@withRouter
@connect(stateMap, { logout, selectCompany })
export default class Template extends Component {
    state = {
        data: null,
        selectingCompany: false,
        selectedCompany: get(this.props, "company", null),
        selectedCompanyId: get(this.props, "company.CompanyId", -1)
    }

    onLogoutClick = e => {
        e.preventDefault();
        return this.props.logout();
    }

    changeCompany = () => {
        let onSucceed = () => {
            let { selectedCompany } = this.state;

            this.setState({ selectedCompany: null, selectingCompany: false }, () =>{
                if(selectedCompany) {
                    Alert.showMessage(`Selected ${selectedCompany.Name}`, null, "bottom");
                } else if(this.props.company) {
                    Alert.showMessage("Deselected company.", null, "bottom");
                }

                this.props.selectCompany(
                    except(selectedCompany, "Divisions")
                );
            });
        };

        let onFail = () =>
            this.setState({ selectedCompany: null, selectingCompany: false });

        this.setState({ selectingCompany: true }, () => 
            this.selectCompanyModal.popup(onSucceed, onFail)
        );
    }

    onSelectCompany = (e) => {
        this.setState({
            selectedCompany: (e && e.raw) ? e.raw : null
        });
    }

    onSelectReportingCompanies = async() => {
        let data = null;
        let ok = await showConfirmationModal({
            title: "Set Reporting Defaults",
            showTitle: true,
            children: props => (
                <ReportingDefaultsModalBody {...props} setResult={x => data = x} />
            )
        });

        if(ok) {
            ReportingDefaultsStore.setData(data.company, data.divisions);
            return await Alert.async.showMessage("Successfully updated reporting settings.");
        }
    }

    renderName() {
        let {authenticated, account} = this.props;

        if(authenticated) {
            let trigger;
            if(this.props.isMobile){
                trigger = <UselessLink><span className="navbar-toggler navbar-toggler-icon"></span></UselessLink>;
            }else{
                trigger = <UselessLink className="navbar-text pull-right h5 mb-0 p-0" text-color="black">{account.name}</UselessLink>;
            }
            return (
                <Dropdown className="dropdown-success" trigger={trigger} show-caret={this.props.isMobile ? false : true}>
                    <Header>My Commands</Header>
                        <Item onClick={e => this.showModal(e)}>My Profile</Item>
                        <Item onClick={this.onLogoutClick}>Logout</Item>
                    <PermissionModulesBasedHeader modules={["admin", "company"]}>System Admin</PermissionModulesBasedHeader>
                        <NodeBasedItem node={["admin.admin", "company.create", "company.read", "company.update", "company.delete"]} destination="/admin/companies">Companies Administration</NodeBasedItem>
                        <PermissionBasedItem module="user" destination="/admin/users">User Administration</PermissionBasedItem>
                    <Header key={0}>{(this.props.company && this.props.company.CompanyId) ? this.props.company.Name : "No Company Selected"}</Header>
                        <Item key={1} onClick={this.changeCompany}>{this.props.company ? "Change Company" : "Select Company"}</Item>
                        <Item onClick={this.onSelectReportingCompanies}>Reporting Defaults</Item>
                    <PermissionModulesBasedHeader modules={["tools", "tweaks"]}>Tools</PermissionModulesBasedHeader>
                        <NodeBasedItem node="tools.modifyScheduling" onClick={openScheduleEditor}>Automatic Imports Editor</NodeBasedItem>
                        <NodeBasedItem node="tweaks.debugMode" key={"debug_notifs"} onClick={openDebugNotificationModal}>Notification Debug</NodeBasedItem>
                        <NodeBasedItem node="tools.setupImportTool" onClick={showIntegrationWizard}>Integration Wizard</NodeBasedItem>
                </Dropdown>
            );
        }
            
        return (
            <Link to="/login" text-color="white" className="navbar-text pull-right h5 mb-0 p-0">Login</Link>
        );
    }

    renderBackArrow() {
        if(!this.props.title.noBackArrow) {
            return (<BackButton />);
        }
    }

    renderTitle() {
        if(this.props.title) {
            let { title } = this.props;
            let breadcrumb = null;
            let bs = getBalanceSheetName();
            let is = getIncomeStatementName();

            let n = ["", bs, is, "Budget", "Forecast"];

            let fix = txt => typeof txt === "string" ? txt.replace(/%{(\d)}/g, (_,x) => n[x]) : txt;

            if(title) {
                if(!["/home", "/login", "/password/forgot", "/password/reset"].includes(this.props.location.pathname) && !title.hideBreadcrumb) {
                    breadcrumb = (
                        <h5 className={title.className}><Link to="/">Home</Link></h5>
                    );
                }
            }

            if(typeof title === "string") {
                return (
                    <React.Fragment>
                        <div className="row px-4 d-none d-md-block mb-0">{breadcrumb}</div>
                        <div className="row px-4 d-none d-md-block">
                            <h3>{fix(title)}</h3>
                        </div>
                    </React.Fragment>
                );
            } else {
                return (
                    <React.Fragment>
                        {!title.hideBreadcrumb && (
                            <div className="row px-4 d-none d-md-block mb-0">{breadcrumb}</div>
                        )}
                        <div className={["row px-4 d-none d-md-block", title.className].join(" ")}>
                            <h3 className={title.children ? "pull-left" : undefined}>
                                {fix(title.text)}
                            </h3>
                            {title.children && title.children()}
                        </div>
                    </React.Fragment>
                );
            }
        } else return null;
    }

    async showModal(e) {
        try {
            let req = await UserService.getAccountDetails();
            let { error, payload } = req;
            if(error) return Alert.showError(error);
            
            this.setState({
                data: payload.info
            });
        } catch(ex) {
            Alert.showGenericError();
            console.log(ex);
        }
    }

    onHideProfileModal(cb) {
        this.setState({
            data: null
        });

        if(cb) return cb();
    }

    get pathName() {
        let path = this.props.location.pathname.replace(/\//g, "_");
        if(/_\d+$/.test(path)) {
            path = path.replace(/\d+$/, "") + " view" + path.replace(/_\d+$/, "");
        }

        return path;
    }

    render() {
        return (
            <React.Fragment>
                <RedirectProvider />
                <Navbar color="grey" color-shade="1" name-color="black" text-color="black">
                    <ul className="navbar-nav mr-auto">
                        {this.props.company && (
                            <li className="nav-item">{this.props.company.Name}</li>
                        )}
                    </ul>
                    {this.props.authenticated && (
                        <Notifications />
                    )}
                    {this.renderName()}
                    {this.props.authenticated && (
                        <CfoAssistant />
                    )}
                </Navbar>
                <div className={`col main view${this.pathName}`}>
                    {this.renderTitle()}
                    {this.props.children}
                </div>
                <div className="cfofxn-footer">
                    <span>CFOfxn℠ {new Date().getUTCFullYear()}. All Rights Reserved.</span>
                    <span>
                        <a text-color="green" href="/documentation/CFOfxn Master Services Agreement.pdf" target="_blank">Privacy Policy</a>
                    </span>
                </div>
                <UserProfileModal
                    visible={this.state.data !== null}
                    data={this.state.data}
                    onHide={(e) => this.onHideProfileModal(e)} />

                <ConfirmationModal ref={ref => this.selectCompanyModal = ref}>
                    <CompanyDropdown addNone isImportant
                        onSelectCompany={this.onSelectCompany}
                        value={this.state.selectedCompanyId} />
                </ConfirmationModal>
                <Alerts stack={{ limit: 1 }} html={true} />
            </React.Fragment>
        );
    }
}