import React, { Component } from "react";
import { ImageCard } from "~/components/material";

export default class Tile extends Component {
    render() {
        let {
            title,
            link,
            icon,
            className,
            enabled = true,
            comingSoon = false,
            rotate = "false",
            size = 4,
            offset,
            iconStyle = {},
            titleProps = {},
            innerProps = {},
            "text-color": textColor = "black",
            ...otherProps
        } = this.props;

        let opts = otherProps;

        if(icon.includes(".svg") || icon.includes(".png")) {
            opts["image"] = icon;
            opts["style"] = {
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center -16px",
                ...iconStyle
            };
        } else {
            opts["background-icon"] = icon;
        }

        opts.color = enabled ? opts.color : "grey"; 

        if(comingSoon) {
            return(
                <div className={[`col-md-12 col-lg-${size} my-2`, className, offset ? `offset-lg-${offset}` : ""].join(" ")}>
                    <ImageCard
                        rotate-icon={rotate}
                        className="h-100"
                        title="Coming Soon"
                        center-title="true"
                        href={link}
                        text-color={textColor}
                        color="grey"
                        color-shade={4}
                        enabled={false}
                        titleProps={titleProps}
                        shadow="lg"
                        innerCardProps={innerProps}
                        {...opts}
                    >{title}</ImageCard>
                </div>
            );
        } else {
            return(
                <div className={`col-md-12 col-lg-${size} my-2 ${className}`}>
                    <ImageCard
                        rotate-icon={rotate}
                        className="h-100"
                        title={title}
                        center-title="true"
                        href={link}
                        text-color={textColor}
                        color={enabled ? "green" : "blue-grey"}
                        enabled={enabled}
                        disabled={!enabled}
                        titleProps={titleProps}
                        shadow="lg"
                        innerCardProps={innerProps}
                        {...opts} />
                </div>
            );
        }
    }
}