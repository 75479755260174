import sortBy from "lodash/sortBy";
import indexOf from "lodash/indexOf";

export const cashFlowHighlights = [
    "Change in Cash",
    "Ending Cash",
    "Total Operating Activity",
    "Total Investing Activity",
    "Total Financing Activity",
    "Change in Operating Activity",
    "Change in Investing Activity",
    "Change in Financing Activity"
];

export const highlightWithBorder = [
    "Gross Profit",
    "Total Cost of Goods Sold",
    "Total Revenue",
    "Total Expense",
    "EBITDA Adjustments",
    "Total Current Assets",
    "Total Noncurrent Assets",
    "Total Current Liabilities",
    "Total Noncurrent Liabilities",
    "Total Liabilities",
    "Equity",

    ...cashFlowHighlights
];

export const highlightWithBackground = [
    "Total Assets",
    "Total Liabilities and Equity",
    "Total Liabilities & Equity",
    "Net Income",
    "Total EBITDA"
];

export const shouldHighlight = (label, row) => {
    let out = {};

    if(highlightWithBorder.includes(label)) {
        out.border = "green";
    }

    if(highlightWithBackground.includes(label)) {
        out.highlight = "green";
    }
    
    if(row.copied) {
        out.highlight = "yellow";
    }

    return out;
};

export const highlighter = (isCashFlow) => (label, row) => {
    let out = shouldHighlight(label, row);

    if(isCashFlow && out.highlight) {
        delete out.highlight;
    }

    if(["Total Operating Activity", "Total Investing Activity", "Total Financing Activity"].includes(label)) {
        out.customContents = `Change in ${label.substring(6)}`;
    }

    return out;
};

export const shouldHighlightExport = (label, isCashFlow = false) =>
    isCashFlow ? cashFlowHighlights.includes(label.toString().trim()) : shouldHighlightRow(label);

export const shouldHighlightRow = (label = "") =>
    highlightWithBorder.includes(label.toString().trim()) || highlightWithBackground.includes(label.toString().trim());

export const insertIntoHierarchy = (parent, row) => {
    if(parent.StandardAccountId && !parent.children) parent.children = {};

    if(row.ParentAccountId in parent) {
        parent[row.ParentAccountId].children = {
            ...parent[row.ParentAccountId].children,
            [row.StandardAccountId]: row
        };
    } else if(parent.children) {
        if(row.ParentAccountId in parent.children) {
            parent.children[row.ParentAccountId].children = {
                ...parent.children[row.ParentAccountId].children,
                [row.StandardAccountId]: row
            };
        } else if(parent.StandardAccountId) {
            for(let id in parent.children) {
                parent.children[id] = insertIntoHierarchy(parent.children[id], row);
            }
        } else {
            for(let id in parent) {
                parent[id] = insertIntoHierarchy(parent[id], row);
            }
        }
    } else {
        for(let id in parent) {
            parent[id] = insertIntoHierarchy(parent[id], row);
        }
    }

    return parent;
};

export const SORT_ORDERS = { //Sorry about the disappearing act, I appear to have dozed off a little bit.
    "balance-sheet": ["Assets", "Liabilities", "Equity", "Total Liabilities & Equity"],
    "income-statement": ["Revenue", "Cost of Goods Sold",  "Gross Profit", "Income", "Expense", "Net Income", "EBITDA", "Notes"],
    "cashFlow": ["Operating Activity", "Investing Activity", "Financing Activity", "Change in Cash", "Beginning Cash", "Ending Cash"]
};

export const sortItems = (root, mode) => sortBy(root, obj => indexOf(SORT_ORDERS[mode], obj.label));

//Sorts the parent levels in the hierarchy to the desired order (defined by mode)
export const sortHierarchy = sortItems;

export const parse = (report, Mode, _, type) => {
    return sortBy(report, x => x.StandardAccountId).reduce((Report, row, i) => {
        if(type === 6 && !!row.ParentAccountId) { //Assumptions
            try {
                if(typeof row.Calculations === "string" || !!!row.Calculations) {
                    row.Calculations = JSON.parse(row.Calculations || "[]");
                }
            } catch(ex) {
                console.log(row.Calculations);
                console.error(ex);
            }
        }

        //Level-based hierarchy
        let level = Report.Levels[row.Level] || [];
        Report.Levels[row.Level] = [...level, row];

        //ParentId-Child-based hierarchy
        if(!row.ParentAccountId) {
            Report.Hierarchy[row.StandardAccountId] = row;
        } else {
            Report.Hierarchy = insertIntoHierarchy(Report.Hierarchy, row);
        }

        return Report;
    }, { Levels: [], Hierarchy: {}, Raw: report, Mode });
};

export const convertItem = (item, section) => {
    let out = {};
    let amount = item.Amount || item.Amounts || 0;
    if(item.EBITDA && parseInt(item.ParentAccountId) === 4 && section === "EBITDA") {
        if(Array.isArray(amount)) {
            amount = amount.map(x => -x);
        } else {
            amount = -amount;
        }
    }
    if(item.children && Object.keys(item.children).length) {
        out = {
            _hasParent: item.ParentAccountId && parseInt(item.ParentAccountId) > 0,
            _ebitda: item.EBITDA,
            id: item.StandardAccountId,
            label: item.Name,
            bold: true,
            value: amount,
            priority: item.SortPriority || 0,
            highlight: item.Highlight,
            totalName: item.TotalName,
            border: item.Border,
            children: sortBy(Object.keys(item.children).map(key => convertItem(item.children[key], item.Name)), x => -x.priority, x => x.label.toLowerCase())
        };
    } else {
        out = {
            _hasParent: item.ParentAccountId && parseInt(item.ParentAccountId) > 0,
            _ebitda: item.EBITDA,
            id: item.StandardAccountId,
            label: item.Name,
            value: amount,
            bold: item.Bold,
            border: item.Border,
            priority: item.SortPriority || 0,
            highlight: item.Highlight,
            calculations: item.Calculations,
            parentAccountId: item.ParentAccountId
        };
    }

    return out;
};

export const generateTree = (Report, mode, columns = 1) => {
    let itemized = Object.keys(Report.Hierarchy).map(x => convertItem(Report.Hierarchy[x]));
    let sorted = sortHierarchy(itemized, Report.Mode);
    
    return sorted;
};

export const convertToTree = (report, mode) => generateTree(report, mode);