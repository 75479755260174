import { faEdit, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import React from "react";
import { Link } from "react-router-dom";
import AsyncComponent from "~/components/AsyncComponent";
import { Select, TextInput } from "~/components/material";
import Modal from "~/components/material/Modal/Modal";
import BookKeepingService from "~/services/api/bookKeeping";
import Alert from "~/services/ui/alerts";

import CompanyDropdown from "~/components/form/fields/CompanyDropdown";
import DivisionDropdown from "~/components/form/fields/DivisionDropdown";
import MonthDropdown from "~/components/form/fields/MonthSelector";
import YearDropdown from "~/components/form/fields/YearSelector";

import { Metadata as MetaService } from "~/services/api";
import * as ProjectionsUtils from "~/views/admin/projections/util/CashFlowPatch";


export default class BookKeepingLinksModal extends AsyncComponent {

    constructor() {
        super();
        this.state = {
            anchorEl: null,
            open: false,
            mode: "add",
            modalOpen: false,
            confirmationModalOpen: false,
            links: [],
            reportTypes: [
                { label: "Edit Cash Flow", value: "cashFlow" },
                { label: "Edit Budget", value: "budget" },
                { label: "Edit Forecast", value: "forecast" }
            ],
            selectedReportName: null,
            selectedReportType: null,
            selectedCompany: null,
            selectedDivision: null,
            selectedYear: null,
            selectedMonth: null,
            filteredYears: [],
            filteredMonths: []
        };
    }

    async componentDidMount() {
        const { error, payload = [] } = await BookKeepingService.getBookKeepingLinks(this.props.userId);
        if (error) Alert.showError("An error was encountered while retrieving Book Keeping links");

        this.setState({ links: payload });
    }

    handleClick = (event) => {
        this.setState({ ...this.state, anchorEl: event.currentTarget, open: true });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
            open: false,
            modalOpen: false,
            selectedReportName: null,
            selectedReportType: null,
            selectedCompany: null,
            selectedDivision: null,
            selectedYear: null,
            selectedMonth: null,
            filteredYears: [],
            filteredMonths: []
        });
    };

    handleModalOpen = async (bookKeepingLinkId) => {
        if (bookKeepingLinkId) {
            const selectedLink = this.state.links?.filter(link => link?.bookKeepingLinkId === bookKeepingLinkId);

            console.log(selectedLink);
            this.setState(prev => ({
                ...prev,
                modalOpen: true,
                anchorEl: null,
                open: false,
                mode: "edit",
                bookKeepingLinkId,
                selectedReportName: selectedLink[0]?.reportName,
                selectedReportType: this.state.reportTypes.find(report => report.value === selectedLink[0]?.reportType),
                selectedCompany: {
                    id: selectedLink[0]?.CompanyId
                },
                selectedDivision: selectedLink[0]?.DivisionId,
                selectedYear: selectedLink[0]?.year,
                selectedMonth: selectedLink[0]?.month
            }), this.loadYearsWithData);
        } else {
            this.setState(prev => ({
                ...prev,
                modalOpen: true,
                anchorEl: null,
                open: false,
                mode: "add"
            }));
        }
    }

    handleModalClose = (event) => {
        this.setState({
            anchorEl: event?.currentTarget,
            open: false,
            modalOpen: false,
            selectedReportName: null,
            selectedReportType: null,
            selectedCompany: null,
            selectedDivision: null,
            selectedYear: null,
            selectedMonth: null,
            filteredYears: [],
            filteredMonths: []
        });
    }

    handleConfirmationModalOpen = () => {
        this.setState({ confirmationModalOpen: true });
    }

    handleConfirmationModalClose = () => {
        this.setState({ confirmationModalOpen: false });
    }

    validate = () => {
        if (!this?.state?.selectedReportName) {
            Alert.showError("Report name is required");
            return false;
        }

        if (!this?.state?.selectedReportType?.value) {
            Alert.showError("Report Type is required");
            return false;
        }

        if (!this?.state?.selectedCompany?.id) {
            Alert.showError("Company is required");
            return false;
        }

        if (!this?.state?.selectedDivision) {
            Alert.showError("Division is required");
            return false;
        }

        if (!this?.state?.selectedYear) {
            Alert.showError("Year is required");
            return false;
        }

        if (!this?.state?.selectedMonth) {
            Alert.showError("Month is required");
            return false;
        }

        return true;
    }

    saveBookKeepingLink = async () => {
        if (this.validate()) {
            const payload = {
                userId: this.props.userId,
                reportName: this?.state?.selectedReportName,
                reportType: this?.state?.selectedReportType?.value,
                company: this?.state?.selectedCompany?.id,
                year: this?.state?.selectedYear,
                month: this?.state?.selectedMonth,
                division: this?.state?.selectedDivision
            };
            if (this.state.mode === "edit") {
                const result = await BookKeepingService.updateBookKeepingLink({
                    ...payload,
                    bookKeepingLinkId: this?.state?.bookKeepingLinkId
                });
                if (result?.req?.status != 200) return Alert.showError("Bookkeeping link cannot be updated at this time. Try some time later.");
                this.handleModalClose();
                Alert.showMessage("BookKeeping Link updated successfully");
            } else {
                const result = await BookKeepingService.createBookKeepingLink(payload);
                if(result?.req?.status != 200) {
                    this.handleConfirmationModalOpen();
                    setTimeout(() => {
                        this.handleConfirmationModalClose();
                    }, 2000);
                    return;
                }
                this.handleModalClose();
                Alert.showMessage("BookKeeping Link created successfully");
            }

            await this.componentDidMount();
        }
    }

    handleDeleteLink = async (id) => {
        await BookKeepingService.deleteBookKeepingLink(id);

        await this.componentDidMount();
    }

    getPath = (link) => {
        const queryParamiters = `?companyId=${link.CompanyId}&divisionId=${link.DivisionId}&month=${link.month}&year=${link.year}&stage=1&mode=2&stature=bookKeeping`;
        switch (link.reportType) {
            case "cashFlow":
                return "/admin/cashFlow" + queryParamiters;
            case "budget":
                return "/admin/budget" + queryParamiters;
            case "forecast":
                return "/admin/forecast" + queryParamiters;
        }
    }

    onSelectReportName = e => {
        this.setState({ selectedReportName: e.target.value });
    }

    onSelectReportType = (selectedReportType) => {
        this.setState({ selectedReportType, selectedCompany: null, selectedDivision: null, selectedYear: null, selectedMonth: null });
    }

    onSelectCompany = selectedCompany => {
        this.setState({ selectedCompany, selectedDivision: null, selectedYear: null, selectedMonth: null });
    }

    onSelectDivision = ({ value }) => {
        this.setState({ selectedDivision: value, selectedYear: null, selectedMonth: null }, this.loadYearsWithData);
    }

    onSelectYear = ({ value }) => {
        this.setState({ selectedYear: value, selectedMonth: null }, this.loadMonthsWithData);
    }

    onSelectMonth = ({ value }) => {
        this.setState({ selectedMonth: value });
    }

    loadYearsWithData = async () => {
        try {
            const selectedType = ProjectionsUtils.convertMode(this?.state?.selectedReportType?.value);
            console.log("selectedType", selectedType);
            console.log("selectedType", selectedType);

            const yearsWithData = await MetaService.safe.getYearsWithData(this.state.selectedDivision, selectedType);
            console.log("yearsWithData", yearsWithData);
            const latest = await MetaService.safe.getLatestDateWithData(selectedType, this.state.selectedDivision);
            console.log("latest", latest);

            let newState = { filteredYears: yearsWithData };

            if (this.state?.selectedYear && this.state?.selectedMonth) {
                newState.selectedYear = this.state?.selectedYear;
                newState.selectedMonth = this.state?.selectedMonth;
            } else if (latest.year && latest.month) {
                newState.selectedYear = latest.year;
                newState.selectedMonth = latest.month;
            }

            console.log("newState", newState);

            if (newState.selectedYear) {
                console.log(this.state.selectedDivision, newState.selectedYear, selectedType);
                const filteredMonths = await MetaService.safe.getMonthsWithData(this.state.selectedDivision, newState.selectedYear, selectedType);
                console.log("filteredMonths", filteredMonths);
                newState.filteredMonths = filteredMonths.map(x => x.Month);
            }

            this.setState(newState);
        } catch (ex) {
            console.log(ex);
            Alert.showGenericError();
        }
    }

    loadMonthsWithData = async () => {
        try {
            const selectedType = ProjectionsUtils.convertMode(this?.state?.selectedReportType?.value);
            const filteredMonths = await MetaService.safe.getMonthsWithData(this.state.selectedDivision, this.state.selectedYear, selectedType);

            this.setState({ filteredMonths: filteredMonths.map(x => x.Month) });
        } catch (ex) {
            console.log(ex);
            Alert.showGenericError();
        }
    }

    render() {
        const date = new Date();

        console.log(this.state);

        return (
            <React.Fragment>
                {this.props.isMobile ? (
                    <IconButton onClick={this.handleClick}>
                        <Icon>link</Icon>
                    </IconButton>
                ) : (
                    <span className="text-success" onClick={this.handleClick}>
                        <Link to="#">
                            <img
                                src="/res/book-keeping-links.svg"
                                alt="bookkeeping icon"
                                title="BookKeeping Links"
                                height={65}
                            />
                        </Link>
                    </span>
                )}
                <Popover
                    id="simple-popover"
                    open={this.state.open}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left"
                    }}
                >
                    <Typography className="p-3">
                        <div className="w-100 d-flex justify-content-center align-items-center flex-column">
                            <img src="/res/book-keeping-links.svg" alt="bookeeping links logo" width="50px" />
                            <span className="font-weight-bold">BookKeeping Links</span>
                        </div>
                        <br />
                        <table className="table" style={{ width: "300px" }}>
                            <thead className="thead-dark">
                                <tr>
                                    <th align="center" scope="col" className="d-flex justify-content-between align-items-center p-2">
                                        <span>Report Name</span>
                                        <button className="btn btn-sm btn-success font-weight-bold" onClick={() => this.handleModalOpen()}>ADD NEW LINK</button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {this.state.links?.map(link => (
                                        <td key={link.bookKeepingLinkId} className="d-flex justify-content-between align-items-center p-2" style={{ height: "50px" }}>
                                            <Link to={this.getPath(link)} className="text-primary" onClick={() => this.handleModalClose()}>{link.reportName}</Link>
                                            <div>
                                                <IconButton
                                                    text-color="gray"
                                                    size="small"
                                                    onClick={() => this.handleModalOpen(link.bookKeepingLinkId)}
                                                >
                                                    <FontAwesomeIcon icon={faEdit} style={{ cursor: "pointer" }} />
                                                </IconButton>
                                                {" "}
                                                <IconButton
                                                    text-color="red"
                                                    size="small"
                                                    onClick={() => this.handleDeleteLink(link.bookKeepingLinkId)}
                                                >
                                                    <FontAwesomeIcon icon={faTrash} style={{ cursor: "pointer" }} />
                                                </IconButton>
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </Typography>
                </Popover>
                <Modal
                    controlled
                    visible={this.state.modalOpen}
                    footer={<Footer onCloseModal={this.handleModalClose} mode={this.state.mode} saveBookKeepingLink={this.saveBookKeepingLink} />}
                    title={"Custom Bookkeeping Link Editor"}
                    hideLines
                    showClose={false}
                    titleClass={"mx-auto font-weight-bold"}>
                    <TextInput
                        name="Report Name"
                        label="Report Name"
                        placeholder="Report Name"
                        value={this.state.selectedReportName}
                        required={true}
                        show-required="true"
                        onChange={this.onSelectReportName}
                        labelActive={true}
                    />
                    <Select
                        label="Report Type"
                        placeholder="Report Type"
                        value={this.state.selectedReportType}
                        data={this.state.reportTypes}
                        required={true}
                        show-required="true"
                        onChange={this.onSelectReportType} />
                    {this.state.selectedReportType?.value && (
                        <CompanyDropdown
                            onSelectCompany={this.onSelectCompany}
                            value={this.state.selectedCompany?.id}
                        />
                    )}
                    {this.state.selectedCompany && (
                        <DivisionDropdown requiredCompanyPermission={`${this.state.mode}.update`}
                            value={this.state.selectedDivision}
                            companyId={this.state.selectedCompany?.id}
                            includeCompany
                            selectedCompany={this.state.selectedCompany?.raw}
                            companyLabel={this.state.selectedCompany?.name}
                            onSelectDivision={this.onSelectDivision} />
                    )}
                    {this.state.selectedDivision && (
                        <YearDropdown
                            start={date.getUTCFullYear() - 25}
                            end={date.getUTCFullYear() + 25}
                            placeholder={"Select a Year"}
                            value={this.state.selectedYear}
                            yearFilter={this.state.filteredYears}
                            onChange={this.onSelectYear} />
                    )}
                    {this.state.selectedYear && (
                        <MonthDropdown forceExistsCheck
                            placeholder="Select Month"
                            value={this.state.selectedMonth}
                            onChange={this.onSelectMonth}
                            monthFilter={this.state.filteredMonths}
                            noResultsText={this.divisionErrMsg} />
                    )}
                </Modal>
                <Modal
                    visible={this.state.confirmationModalOpen}
                    head={false}
                    showClose={false}
                    bodyClass={"bg-danger text-light"}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4
                    }}>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Internal Server error or a duplicate bookkeeping link name. Try some time later or try changing the bookkeeping link name.
                    </Typography>
                    </Box>
                </Modal>
            </React.Fragment>
        );
    }
}

const Footer = ({ saveBookKeepingLink, mode, onCloseModal }) => (
    <div className="row">
        <button type="button"
            className="btn btn-success"
            onClick={saveBookKeepingLink}>{mode === "edit" ? "UPDATE" : "SAVE"}</button>
        <button type="button"
            className="btn btn-flat waves-effect"
            onClick={onCloseModal}>CANCEL</button>
    </div>
);