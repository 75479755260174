import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { injectState } from "~/hoc/Mobx";

import AlertBubble from "~/components/material/Alert";
import CompanyList from "./components/CompanyGrid";
import DivisionList from "./components/DivisionGrid";

import state from "../../state";

@injectState(state, false)
export default class TargetsView extends React.Component {
    render() {
        let { companies, divisions, selectedCompany, onTryAddCompany, deleteCompany, selectCompany, selectedDivisions, onSelectDivision, onSelectAllDivisions } = this.props;

        return (
            <Router>
                <React.Fragment>
                    <AlertBubble color="blue-grey" text-color="white" className="h6 no-border-radius row m-0 flex-shrink-0 mw-100">
                        The unique application key, paired with this division list, allows fine-grain control over each tenant's access rights. They can only perform imports for the selected divisions.
                    </AlertBubble>
                    <div className="row flex-shrink-1 m-0 flex-row">
                        <CompanyList companies={companies}
                            onTryAddCompany={onTryAddCompany}
                            onDeleteCompany={deleteCompany}
                            onSelectCompany={selectCompany} />
                        {selectedCompany ? (
                            <DivisionList divisions={divisions}
                                selectedDivisions={selectedDivisions}
                                selectedCompany={selectedCompany}
                                onSelectDivision={onSelectDivision}
                                onSelectAllDivisions={onSelectAllDivisions} />
                        ) : (
                            <div className="col-md-8 d-flex align-items-center" color="grey" color-shade="2">
                                <span>Select a company to assign access to a division</span>
                            </div>
                        )}
                    </div>
                </React.Fragment>
            </Router>
        );
    }
}