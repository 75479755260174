import React from "react";
import UselessLink from "~/components/material/UselessLink";
import MonthSelector from "./MonthSelector";
import YearSelector from "./YearSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import MetaService from "~/services/api/meta";

import shortid from "shortid";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import findIndex from "lodash/findIndex";

export default class MultipleDateSelector extends React.Component {
    state = {
        dates: this.propsSelected,
        dateFilters: {},
        yearFilters: []
    }

    get propsSelected() {
        let { exact, input, value } = this.props;
        let newInput = get(input, "value", value);

        if(newInput == "" || !newInput || !Array.isArray(newInput))
            newInput = [];

        newInput.forEach(val => {
            if(!val.year) {
                val.month = null;
            }
        });

        if(exact && !newInput.length) {
            newInput = Array(exact).fill(0).map(x => ({ year: null, month: null, id: shortid() }));
        }

        return newInput;
    }

    updateSelectionsFromProps() {
        let val = this.propsSelected;
        this.setState({ dates: val }, this.updateParent);
    }

    updateMonthFilters = (id, cb) => async() => {
        let date = this.state.dates.find(x => x.id === id);

        let data = await MetaService.safe.getMonthsWithData(this.props.selectedCompanies, date.year, this.props.statementType);
        this.setState({
            dateFilters: {
                ...this.state.dateFilters,
                [date.year]: data
            }
        }, cb);
    }

    updateAllMonthFilters = async() => {
        let { selectedCompanies, statementType } = this.props;
        let dateFilters = {};

        for(let date of this.state.dates) {
            if(!date.year) continue;
            dateFilters[date.year] = await MetaService.safe.getMonthsWithData(selectedCompanies, date.year, statementType);
        }

        this.setState({ dateFilters });
    }

    updateYearsWithData = async empty => {
        let yearFilters = await MetaService.safe.getYearsWithData(this.props.selectedCompanies, this.props.statementType);

        if(empty) {
            let isOkay = this.state.dates.map(x => yearFilters.includes(x.year)).reduce((a,b) => a && b, true);

            if(isOkay) {
                this.setState({
                    yearFilters
                }, this.updateAllMonthFilters);
            } else {
                this.setState({
                    yearFilters,
                    dateFilters: {},
                    dates: Array(this.props.exact ? this.props.exact : 0).fill(0).map(x => ({ year: null, month: null, id: shortid() }))
                }, this.updateParent);
            }
        } else {
            this.setState({
                yearFilters
            });
        }
    }

    updateParent = () => {
        let { input, onChange = e => null } = this.props;
        get(input, "onChange", onChange)(this.state.dates);
    }

    componentDidMount() {
        let { selectedCompanies = [] } = this.props;

        if(selectedCompanies.length) {
            this.updateYearsWithData(true);
        }
    }

    componentDidUpdate(props) {
        let { exact, disabled, input, value } = this.props;
        
        if(exact !== props.exact && exact) {
            this.setState({
                dates: Array(exact).fill(0).map(x => ({ year: null, month: null, id: shortid() })),
                dateFilters: {},
                yearFilters: []
            });
        } else if(input) {
            let oldVal = get(props, "input.value", props.value);
            let newVal = get(input, "value", value);

            if(props.disabled !== disabled || !isEqual(props.selectedCompanies, this.props.selectedCompanies)) {
                this.updateYearsWithData(true);
            } else if(!isEqual(oldVal, newVal) && !isEqual(newVal, this.state.dates)) {
                if(exact) {
                    this.setState({
                        dates: Array(exact).fill(0).map(x => ({ year: null, month: null, id: shortid() })),
                        dateFilters: {},
                        yearFilters: []
                    }, this.updateYearsWithData);
                } else {
                    this.updateSelectionsFromProps();
                }
            }
        } else if(props.disabled !== disabled || !isEqual(props.selectedCompanies, this.props.selectedCompanies)) {
            this.updateYearsWithData(true);
        }
    }

    removeDate = id => () => {
        let dates = [...this.state.dates];
        dates.splice(findIndex(dates, x => x.id === id), 1);

        this.setState({ dates }, this.updateParent);
    }

    onAddRow = () => {
        this.setState({
            dates: [
                ...this.state.dates,
                { year: null, month: null, id: shortid() }
            ]
        }, this.updateParent);
    }

    onChangeYear = id => ({ value }) => {
        let dates = [...this.state.dates];
        let date = dates.find(x => x.id === id);
        date.year = value;
        date.month = null;

        this.setState({ dates }, this.updateMonthFilters(id, this.updateParent));
    }

    onChangeMonth = id => ({ value }) => {
        let dates = [...this.state.dates];
        dates.find(x => x.id === id).month = value;

        this.setState({ dates }, this.updateParent);
    }

    render() {
        let { label, disabled, max = 12, exact } = this.props;
        let { dates = [], dateFilters, yearFilters } = this.state;
        let date = new Date();

        return (
            <table className="w-100">
                <colgroup>
                    {!!!exact && (<col width="0" />)}
                    <col />
                    <col />
                </colgroup>
                <tbody>
                    {!!!exact && (
                        <tr>
                            <td colSpan="2" className="p-0">
                                <h3 className="pull-left p-0 pt-1">{label}</h3>
                                <button className="btn btn-sm pull-right" color="green" text-color="white" onClick={this.onAddRow} disabled={disabled || dates.length === max}>
                                    <FontAwesomeIcon icon={faPlus} />
                                </button>
                            </td>
                        </tr>
                    )}
                    {dates.map(({ year, month, id }) => {
                        return (
                            <tr key={id}>
                                {!!!exact && (
                                    <td className="p-1" style={{ width: "3rem" }}>
                                        <UselessLink  onClick={this.removeDate(id)}><h3>&times;</h3></UselessLink>
                                    </td>
                                )}
                                <td className="row">
                                    <YearSelector colspan="col-6" className="m-0"
                                        disabled={disabled}
                                        start={date.getUTCFullYear() - 25}
                                        end={date.getUTCFullYear() + 25}
                                        placeholder="Select a Year"
                                        onChange={this.onChangeYear(id)}
                                        yearFilter={yearFilters}
                                        value={year} />

                                    <MonthSelector colspan="col-6" className="m-0"
                                        disabled={disabled || !!!year}
                                        placeholder="Select a Month"
                                        monthFilter={dateFilters[year] || []}
                                        value={month}
                                        onChange={this.onChangeMonth(id)} />
                                </td>
                            </tr>
                        );
                    })}
                    {!dates.length && (
                        <tr>
                            <td colSpan={1 + (!!!exact)}>Add a date using the plus button</td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }
}