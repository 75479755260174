let dict = {
    BalanceSheet: {
        Assets: 1,
        Liabilities: 2,
        Equity: 3,

        CurrentAssets: 7,
        NoncurrentAssets: 8,
        CurrentLiabilities: 9,
        NoncurrentLiabilities: 10,

        Cash: 11,
        Savings: 12,
        Investments: 13,
        AccountsReceivable: 14,
        AllowanceForDoubtfulAccounts: 15,
        Inventory: 16,
        Prepaid: 17,
        ContractAsset: 18,
        OtherCurrent: 19,
        PropertyAndEquipment: 20,
        LoanReceivable: 21,
        IntangibleAssets: 22,
        RightOfUseAsset: 23,
        IntercompanyAsset: 24,
        OtherNoncurrent: 25
    },

    IncomeStatement: {
        Revenue: 4,
        CostOfGoodsSold: 5,
        Expense: 6,

        AccountsPayable: 26,
        AccruedLiabilities: 27,
        DeferredRevenue: 28,
        Deposit: 29,
        CurrentDebt: 30,
        ContractLiability: 31,
        OtherCurrent: 32,
        LongTermDebt: 33,
        IntercompanyLiability: 34,
        LeaseLiability: 35,
        OtherNoncurrent: 36,
        BalanceSheetRevenue: 37,
        Rent: 38,
        Sales: 39,
        PatientRevenue: 40,
        RoomAndBoard: 41,
        DevelopmentFees: 42,
        OneTimeFees: 43,
        Dues: 44,
        Subscriptions: 45,
        OtherRevenue1: 46,
        OtherRevenue2: 47,
        OtherRevenue3: 48,
        OtherRevenue4: 49,
        OtherRevenue5: 50,
        ContraRevenue: 51,
        Salaries: 52,
        Wages: 53,
        OfficersCompensation: 54,
        Commissions: 55,
        Benefits: 56,
        PayrollTaxes: 57,
        AdvertisingAndMarketing: 59,
        Accounting: 60,
        Legal: 61,
        InformationTechnology: 62,
        Collections: 63,
        WebTools: 64,
        Internet: 65,
        Cable: 66,
        Utilities: 67,
        OfficeSupplies: 68,
        InsuranceExpense: 69,
        Phone: 70,
        Travel: 71,
        RepairsAndMaintenance: 72,
        Landscaping: 73,
        MealsAndEntertainment: 74,
        Shipping: 75,
        RealEstateTax: 76,
        PropertyTax: 77,
        Food: 78,
        BadDebt: 79,
        Cleaning: 80,
        ManagementFees: 81,
        SharedCostAllocation: 82,
        Transfers: 83,
        Grants: 84,
        MeetingExpense: 85,
        Conferences: 86,
        Events: 87,
        Printing: 88,
        Magazine: 89,
        BankFees: 91,
        Administration: 92,
        SellingAndGeneral: 93,
        Program: 94,
        Member: 95,
        Alumnae: 96,
        Volunteer: 97,
        Charitable: 98,
        Fundraising: 99,
        Other1: 100,
        Other2: 101,
        Other3: 102,
        Other4: 103,
        Other5: 104,
        Other6: 105,
        Other7: 106,
        Other8: 107,
        Amortization: 108,
        Depreciation: 109,
        Interest: 110,
        IncomeTaxes: 111,
        CouncilDues: 167,
        OperatingDues: 168,
        InitiationFees: 169,
        NewMemberFees: 170,
        UseFee: 171,
        CarRental: 172,
        StartUp: 173,
        Research: 174,
        Development: 175,
        Growth: 176,
        RealEstateTax2: 178,
        PersonalPropertyTax: 179
    },

    CashFlow: {
        OperatingActivity: 2000,
        InvestingActivity: 2001,
        FinancingActivity: 2002,
        BeginningCash: 2003
    }
};

export const IncomeStatement = dict.IncomeStatement;
export const BalanceSheet = dict.BalanceSheet;
export const CashFlow = dict.CashFlow;
export default dict;