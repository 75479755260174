import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { tokenReducer, errorReducer } from "./auth";
import accountReducer from "./account";
import { tenantData, tenant } from "./tenant";
import pageTitleReducer, { hasAssignedCompaniesReducer } from "./ui";
import analyticsReducer from "./reporting";

import { CHANGE_GLOBAL_COMPANY } from "~/actions/definitions";

let selectedCompany = localStorage.getItem("company") || null;

if(selectedCompany) {
    selectedCompany = JSON.parse(atob(selectedCompany));
    console.log(`Using locally-stored company ${selectedCompany.Name}`);
}

const currentCompany = (state = null, { type, payload }) => {
    if(type !== CHANGE_GLOBAL_COMPANY) return state;

    if(!payload || !payload.CompanyId) {
        localStorage.removeItem("company");
        return null;
    }
    
    localStorage.setItem("company", btoa(JSON.stringify(payload)));
    document.title = `${payload.Name} CFOfxn`;

    return payload;
};

const reportingReducers = combineReducers({
    analytics: analyticsReducer
});

const uiReducers = combineReducers({
    title: pageTitleReducer,
    hasAssignedCompanies: hasAssignedCompaniesReducer
});

const authReducers = combineReducers({
    token: tokenReducer,
    error: errorReducer,
    account: accountReducer
});

const reducers = combineReducers({
    auth: authReducers,
    tenant: tenant,
    tenantDetails: tenantData,
    form: formReducer,
    ui: uiReducers,
    company: currentCompany,
    reporting: reportingReducers
});

export let initialState = {
    company: selectedCompany
};

export default reducers;
// export const store = createStore(
//     reducers,
//     initialState
// );