import { createStore, applyMiddleware } from "redux";
import ReduxPromise from "redux-promise-middleware";
import ReduxThunk from "redux-thunk";
import reducers, { initialState } from "./reducers";
import { Company as CompanyService } from "./services/api";
import "./lib/pipeline"; //Import the pipeline
import except from "lodash/omit";

import { CHANGE_GLOBAL_COMPANY } from "./actions/definitions";

//To migrate from redux-promise to redux-promise-middleware
function removeFullfilledSuffixMiddleware() {
    return (next) => (action) => next({
        ...action,
        type: action.type.replace(/_FULFILLED$/, "")
    });
}

const createStoreWithMiddleware = applyMiddleware(
    ReduxThunk,
    ReduxPromise,
    removeFullfilledSuffixMiddleware
)(createStore);
const store = createStoreWithMiddleware(
    reducers,
    initialState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
export { store };
export const setState = (newState) => store.dispatch(newState);

if(initialState.company) {
    store.dispatch({
        type: CHANGE_GLOBAL_COMPANY,
        payload: async () => {
            try {
                let req = await CompanyService.getCompanyById(initialState.company.CompanyId);

                if(req.res && req.res.CompanyId) {
                    return except(req.res, "Divisions");
                }
            } catch(ex) { }

            return initialState.company;
        }
    });
}