import DailyFields from "./components/DailyFields";
import WeeklyFields from "./components/WeeklyFields";
import MonthlyFields from "./components/MonthlyFields";

export const periods = [
    { label: "Daily", value: "daily" },
    { label: "Weekly", value: "weekly" },
    { label: "Monthly", value: "monthly" }/*,
    { label: "Yearly", value: "yearly" }*/
];

export const fields = {
    "daily": DailyFields,
    "weekly": WeeklyFields,
    "monthly": MonthlyFields/*,
    "yearly": () => null*/
};