//Material Design Bootstrap's entire CSS color palette, with some additional features, in around 110 lines of JS.
//The original, uncompressed CSS stylesheet this was created to replace is roughly 50kb. Compressed, this is 2.81kb
const colors = {
    red: { primary: ["ffebee", "ffcdd2", "ef9a9a", "e57373", "ef5350", "f44336", "e53935", "d32f2f", "c62828", "b71c1c"], accent: ["ff8a80", "ff5252", "ff1744", "d50000"] },
    pink: { primary: ["fce4ec", "f8bbd0", "f48fb1", "f06292", "ec407a", "e91e63", "d81b60", "c2185b", "ad1457", "880e4f"], accent: ["ff80ab", "ff4081", "f50057", "c51162"] },
    purple: { primary: ["f3e5f5", "e1bee7", "ce93d8", "ba68c8", "ab47bc", "9c27b0", "8e24aa", "7b1fa2", "6a1b9a", "4a148c"], accent: ["ea80fc", "e040fb", "d500f9", "a0f"] },
    "deep-purple": { primary: ["ede7f6", "d1c4e9", "b39ddb", "9575cd", "7e57c2", "673ab7", "5e35b1", "512da8", "4527a0", "311b92"], accent: ["b388ff", "7c4dff", "651fff", "6200ea"] },
    indigo: { primary: ["e8eaf6", "c5cae9", "9fa8da", "7986cb", "5c6bc0", "3f51b5", "3949ab", "303f9f", "283593", "1a237e"], accent: ["8c9eff", "536dfe", "3d5afe", "304ffe"] },
    blue: { primary: ["e3f2fd", "bbdefb", "90caf9", "64b5f6", "42a5f5", "2196f3", "1e88e5", "1976d2", "1565c0", "0d47a1"], accent: ["82b1ff", "448aff", "2979ff", "2962ff"] },
    "light-blue": { primary: ["e1f5fe", "b3e5fc", "81d4fa", "4fc3f7", "29b6f6", "03a9f4", "039be5", "0288d1", "0277bd", "01579b"], accent: ["80d8ff", "40c4ff", "00b0ff", "0091ea"] },
    cyan: { primary: ["e0f7fa", "b2ebf2", "80deea", "4dd0e1", "26c6da", "00bcd4", "00acc1", "0097a7", "00838f", "006064"], accent: ["84ffff", "18ffff", "00e5ff", "00b8d4"] },
    teal: { primary: ["e0f2f1", "b2dfdb", "80cbc4", "4db6ac", "26a69a", "009688", "00897b", "00796b", "00695c", "004d40"], accent: ["a7ffeb", "64ffda", "1de9b6", "00bfa5"] },
    green: { primary: ["e8f5e9", "00c851", "a5d6a7", "81c784", "66bb6a", "4caf50", "43a047", "388e3c", "2e7d32", "1b5e20"], accent: ["b9f6ca", "69f0ae", "00e676", "00c853"] },
    "light-green": { primary: ["f1f8e9", "dcedc8", "c5e1a5", "aed581", "9ccc65", "8bc34a", "7cb342", "689f38", "558b2f", "33691e"], accent: ["ccff90", "b2ff59", "76ff03", "64dd17"] },
    lime: { primary: ["f9fbe7", "f0f4c3", "e6ee9c", "dce775", "d4e157", "cddc39", "c0ca33", "afb42b", "9e9d24", "827717"], accent: ["f4ff81", "eeff41", "c6ff00", "aeea00"] },
    yellow: { primary: ["fffde7", "fff9c4", "fff59d", "fff176", "ffee58", "ffeb3b", "fdd835", "fbc02d", "f9a825", "f57f17"], accent: ["ffff8d", "ff0", "ffea00", "ffd600"] },
    amber: { primary: ["fff8e1", "ffecb3", "ffe082", "ffd54f", "ffca28", "ffc107", "ffb300", "ffa000", "ff8f00", "ff6f00"], accent: ["ffe57f", "ffd740", "ffc400", "ffab00"] },
    orange: { primary: ["fff3e0", "ffe0b2", "ffcc80", "ffb74d", "ffa726", "ff9800", "fb8c00", "f57c00", "ef6c00", "e65100"], accent: ["ffd180", "ffab40", "ff9100", "ff6d00"] },
    "deep-orange": { primary: ["fbe9e7", "ffccbc", "ffab91", "ff8a65", "ff7043", "ff5722", "f4511e", "e64a19", "d84315", "bf360c"], accent: ["ff9e80", "ff6e40", "ff3d00", "dd2c00"] },
    brown: { primary: ["efebe9", "d7ccc8", "bcaaa4", "a1887f", "8d6e63", "795548", "6d4c41", "5d4037", "4e342e", "3e2723"] },
    grey: { primary: ["fafafa", "f5f5f5", "eee", "e0e0e0", "bdbdbd", "9e9e9e", "757575", "616161", "424242", "212121"] },
    "blue-grey": { primary: ["eceff1", "cfd8dc", "b0bec5", "90a4ae", "78909c", "607d8b", "546e7a", "455a64", "37474f", "263238"] },
    black: { primary: "000" },
    white: { primary: "fff" },
    // primary: { primary: '007bff' },
    // secondary: { primary: '868e96' },
    info: { primary: "17a2b8" },
    success: { primary: "00c851" },
    warning: { primary: "ffc107" },
    danger: { primary: "dc3545" },
    accent: { primary: "448aff" },
    light: { primary: "f8f9fa" },
    dark: { primary: "343a40" },
    "deep-blue": { primary: "32416A" }
};

let elem = document.createElement("style");
elem.type = "text/css";

function addTextColor(name, color, k = null, v = null) {
    if(k !== null && v !== null) {
        add(`[text-color*="${name}"][text-color-${k}="${v}"],a[text-color*="${name}"][text-color-${k}="${v}"]:not([text-hover-color]):hover,[text-hover-color*="${name}"][text-hover-color-${k}="${v}"]:hover {color: ${color} !important}`);
    } else {
        add(`[text-color*="${name}"],a[text-color*="${name}"]:not([text-hover-color]):hover,[text-hover-color*="${name}"]:hover {color: ${color} !important}`);
    }
}
function add() {elem.innerHTML += Array.prototype.slice.call(arguments).join("");}
const addColor = (name, color, darkness = null, accent = null) => {
    if(darkness === null && accent === null) {
        add(
            `[color*="${name}"],[hover-color*="${name}"]:hover {background-color: #${color} !important}`,
            `[border*="${name}"] {border: 2px solid #${color} !important}`,
            `[border-color*="${name}"] {border-color: #${color} !important}`,
            `[badge][badge-color*="${name}"][data-badge]:after {background-color: #${color} !important}`
        );
        addTextColor(name, "#" + color);
    } else {
        let [k, v] = darkness > -1 ? ["shade", darkness] : ["accent", accent];
        add(
            `[color*="${name}"][color-${k}="${v}"],[hover-color*="${name}"][hover-color-${k}="${v}"] {background-color: #${color} !important}`,
            `[border*="${name}"][border-${k}="${v}"] {border: 2px solid #${color} !important}`,
            `[border-color*="${name}"][border-color-${k}="${v}"] {border-color: #${color} !important}`
        );
        addTextColor(name, "#" + color, k, v);
    }
};

add("[color*=\"shadow\"] {background-color: #000 !important; opacity: .5 !important}");
addTextColor("shadow", "#000 !important");
add("[color*=\"brighten\"] {background-color: #fff !important; opacity: .5 !important}");
addTextColor("brighten", "#fff !important");

for(let i = 0; i < 10; i++) {
    add(`[color*="shadow"][color-shade="${i}"],[color*="brighten"][color-shade="${i}"],[text-color*="shadow"][text-color-shade="${i}"],[text-color*="brighten"][text-color-shade="${i}"] { opacity: ${i / 10} !important }`);
}

add(
    "[reset-color] {background-color: unset !important}",
    "[reset-text] {text-decoration: none;color: unset !important}",
    "[reset-border] {border: unset !important}",
    "[color][color-fade] {transition: background-color linear 1s}",
    "[color][color-fade=\"fast\"] {transition: background-color linear 0.3s}",
    "[text-color][text-color-fade] {transition: color linear 1s}",
    "[text-color][text-color-fade=\"fast\"] {transition: color linear 0.3s}",
    "[border][border-fade] {transition: border linear 1s}",
    "[border][border-fade=\"fast\"] {transition: border linear 0.3s}",
    "[border-color][border-color-fade] {transition: border-color linear 1s}",
    "[border-color][border-color-fade=\"fast\"] {transition: border-color linear 0.3s}",
    ".transparent,[transparent],[color*=\"transparent\"],[hover-color*=\"transparent\"]:hover {background: transparent !important}",
    "[border-color*=\"transparent\"] {border: 2px solid transparent !important}"
);

addTextColor("transparent", "transparent");

//This used to be a forEach, then later an additional reduce to produce the :root{} data, but it's been condensed to one loop instead of two
let root = Object.getOwnPropertyNames(colors).reduce((set, name) => {
    let color = colors[name];
    if(typeof color.primary === "string") {
        addColor(name, color.primary);
        set += `--${name}: #${color.primary};`;
    } else {
        addColor(name, color.primary[color.primary.length / 2]);
        color.primary.forEach((col, i) => addColor(name, color.primary[i], i));
        set += `--${name}: #${color.primary[color.primary.length / 2]};`;
    }

    if(color.accent) {
        color.accent.forEach((col, i) => addColor(name, color.accent[i], undefined, i));
    }

    return set;
}, "");

add(`:root {${root}}`);
document.head.appendChild(elem); //Only append to DOM once all the styles are ready

export default colors;