export const parseDate = ({ year, month }) =>
    ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][month - 1] + " " + year;

let basicKeys = {
    _hasParent: false,
    _ebitda: false,
    bold: false,
    priority: 0
};

export const metrics = {
    daysCashOnHand: "Days Cash Onhand",
    cash: "Cash",
    currentRatio: "Current Ratio",
    daysInAccountsReceivable: "Days in Accounts Receivable",
    /*daysInInventory: "Days in Inventory",*/
    daysInAccountsPayable: "Days in Accounts Payable",
    debtToEquityRatio: "Debt to Equity Ratio",
    principalPayments: "Last Twelve Months Principal Payments",
    revenue: "Last Twelve Months Revenue",
    ebitda: "Last Twelve Months EBITDA",
    ebitdaMargin: "EBITDA Margin",
    netIncome: "Last Twelve Months Net Income",
    netIncomeMargin: "Net Income Margin",
    grossProfit: "Last Twelve Months Gross Profit",
    grossProfitMargin: "Gross Profit Margin",
    dscr: "Debt Service Coverage Ratio",
    fccr: "Fixed Charge Coverage Ratio",

    iRevenue: "Revenue",
    iCogs: "Cost of Sales",
    iExpense: "Expense",
    iNetIncome: "Net Income",
    iEbitda: "EBITDA"
};

export const groups = [
    { name: "Amounts", id: "_amounts", children: ["cash", "principalPayments", "revenue", "ebitda", "netIncome", "grossProfit"] },
    { name: "Percentage", id: "_percentage", children: ["currentRatio", "debtToEquityRatio", "ebitdaMargin", "netIncomeMargin", "grossProfitMargin", "dscr", "fccr"] },
    { name: "Days", id: "_days", children: ["daysCashOnHand", "daysInAccountsReceivable"/*, "daysInInventory"*/, "daysInAccountsPayable"] }
];

export const operatingGroups = [
    { name: "Income Statement", id: "_income", children: ["iRevenue", "iCogs", "iExpense", "iNetIncome", "iEbitda"] }
];

export const basicTable = amounts => [
    { ...basicKeys, id: "daysCashOnHand", label: metrics.daysCashOnHand, value: amounts["daysCashOnHand"], formulas: amounts["daysCashOnHand"].formulas },
    { ...basicKeys, id: "cash", label: metrics.cash, value: amounts["cash"], formulas: amounts["cash"].formulas },
    { ...basicKeys, id: "currentRatio", label: metrics.currentRatio, value: amounts["currentRatio"], formulas: amounts["currentRatio"].formulas },
    { ...basicKeys, id: "daysInAccountsReceivable", label: metrics.daysInAccountsReceivable, value: amounts["daysInAccountsReceivable"], formulas: amounts["daysInAccountsReceivable"].formulas },
    // { ...basicKeys, id: "daysInInventory", label: metrics.daysInInventory, value: amounts["daysInInventory"], formulas: amounts["daysInInventory"].formulas },
    { ...basicKeys, id: "daysInAccountsPayable", label: metrics.daysInAccountsPayable, value: amounts["daysInAccountsPayable"], formulas: amounts["daysInAccountsPayable"].formulas },
    { ...basicKeys, id: "debtToEquityRatio", label: metrics.debtToEquityRatio, value: amounts["debtToEquityRatio"], formulas: amounts["debtToEquityRatio"].formulas },
    { ...basicKeys, id: "principalPayments", label: metrics.principalPayments, value: amounts["principalPayments"], formulas: amounts["principalPayments"].formulas },
    { ...basicKeys, id: "revenue", label: metrics.revenue, value: amounts["revenue"], formulas: amounts["revenue"].formulas },
    { ...basicKeys, id: "ebitda", label: metrics.ebitda, value: amounts["ebitda"], formulas: amounts["ebitda"].formulas },
    { ...basicKeys, id: "ebitdaMargin", label: metrics.ebitdaMargin, value: amounts["ebitdaMargin"], formulas: amounts["ebitdaMargin"].formulas },
    { ...basicKeys, id: "netIncome", label: metrics.netIncome, value: amounts["netIncome"], formulas: amounts["netIncome"].formulas },
    { ...basicKeys, id: "netIncomeMargin", label: metrics.netIncomeMargin, value: amounts["netIncomeMargin"], formulas: amounts["netIncomeMargin"].formulas },
    { ...basicKeys, id: "grossProfit", label: metrics.grossProfit, value: amounts["grossProfit"], formulas: amounts["grossProfit"].formulas },
    { ...basicKeys, id: "grossProfitMargin", label: metrics.grossProfitMargin, value: amounts["grossProfitMargin"], formulas: amounts["grossProfitMargin"].formulas },
    { ...basicKeys, id: "dscr", label: metrics.dscr, value: amounts["dscr"], formulas: amounts["dscr"].formulas },
    { ...basicKeys, id: "fccr", label: metrics.fccr, value: amounts["fccr"], formulas: amounts["fccr"].formulas },

    { ...basicKeys, id: "iRevenue", label: metrics.iRevenue, value: amounts["iRevenue"], formulas: null },
    { ...basicKeys, id: "iCogs", label: metrics.iCogs, value: amounts["iCogs"], formulas: null },
    { ...basicKeys, id: "iExpense", label: metrics.iExpense, value: amounts["iExpense"], formulas: null },
    { ...basicKeys, id: "iNetIncome", label: metrics.iNetIncome, value: amounts["iNetIncome"], formulas: null },
    { ...basicKeys, id: "iEbitda", label: metrics.iEbitda, value: amounts["iEbitda"], formulas: null }
];