import React, { Component } from "react";
import $, { mixClass } from "~/lib/elemutil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import except from "except";

export default class TextInput extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        if (props.innerRef) {
            props.innerRef(this);
        }
    }

    onFlipLabel(event, add, onClick) {
        if (!!this.props.labelActive) return;
        if (onClick && !!this.props.disabled) return;

        if (this.input) {
            if (add) {
                $(event.target).siblings("label,i,svg", true).forEach(e => e.addClass("active"));
                this.input.focus();
            } else if (typeof this.props.onBlur === "function") {
                this.props.onBlur(event);
            } else {
                let { value } = this.input;
                if (value && value.length) {
                    $(event.target).siblings("i,svg", true).forEach(e => e.removeClass("active"));
                } else {
                    $(event.target).siblings("label,i,svg", true).forEach(e => e.removeClass("active"));
                }
            }
        }
    }

    setLabel(label) {
        this.setState({ label });
    }

    onChange(e) {
        let val = e.target.value;
        if (this.props.maxLength && val && val.length > this.props.maxLength)
            return e.preventDefault();

        if (this.props.onChange) {
            this.props.onChange(e);
        } else if (this.props.input && this.props.input.onChange) {
            this.props.input.onChange(e);
        }
    }

    get value() {
        if (this.input)
            return this.input.value;

        return undefined;
    }

    render() {
        let name = this.props.id || this.props.name;
        let { label, meta, colspan, focused } = this.props;
        let errored = meta ? (meta.dirty && meta.invalid && meta.error) : false;

        let isActive = (
            (
                (this.props.value && this.props.value.length)
                || (this.props.input && this.props.input.value && this.props.input.value.length)
            ) ? "active" : undefined
        );

        let value = this.props.value;
        if (this.props.input) value = this.props.input.value;

        value = value || "";

        if (this.state.label) {
            label = this.state.label;
        }

        let mainSection = (
            <div className="md-form">
                {this.props.icon && (
                    <FontAwesomeIcon icon={this.props.icon} className={["prefix", isActive].join(" ")} text-color={errored ? "red" : ""} />
                )}
                <input
                    className={mixClass("form-control", this.props.className, errored ? "invalid" : "")}
                    type={this.props.type || "text"}
                    {...this.props.input}
                    {...except(this.props, "colspan", "maxLength", "type", "className", "input", "icon", "label", "name", "focused", "genericChangeHandler", "meta", "innerRef")}
                    placeholder={this.props.placeholder}
                    onBlur={e => this.onFlipLabel(e, false)}
                    onFocus={e => this.onFlipLabel(e, true)}
                    onInput={e => this.onFlipLabel(e, true)}
                    onChange={e => this.onChange(e)}
                    autoFocus={focused}
                    ref={ref => this.input = ref} />
                <label className={this.props.labelActive ? "active" : isActive} htmlFor={name} onClick={e => this.onFlipLabel(e, true, true)} text-color={errored ? "red" : ""}>
                    {label}{(this.props.required && this.props["show-required"]) && <span text-color="red">&nbsp;*</span>}
                </label>
                {(this.props.maxLength && isActive) && (
                    <span className="character-counter">{value.length} / {this.props.maxLength}</span>
                )}
                <div className="invalid-feedback">{errored ? meta.error : ""}</div>
            </div>
        );

        if (colspan) {
            return (
                <div className={colspan}>
                    {mainSection}
                </div>
            );
        }

        return mainSection;
    }
}