import { action, observable, remove,set} from "mobx";


class AnalyticsState {
    @observable clickedLinkData = {};
    @observable selectedLinkMetrics = []; 

    @action setClickedLinkData = (data) => {
        const _data = Object.assign({}, data);
        remove(this.clickedLinkData, data);
        set(this.clickedLinkData, _data);
    }

    @action setSelectedLinkMetrics = (metrics) => {
        this.selectedLinkMetrics.replace(metrics);
    }
}

const analyticsStore = new AnalyticsState();
export default analyticsStore;