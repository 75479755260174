import React, { Component } from "react";
import Collapsible from "~/hoc/Collapsible";
import SelectBox from "react-select";
import except from "lodash/omit";
import classNames from "~/lib/classNames";
import Alert from "~/services/ui/alerts";
import Tooltip from "~/components/material/Tooltip";

class AsyncSelect extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: true, data: [] };
    }

    onReceiveData(error, data) {
        if(error) {
            console.log(error);
            Alert.showGenericError();
        } else {
            this.setState({ loading: false, data });
        }
    }

    componentDidMount() {
        if(this.state.loading) {
            this.props.loadOptions(null, (e,d) => this.onReceiveData(e,d));
        }
    }

    render() {
        return (
            <SelectBox
                {...this.props}
                placeholder={this.state.loading ? "Loading..." : this.props.placeholder}
                options={this.state.data} />
        );
    }
}

@Collapsible
export default class SelectClass extends Component {
    constructor(props) {
        super(props);
        let state = {
            value: "",
            overrides: {}
        };

        if(props.value) {
            if(props.value.value) {
                state.value = props.value;
            } else {
                state.value = { value: props.value };
            }

            if(props.hasOwnProperty("value") && props.input) {
                props.input.onChange(props.value);
            }
        } else if(props.initialValue) {
            if(props.initialValue.value) {
                state.value = props.initialValue;
            } else {
                state.value = { value: props.initialValue };
            }

            if(props.hasOwnProperty("initialValue") && props.input) {
                props.input.onChange(props.initialValue);
            }
        } else if(props.input) {
            if(props.input.value.value) {
                state.value = props.input.value;
            } else {
                state.value = { value: props.input.value };
            }
        }

        if(Array.isArray(state.value) && !props.multiple) {
            state.value = state.value[0];
        }
        
        this.state = state;

        if(props.innerRef) {
            props.innerRef(this);
        }
    }

    onChange = option => {
        let val = option && option.value;
        
        this.setState({ value: option }, () => {
            if(option && option.onSelect) {
                let { onSelect, ...opt } = option;
                let res = onSelect(opt, () => this.onChange(opt));

                if(res && res.then) {
                    res.then(() => this.onChange(opt));
                }
            } else if(this.props.genericChangeHandler) {
                return this.props.genericChangeHandler(option, overrides => this.setState({ overrides }));
            } else if(this.props.onChange) {
                return this.props.onChange(option);
            } else if(this.props.input) {
                return this.props.input.onChange(val);
            }
        });
    }

    static getDerivedStateFromProps(nextProps, oldState) {
        if(nextProps.input) {
            let value = nextProps.input.value;
            
            if(value != oldState.value) {
                if(!!oldState.value && value !== "") {
                    if(value && Array.isArray(value) && !nextProps.multiple) {
                        return { value: value[0] };
                    } else if(value && value.value) {
                        if(value.value !== oldState.value.value) {
                            return { value };
                        }
                    } else if(value !== oldState.value.value) {
                        return { value: { value } };
                    }
                } else if(!!oldState.value && value === "") {
                    return { value: null };
                }
            }
        } else if(nextProps.value != oldState.value) {
            if(!!oldState.value && nextProps.value !== "") {
                if(nextProps.value && nextProps.value.value) {
                    if(nextProps.value.value !== oldState.value.value) {
                        return { value: nextProps.value };
                    }
                } else if(nextProps.value !== oldState.value.value) {
                    return { value: { value: nextProps.value } };
                }
            } else if(!!oldState.value && nextProps.value === "") {
                return { value: null };
            }
        }

        return null;
    }

    render() {
        let { multiple, data = [], label = null, colspan = undefined, className = undefined, highlightedOptions = [], overrides = {}, sidePiece, tooltip, tooltipPosition = "bottom" } = this.props;
        
        let props = {
            name: this.props.name || this.props.id || null,
            value: this.state.value,
            noResultsText: "Error: None Available",
            placeholder: this.props.placeholder || "--",
            onBlurResetsInput: false,
            onSelectResetsInput: false,
            multi: multiple
        };

        if(this.props.hasOwnProperty("value")) {
            props.value = this.props.value;
        }

        if(this.props.input) {
            props.value = this.props.input.value || this.state.value;
            props.placeholder = this.props.input.placeholder || props.placeholder;

            if(Array.isArray(props.value) && !this.props.multiple) {
                props.value = props.value[0];
            }
        }

        if(!this.props.async) {
            props.options = data;

            if(highlightedOptions.length) {
                props.options = props.options.map(x => {
                    if(highlightedOptions.includes(x.value)) {
                        x.className = [x.className, "select-option-highlighted"].join(" ");
                    }
                    
                    return x;
                });
            }
        }

        if(this.props.disabled) {
            props.disabled = true;
            
            if(this.props.disabledLabel) {
                props.value = { label: this.props.disabledLabel };
            }
        }

        props = {
            ...props,
            ...this.state.overrides,
            ...overrides
        };

        if(this.state.overrides.label) {
            label = this.state.overrides.label;
        }

        const ComponentClass = (this.props.async ? AsyncSelect : SelectBox);
        
        let main = (
            <div className={classNames("md-form Select-wrapper", className, sidePiece && "input-group pl-0")}>
                {label && (
                    <label>
                        {label}{(this.props.required && this.props["show-required"]) && (<span text-color="red">&nbsp;*</span>)}
                    </label>
                )}
                <ComponentClass ref={ref => this.select = ref}
                    {...props}
                    {...props.input}
                    {...except(this.props, "expanded", "disabled", "initialValue", "data", "async", "options", "value", "className", "input", "onClick", "innerRef", "overrides", "placeholder")}
                    clearable={false}
                    onChange={val => this.onChange(val)} />
                {sidePiece && (
                    <div className="input-group-append align-items-start">{sidePiece}</div>
                )}
            </div>
        );

        if(tooltip) {
            main = (
                <Tooltip placement={tooltipPosition} title={tooltip}>
                    {main}
                </Tooltip>
            );
        }

        if(colspan) {
            return (
                <div className={colspan}>{main}</div>
            );
        }
        
        return main;
    }
}