import React from "react";
import { observable, action, autorun } from "mobx";
import { showConfirmationModal } from "~/components/material/Modal/ConfirmationModal";
import { observer } from "mobx-react";
import NotificationsService from "~/services/api/notifications";

import TextField from "@material-ui/core/TextField";

const ModalBody = observer(class ModalBody extends React.Component {
    @observable target = "everyone";
    @observable targets = "";
    @observable contents = "Hello World!";
    @observable details = "This is a test notification";
    @observable priority = 0;
    @observable pinned = 0;
    @observable type = "custom:generic";
    @observable icon = "check";
    @observable metadata = "{}";

    @action onChangeTarget = e => this.target = e.target.value;
    @action onChangeTargets = e => this.targets = e.target.value;
    @action onChangeContents = e => this.contents = e.target.value;
    @action onChangeDetails = e => this.details = e.target.value;
    @action onChangePriority = e => this.priority = e.target.value;
    @action onChangePinned = e => this.pinned = e.target.value;
    @action onChangeType = e => this.type = e.target.value;
    @action onChangeIcon = e => this.icon = e.target.value;
    @action onChangeMetadata = e => this.metadata = e.target.value;

    constructor(props) {
        super(props);

        autorun(() => {
            props.setResult({
                target: this.target,
                targets: this.targets,
                contents: this.contents,
                details: this.details,
                priority: this.priority,
                pinned: this.pinned,
                type: this.type,
                icon: this.icon,
                metadata: this.metadata
            });
        });
    }

    render() {
        return (
            <React.Fragment>
                <TextField className="w-100 mb-2" value={this.type} label="Notification Type" onChange={this.onChangeType} />
                <TextField className="w-100 mb-2" value={this.icon} label="Icon (only for custom:generic type)" onChange={this.onChangeIcon} />
                <TextField className="w-100 mb-2" value={this.target} label="Target (everyone, user, companyAdmins, portalAdmins)" onChange={this.onChangeTarget} />
                <TextField className="w-100 mb-2" value={this.targets} label="Specific Targets (id for user, companyAdmins)" onChange={this.onChangeTargets} />
                <TextField className="w-100 mb-2" value={this.contents} label="Contents" onChange={this.onChangeContents} />
                <TextField className="w-100 mb-2" value={this.details} label="Details" onChange={this.onChangeDetails} />
                <TextField className="w-100 mb-2" value={this.priority} label="Priority (-1, 0, 1, 2)" onChange={this.onChangePriority} />
                <TextField className="w-100 mb-2" value={this.pinned} label="Pinned (0, 1)" onChange={this.onChangePinned} />
                <TextField className="w-100 mb-2" value={this.metadata} label="Metadata" onChange={this.onChangeMetadata} />
            </React.Fragment>
        );
    }
});

export default async() => {
    let data = null;

    let ok = await showConfirmationModal({
        title: "Debug Notification Sender",
        showTitle: true,
        className: "modal-lg",
        okayLabel: "Create",
        children: props => (
            <ModalBody setResult={x => data = x} />
        )
    });

    if(ok) {
        NotificationsService.createNotification(data);
    }
};