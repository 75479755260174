import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "~/components/material/Tooltip";
import except from "lodash/omit";

export default class FobButton extends React.Component {
    getTooltipPosition() {
        switch(this.props.affixed) {
            default: return "bottom";

            case "top-right":
            case "bottom-right": return "left";

            case "top-left":
            case "bottom-left": return "right";
        }
    }

    render() {
        let props = {
            type: "button",
            affixed: "bottom-right",
            className: ["btn-floating", this.props.className].join(" "),
            color: "green",
            "text-color": "white",
            ...except(this.props, "className", "title", "offset")
        };

        if(this.props.offset) {
            props.style = {
                ...props.style,
                ...this.props.offset
            };
        }

        if(this.props.disabled) {
            props.className += " disabled";
            props["color-shade"] = 3;
        }

        if(this.props.title) {
            let tooltipProps = {
                title: this.props.title,
                placement: this.getTooltipPosition()
            };

            return (
                <Tooltip {...tooltipProps}>
                    <button {...props}>
                        <FontAwesomeIcon icon={this.props.icon || "save"} />
                    </button>
                </Tooltip>
            );
        } else {
            return (
                <button {...props}>
                    <FontAwesomeIcon icon={this.props.icon || "save"} />
                </button>
            );
        }
    }
}