import React from "react";
import { Header } from "~/components/material/Dropdown";
import Permissions from "~/services/PermissionsDictionary";

export default () => {
    let a = Permissions.hasGeneralPermission("admin.admin");
    let b = Permissions.getPermissionLevel(-1, "user");
    let c = Permissions.getPermissionLevel(-1, "accounts");

    if(a || b || c) {
        return (
            <Header>System Admin</Header>
        );
    } else return null;
};

export const PermissionModulesBasedHeader = ({
    modules, children
}) => {
    if(modules.some(x => Permissions.getPermissionLevel(-1, x)))
        return <Header>{children}</Header>;

    return null;
};

export const PermissionBasedHeader = ({
    module, children
}) => {
    if(Permissions.getPermissionLevel(-1, module))
        return <Header>{children}</Header>;

    return null;
};