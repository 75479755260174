import {AUTHENTICATED, UNAUTHENTICATED, AUTHENTICATION_ERROR, SET_TOKEN} from "~/actions/definitions";
import { setToken, deleteToken } from "~/services/auth";
import Permissions from "~/services/PermissionsDictionary";

export const tokenReducer = (state = null, {type, payload}) => {
    if(type === AUTHENTICATED || type === SET_TOKEN) {
        setToken(payload.token).then(() => console.log("Authenticated!")); //Login via socket too

        if(payload.permissions) {
            Permissions.update(payload.permissions || []);
        }

        return payload.token;
    }

    if(type === UNAUTHENTICATED) {
        deleteToken();
        return null;
    }

    return state;
};

export const errorReducer = (state = null, {type, payload: error}) => {
    if(type === AUTHENTICATION_ERROR) {
        if(!error) return "Unknown error";

        if(typeof error === "string")
            return error;

        if(error instanceof Error)
            return error.message;

        if(Array.isArray(error)) {
            return `Multiple ${error.type} errors`;
        }
    }

    return state;
}; 