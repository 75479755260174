export const minLength = (length) => (value) => 
    value && value.toString().trim().length > length - 1 ? null : `Must be at least ${length} characters`;

export const maxLength = (length) => (value) => 
    value ? value.toString().trim().length > length ?  `Must be at most ${length} characters` : null : null;

export const required = (value) =>
    value && value.toString().trim().length > 0 ? null : "This field is required";

export const phoneNumber = (val) =>
    val && /\d{10}/.test(val.toString().trim()) ? null : "Invalid phone number";

export const zipCode = (val) => 
    val && /\d{5}/.test(val.toString().trim()) ? null : "Invalid zipcode";

export const emailAddress = (val) =>
    val && val.toString().trim().length && regex.email(val) ? null : "Invalid email address";

const regex = {
    email: x => /^.+@(?:[^.@\s]+(?:\.[^.@\s]+)*)+$/i.test(x.toString().trim())
};

export const emailAddressNotRequired = val => 
    val ? (regex.email(val) ? null : "Invalid email address") : null;

export const zipCodeNotRequired = (val) => 
    val ? (/\d{5}/.test(val) ? null : "Invalid zipcode") : null;
    
export const phoneNumberNotRequired = (val) =>
    val ? (/\d{10}/.test(val) ? null : "Invalid phone number") : null;

export const objectKeysToLowerCase = function (origObj, recursive = false) {
    return Object.keys(origObj).reduce(function (newObj, key) {
        let val = origObj[key];
        let newVal = (typeof val === "object" && recursive) ? objectKeysToLowerCase(val) : val;
        newObj[key.toLowerCase()] = newVal;
        return newObj;
    }, {});
};

export const complexPassword = val => {
    let password = (val && val.length ? val : "").replace(/\s/g, "");
    if(password.length < 8) return "Must be at least 8 characters long";
    if(!/[a-z]/g.test(password)) return "Must have at least one lowercase character";
    if(!/[A-Z]/g.test(password)) return "Must have at least one uppercase character";
    if(!/[0-9]/g.test(password)) return "Must have at least one number";
    if(!/[^a-zA-Z0-9]/g.test(password)) return "Must have at least one special character";

    return null;
};

export const phoneNumberMask = (value) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;
    if (value.length) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    } else { return value; }
};