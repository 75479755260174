export default {
    "admin": [ "admin" ],
    "company": [ "assigned", "create", "read", "update", "delete" ],
    "division": [ "create", "read", "update", "delete" ],
    "user": [ "create", "read", "update", "delete" ],
    "import": [ "create", "read", "update", "delete", "trigger" ],
    "accounts": [ "create", "read", "update", "delete" ],
    "balancesheet": [ "read" ],
    "incomestatement": [ "read" ],
    "budget": [ "create", "read", "update", "delete", "unfinalize", "unlock" ],
    "forecast": [ "create", "read", "update", "delete", "unfinalize", "unlock" ],
    "cashFlow": [ "create", "read", "update", "delete", "unfinalize", "unlock" ],
    "notes": [ "create", "read", "update", "delete" ],
    "analytics": [ "read" ],
    "systemReports": [ "read" ],
    "tools": [ "setupImportTool", "modifyScheduling", "generalLedger" ],
    "tweaks": [ "debugMode", "noLogout" ],
    "preferences": [ "disableNotifications", "showQuickLinks" ]
};

export const hideFromModal = [
    "preferences.showQuickLinks"
];

export const generalPermissions = {
    "admin": [ "admin" ],
    "preferences": [ "showQuickLinks" ]
};

let fakes = {
    "create": [],
    "read": [],
    "update": [],
    "delete": []
};

export const defaults = {
    "admin": {
        admin: []
    },
    "company": {
        ...fakes,
        assigned: [],
        create: false
    },
    "division": { ...fakes },
    "user": { "create": false, "read": false, "update": [], "delete": false },
    "import": { "create": [], "read": [], "update": [], "delete": [], "trigger": [] },
    "accounts": { "create": [], "read": [], "update": [], "delete": [] },
    "balancesheet": { read: [] },
    "incomestatement": { read: [] },
    "budget": { ...fakes, "unfinalize": [] },
    "forecast": { ...fakes, "unfinalize": [] },
    "cashFlow": { ...fakes, "unfinalize": [] },
    "notes": { ...fakes },
    "analytics": { read: [] },
    "systemReports": { read: [] },
    "tools": { setupImportTool: false, modifyScheduling: [], generalLedger: [] },
    "tweaks": { debugMode: false, noLogout: false },
    "preferences": { disableNotifications: [], showQuickLinks: false }
};

export const labels = {
    "admin": {
        "admin": { name: "Company Admin", description: "Allow unfettered access to CFOfxn and its features (overrides all other permission nodes)" }
    },
    "company": {
        "assigned": { name: "Assigned", description: "Should never show in UI" },
        "create": { name: "Create", description: "Create a new company" },
        "read":   { name: "View", description: "See and view the details of a company" },
        "update": { name: "Edit ", description: "Make changes to an existing company" },
        "delete": { name: "Remove", description: "Remove a company" }
    },
    "division": {
        "create": { name: "Create", description: "Create a new division" },
        "read":   { name: "View", description: "See and view the details of a division" },
        "update": { name: "Edit ", description: "Make changes to an existing division" },
        "delete": { name: "Remove", description: "Remove a division" }
    },
    "user": {
        "create": { name: "Create", description: "Create a new user" },
        "read":   { name: "View", description: "See and view the details of a user" },
        "update": { name: "Edit", description: "Make changes to an existing user" },
        "delete": { name: "Deactivate", description: "Deactivate a user's account" }
    },
    "import": {
        "create": { name: "Create", description: "Create new imports from scratch or from a file" },
        "read":   { name: "View", description: "Run reports against imported data" },
        "update": { name: "Edit", description: "Make changes to imported data" },
        "delete": { name: "Remove", description: "Remove imported data" },
        "trigger":{ name: "Trigger Integrations", description: "Trigger integrations to pull data from external sources" }
    },
    "accounts": {
        "create": { name: "Create", description: "Create new account mappings from scratch or from a file" },
        "read":   { name: "View", description: "See the list of existing account mappings" },
        "update": { name: "Edit", description: "Make changes to existing account mappings" },
        "delete": { name: "Remove", description: "Remove entire sets of account mappings" }
    },
    "balancesheet": {
        "read": { name: "View", description: "Generate balance sheet reports from imported data" }
    },
    "incomestatement": {
        "read": { name: "View", description: "Generate income statement (profit and loss) reports from imported data" }
    },
    "budget": {
        "create":     { name: "Create", description: "Create a new budget from scratch or from existing CFOfxn data" },
        "read":       { name: "View", description: "Run reports against and see the calculations associated with existing budgets" },
        "update":     { name: "Edit", description: "Make changes to existing budgets or their calculations" },
        "delete":     { name: "Remove", description: "Remove entire sets of budgets" },
        "unfinalize": { name: "Reset Status", description: "Reset the status of a budget from Final back to Draft" },
        "unlock":     { name: "Unlock", description: "Unlock budget as a non-company admin" }
    },
    "forecast": {
        "create":     { name: "Create", description: "Create a new forecast from scratch or from existing CFOfxn data" },
        "read":       { name: "View", description: "Run reports against and see the calculations associated with existing forecasts" },
        "update":     { name: "Edit", description: "Make changes to existing forecasts or their calculations" },
        "delete":     { name: "Remove", description: "Remove entire sets of forecasts" },
        "unfinalize": { name: "Reset Status", description: "Reset the status of a forecast from Final back to Draft" },
        "unlock":     { name: "Unlock", description: "Unlock forecast as a non-company admin" }
    },
    "cashFlow": {
        "create":     { name: "Create", description: "Create a new cash flow statement from scratch or from existing CFOfxn data" },
        "read":       { name: "View", description: "Run reports against and see the calculations associated with existing cash flow statements" },
        "update":     { name: "Edit", description: "Make changes to existing cash flow statements or their calculations" },
        "delete":     { name: "Remove", description: "Remove entire sets of cash flow statements" },
        "unfinalize": { name: "Reset Status", description: "Reset the status of a cash flow statement from Final back to Draft" },
        "unlock":     { name: "Unlock", description: "Unlock cashflow as a non-company admin" }
    },
    "notes": {
        "create": { name: "Create", description: "Create a new note" },
        "read":   { name: "View", description: "See a list of notes associated with a company or division" },
        "update": { name: "Edit", description: "Modify an existing note" },
        "delete": { name: "Remove", description: "Delete a note" }
    },
    "analytics": {
        "read": { name: "View", description: "Run metric reports against imported data and projections" }
    },
    "systemReports": {
        "read": { name: "View", description: "Execute a series of system and financial reports" }
    },
    "tools": {
        "setupImportTool": { name: "Deploy Integrations", description: "Setup and configure QuickBooks Integration Services" },
        "modifyScheduling": { name: "Modify Import Schedules", description: "Setup and configure automatic import schedules" },
        "generalLedger": { name: "View General Ledger", description: "Generate requests for a General Ledger report and export the results" }
    },
    "preferences": {
        "disableNotifications": { name: "Disable Import Notifications", description: "Disables import notifications for the specified user" }
    }
};

export const generalLabels = {
    "admin": {
        "admin": { name: "Portal Admin", description: "Allow unfettered access to CFOfxn and its features (overrides all other permission nodes)" }
    },
    "preferences": {
        "showQuickLinks": { name: "Show Quick Links", description: "Show quick links on the dashboard" }
    }
};
