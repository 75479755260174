import { API } from "./index";
import { get, post } from "../net";

class AuthService {
    async login(alias, email, password) {
        let request = post({
            endpoint: "security/login",
            data: {alias, email, password},
            auth: false,
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.error(request, ex);
        }
    }

    async confirmEmail(token) {
        let request = get({
            endpoint: `security/confirmEmail?token=${encodeURIComponent(atob(token))}`,
            auth: false,
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.error(request, ex);
        }
    }
}

export default new AuthService();