const exports = {};

exports["info:generic"] = notification => ({
    icon: "help_outline"
});

exports["error:generic"] = notification => ({
    icon: "error_outline"
});

exports["custom:generic"] = () => ({});

export default exports;