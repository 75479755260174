import React, { Component } from "react";
import $ from "~/lib/elemutil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import except from "except";
import classNames from "~/lib/classNames";

export default class TextInput extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        if(props.innerRef) {
            props.innerRef(this);
        }
    }

    onFlipLabel(event, add) {
        let input = this.input;
        if(input) {
            if(add) {
                $(event.target).siblings("label,i,svg", true).forEach(e => e.addClass("active"));
                input.focus();
            } else {
                let {value} = this.input;
                if(value && value.length) {
                    $(event.target).siblings("i,svg", true).forEach(e => e.removeClass("active"));
                } else {
                    $(event.target).siblings("label,i,svg", true).forEach(e => e.removeClass("active"));
                }
            }
            
            this.updateSize();
        }
    }

    setLabel(label) {
        this.setState({ label });
    }

    updateSize() {
        let input = this.input;

        if(input) {
            if(input.value && input.value.length) {
                input.style.cssText = "height:auto; padding:0";
                input.style.cssText = "height:" + Math.min((input.scrollHeight - 52), 250) + "px";
            } else {
                input.style.cssText = null;
            }
        }
    }

    componentDidMount() {
        if(this.props.focused) {
            setTimeout(() => {
                this.input.focus();
                this.input.setSelectionRange(this.input.value.length, this.input.value.length);
            }, 1);
        }
    }

    setupTextarea(ref) {
        this.input = ref;
        this.updateSize();
    }

    componentDidUpdate(props) {
        if(props.value !== this.props.value) {
            this.updateSize();
        }
    }

    render() {
        let name = this.props.id;
        let {label, meta, colspan, focused, input = {}, icon, value = "", className, rows, "show-required": showRequired, maxLength, ...otherProps} = this.props;
        let errored = meta ? (meta.dirty && meta.invalid && meta.error) : false;

        if(this.state.label) {
            label = this.state.label;
        }

        let val = input && input.value ? input.value : value;
        let isActive = val && val.length ? "active" : undefined;

        let mainSection = (
            <div className="md-form">
                {icon && (
                    <FontAwesomeIcon icon={icon} className={["prefix", isActive].join(" ")} text-color={errored ? "red" : ""} />
                )}
                <textarea type="text" value={val}
                    className={classNames("form-control md-textarea", className, errored && "invalid")}
                    maxLength={maxLength}
                    {...input}
                    {...except(otherProps, "genericChangeHandler", "innerRef")}
                    onBlur={e => this.onFlipLabel(e, false)}
                    onFocus={e => this.onFlipLabel(e, true)}
                    onInput={e => this.onFlipLabel(e, true)}
                    rows={rows || 3}
                    autoFocus={focused}
                    ref={ref => this.setupTextarea(ref)} />
                <label className={isActive} htmlFor={name} onClick={e => this.onFlipLabel(e, true)} text-color={errored ? "red" : ""}>
                    {label}{(this.props.required && showRequired) && <span text-color="red">&nbsp;*</span>}
                </label>
                {(maxLength && isActive) && (
                    <span className="character-counter">{val.length} / {maxLength}</span>
                )}
                <div className="invalid-feedback">{errored ? meta.error : ""}</div>
            </div>
        );

        if(colspan) {
            return (
                <div className={colspan}>
                    {mainSection}
                </div>
            );
        }

        return mainSection;
    }
}