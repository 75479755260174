import React, { Component } from "react";

export default class Accordion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openTab: -1
        };
    }
    
    update(i) {
        if(this.state.openTab === i) {
            this.setState({ openTab: -1 });
        } else {
            this.setState({ openTab: i });
        }
    }

    render() {
        return (
            <div className={["accordion", this.props.className].join(" ")}>
                {this.props.children.map((child,i) => {
                    return React.cloneElement(child, {
                        key: i,
                        expanded: this.state.openTab === i,
                        update: () => this.update(i)
                    });
                })}
            </div>
        );
    }
}

Accordion.Section = class AccordionSection extends Component {
    onClick(e) {
        e.preventDefault();
        this.props.update();
    }

    render() {
        let { expanded } = this.props;

        let className = "collapse";
        if(expanded) className += " show";

        return (
            <div className="card">
                <div className="card-header" role="tab">
                    <a className="collapsed" href="#collapseTwo" onClick={e => this.onClick(e)}>
                        <h5 className="mb-0">
                            {this.props.label}
                        </h5>
                    </a>
                </div>
                <div className={className} role="tabpanel">
                    <div className="card-body">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
};