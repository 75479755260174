import { API } from "./index";
import { get, BASE_URL } from "../net";
import axios from "axios";
import qs from "querystring";

class IntegrationService {
    async getGeneralLedger({QBOendpoint, parentCompanyId, divisionId, classId, startDate, endDate}) {
        let request = get({
            endpoint: `${QBOendpoint}?${qs.stringify({
                parentCompanyId,
                companyId: divisionId,
                classId,
                startDate,
                endDate
            })}`,
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async generateIntegrationZip(data) {
        let request = axios.create({ //Gotta do this one by hand since it's so complicated.
            baseURL: BASE_URL,
            timeout: 0,
            validateStatus: false,
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json"
            }
        }).post("integration/qb/generateIntegrationZip", data, {
            onUploadProgress: this.watcher,
            responseType: "blob"
        });

        console.log(data);

        try {
            let res = await request;
            return {
                error: null,
                payload: res.data
            };
        } catch(ex) {
            return {
                error: ex,
                payload: null
            };
        }
    }

    async getUnmappedImportsForCompany(companyId) {
        let request = get({
            endpoint: `integration/qb/getUnmappedImports?companyId=${companyId}`,
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    beginQBOnlineAuth(opts) {
        window.authorizingQuickbooks = true;
        window.location.href = `${BASE_URL}/integration/qb/qboOauthBegin?params=${encodeURIComponent(JSON.stringify(opts))}`;
    }
}

const inst = new IntegrationService();
export default inst;