import { AUTHENTICATED, UNAUTHENTICATED, SET_PAGE_TITLE } from "~/actions/definitions";

export const hasAssignedCompaniesReducer = (state = null, {type, payload}) => {
    if(type === AUTHENTICATED)
        return payload.hasAssignedCompanies || false;

    if(type === UNAUTHENTICATED)
        return false;

    return state;
};

export default (state = "", { type, payload }) => {
    if(type === SET_PAGE_TITLE) return payload;

    return state;
};