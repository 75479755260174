import { Component } from "react";
import { connect } from "react-redux";
import Alert from "~/services/ui/alerts";

const stateMap = ({ auth: { token } }) => ({
    authenticated: token && token.length
});

class AuthenticationAlert extends Component {
    componentDidMount() {
        if(this.props.authenticated) {
            setTimeout(() => Alert.showSubBanner("You are already logged in"), 1000);
        }
    }

    render() {
        return null;
    }
}

export default connect(stateMap)(AuthenticationAlert);