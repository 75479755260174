import Alert from "react-s-alert";
const GENERIC_ERROR = "Unexpected error occurred. Please contact cfosupport@finlogicllc.com for support.";

const async = {
    showSubBanner: (message, style, overrides = {}) => new Promise((resolve) => {
        showSubBanner(message, style, {
            ...overrides,
            onClose: () => setTimeout(resolve, 1)
        });
    }),

    showMessage: (message, position, overrides = {}) => new Promise((resolve) => showMessage(message, resolve, position, overrides)),
    showWarning: (message, position, overrides = {}) => new Promise((resolve) => showWarning(message, resolve, position, overrides)),
    showError: (message, position, overrides = {}) => new Promise((resolve) => showError(message, resolve, position, overrides)),
    showGenericError: (position, overrides = {}) => new Promise((resolve) => showGenericError(resolve, position, overrides))
};

const getMessage = (message) => {
    if(message.message && message.message.length)
        return getMessage(message.message);

    return message.toString();
};

export const showSubBanner = (message, style = "warning", overrides = {}) => {
    Alert[style](getMessage(message), {
        position: "bottom",
        html: false,
        effect: "flip",
        timeout: "none",
        ...overrides
    });
};

export const showMessage = (message, cb, position = "top", overrides = {}) => {
    Alert.success(getMessage(message), {
        position,
        timeout: 4000,
        html: false,
        effect: "slide",
        onClose: () => setTimeout(cb, 1),
        ...overrides
    });
};

export const showError = (message, cb, position = "top", overrides = {}) => {
    Alert.error(getMessage(message), {
        position,
        timeout: 4000,
        html: false,
        effect: "slide",
        onClose: () => setTimeout(cb, 1),
        ...overrides
    });
};

export const showWarning = (message, cb, position = "top", overrides = {}) => {
    Alert.warning(getMessage(message), {
        position,
        timeout: 4000,
        html: false,
        effect: "slide",
        onClose: () => setTimeout(cb, 1),
        ...overrides
    });
};

export const showGenericError = (cb, position = "top", overrides = {}) => {
    Alert.error(GENERIC_ERROR, {
        position,
        timeout: 4000,
        html: false,
        effect: "slide",
        onClose: () => setTimeout(cb, 1),
        ...overrides
    });
};

export default {
    async,
    showSubBanner,
    showMessage,
    showError,
    showWarning,
    showGenericError
};