import React from "react";
import { Select } from "~/components/material";

//https://gist.github.com/mshafrir/2646763
export const StateAbbreviates = [
    "AL", "AK", "AZ", "AR", "CA",
    "CO", "CT", "DE", "DC", "FL",
    "GA", "HI", "ID", "IL", "IN",
    "IA", "KS", "KY", "LA", "ME",
    "MD", "MA", "MI", "MN", "MS",
    "MO", "MT", "NE", "NV", "NH",
    "NJ", "NM", "NY", "NC", "ND", "OH",
    "OK", "OR", "PA", "RI", "SC",
    "SD", "TN", "TX", "UT", "VT",
    "VA", "WA", "WV", "WI", "WY"
];

export const StateNames = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "District Of Columbia",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming"
];

export const STATES = Array(StateAbbreviates.length).fill(0).map((_,i) => ({
    label: StateNames[i],
    value: StateAbbreviates[i]
})).sort((a,b) => a.label > b.label ? 1 : 0);

export default props => (
    <Select
        {...props}
        data={STATES} />
);