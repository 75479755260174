import React, { Component } from "react";
import except from "except";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import UselessLink from "./UselessLink";

import Collapsible from "~/hoc/Collapsible";

class Dropdown extends Component {
    onTrigger(e, parent = false) {
        if(parent) e.preventDefault();
        this.props.toggleExpanded();
    }

    componentDidMount() {
        this.trigger.addEventListener("click", (e) => this.onTrigger(e, true));
    }

    render() {
        let { trigger, "show-caret": showCaret, children, className, expanded, dropdownWidth } = this.props;

        let triggerBtn;
        if(trigger) {
            let caret = [
                "\u00A0", //Blank space
                //Needs key prop because children is iterable
                <FontAwesomeIcon key="____" icon="caret-down" className={"flipRotate" + (expanded ? " show" : "")} />
            ];

            let refHandler = ref => this.trigger = ref;

            if(typeof trigger !== "string") {
                triggerBtn = React.cloneElement(trigger, {
                    ref: refHandler,
                    children: [
                        trigger.props.children,
                        showCaret ? caret : undefined
                    ]
                });
            } else triggerBtn = (
                <button type="button" className="btn waves-effect" ref={refHandler}>
                    {trigger} {showCaret && (caret)}
                </button>
            );
        }

        let dropdownStyles = [
            "dropdown-menu dropdown-primary dropdown-menu-right",
            expanded ? "show" : undefined,
            className
        ].join(" ");

        let childrenWithProps = React.Children.map(children, child => {
            if(!React.isValidElement(child)) return null;
            return React.cloneElement(child, { CollapseDropdown: e => this.onTrigger(e) });
        });
        
        return (
            <div className={["dropdown", expanded ? "is-showing" : undefined, this.props.wrapperClass].join(" ")}>
                {trigger && (triggerBtn)}

                <div ref={ref => this.actualDropdown = ref} className={dropdownStyles} style={dropdownWidth ? { width: dropdownWidth } : undefined}>
                    {childrenWithProps}
                </div>
            </div>
        );
    }
}

const DropdownInst = Collapsible(Dropdown);
export default DropdownInst;

export class Item extends Component {
    onClick(e) {
        this.props.CollapseDropdown(e);

        if(this.props.onClick)
            return this.props.onClick(e);
    }

    render() {
        let { type, destination = "#/", className, children, disabled } = this.props;

        let ObjectType = Link;
        if(type) ObjectType = type;
        if(disabled) ObjectType = UselessLink;

        let style = {};

        if(disabled) {
            style = {
                cursor: "not-allowed"
            };
        }

        return <ObjectType to={destination} onClick={disabled ? () => undefined : e => this.onClick(e)} className={["dropdown-item", className].join(" ")} {...except(this.props, "CollapseDropdown", "onClick")} style={style}>{children}</ObjectType>;
    }
}

export class Separator extends Component {
    render() {
        return <div className="dropdown-divider" />;
    }
}

export class Header extends Component {
    render() {
        let { children } = this.props;
        return <h6 className="dropdown-header" {...except(this.props, "CollapseDropdown")}>{children}</h6>;
    }
}

DropdownInst.Item = Item;
DropdownInst.Separator = Separator;
DropdownInst.Header = Header;