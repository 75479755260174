import React from "react";

export default class Background extends React.Component {
    render() {
        let { image = null, color = null } = this.props;
        let style = {
            position: "fixed",
            width: "100%",
            height: "100%",
            top: "0",
            left: "0"
        };

        if(image) {
            let imageStyle = {
                width: "100%",
                height: "100%",
                background: `url(${image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat"
            };
            return (
                <div className="view" style={style}>
                    <div style={imageStyle} />
                </div>
            );
        } else if(color) {
            style = {
                ...style,
                backgroundColor: color
            };
            return <div className="background" style={style} />;
        } else return null;
    }
}