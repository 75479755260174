import { FETCH_ACCOUNT, ACCOUNT_ERROR, AUTHENTICATED, UNAUTHENTICATED } from "~/actions/definitions";
const defaultState = {
    name: null,
    email: null,
    error: null
};

export default (state = defaultState, {type, payload}) => {
    if(type === FETCH_ACCOUNT) {
        let { FirstName, LastName, Email, ...rest } = payload.info;               
        return Object.assign(Object.assign({}, defaultState), {
            name: `${FirstName} ${LastName}`,
            email: Email,
            FirstName,
            LastName,
            ...rest
        });
    }

    if(type === ACCOUNT_ERROR)
        return Object.assign(Object.assign({}, defaultState), { error: payload });

    if(type === UNAUTHENTICATED)
        return Object.assign({}, defaultState);

    if(type === AUTHENTICATED) {
        return Object.assign(Object.assign({}, defaultState), {
            name: `${payload.firstname} ${payload.lastname}`,
            firstname: payload.firstname,
            lastname: payload.lastname,
            email: payload.email,
            AcceptTerms: payload.acceptTerms,
            PortalUserId: payload.portalUserId,
            termsAndConditionsId: payload.termsAndConditionsId
        });
    }

    return state;
};