/**
 * Condense a list of class names and conditional class names into a single className property value
 */
export const classNames = (...classes) => {
    let names = [];

    for(let name of classes) {
        if(!name) continue;

        if(typeof name === "string" && name.length) {
            names.push(name);
        } else if(Array.isArray(name)) {
            names.push(classNames(name));
        } else if(typeof name === "object") {
            let clazzes = Object.keys(name);

            for(let clazz of clazzes) {
                if(clazzes[clazz])
                    names.push(clazz);
            }
        }
    }

    return names.join(" ");
};

export default classNames;