import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Card } from "~/components/material";
import { connect } from "react-redux";
import { PortalUser as UserService } from "~/services/api";
import AuthenticatedAlert from "~/components/alerts/AuthenticationAlert";
import { setPageTitle } from "~/actions/ui";
import Background from "~/components/Background";
import Alert from "~/services/ui/alerts";

import { getHashParameters } from "~/lib/subdomain";

import AbstractForm from "~/components/generic/GenericForm";
import LoginFields from "./fields/LoginForm.fields";

class Login extends Component {
    constructor(props) {
        super(props);
        props.setPageTitle(null);
    }

    async onFormSubmit({alias, email, password}) {
        try {
            let { error, req } = await UserService.login(alias, email, password);
            if(req.status === 400) return Alert.showError("Invalid username or password");
            if(error) return Alert.showError(error);

            let hash = getHashParameters(window.location.hash);

            if(hash.redirect) {
                this.props.history.push(decodeURIComponent(hash.redirect));
            } else {
                this.props.history.push("/home");
            }
        } catch(ex) {
            console.log(ex);
            Alert.showGenericError();
        }
    }

    render() {
        return (
            <React.Fragment>
                <Background image="/res/login-cover-lg.jpg" />
                <AuthenticatedAlert />

                <Card className="mt-3 animated fadeIn login-card" title="Login" title-icon="lock" title-overlay="true" title-color="green">
                    <AbstractForm name="login" fields={LoginFields()} onSubmit={(x,b) => this.onFormSubmit(x,b)}>
                        <div className="col-12">
                            <div className="login-link-in-card">
                                <button type="submit" className="btn btn-success waves-effect">Login</button>
                            </div>
                            <div className="forgot-password-link-in-card">
                                <Link to="/password/forgot" className="btn btn-flat waves-effect">Forgot Password?</Link>
                            </div>
                        </div>
                    </AbstractForm>
                </Card>
            </React.Fragment>
        );
    }
}



export default withRouter(
    connect(null, { setPageTitle })(Login)
);