import io from "socket.io-client";
import { BASE_URL } from "../net";
import redux from "~/store";
import handlers from "./handlers";

import get from "lodash/get";
import genId from "shortid";

class SocketService {
    socket = null;

    get id() {
        return get(this.socket, "id");
    }

    sendData = (type, ...other) => {
        if(this.isConnected()) {
            this.socket.emit(type, ...other);
        }
    }

    connect = () => new Promise((resolve, reject) => {
        if(!this.isConnected()) {
            this.socket = io(BASE_URL, {
                transports: ["websocket"]
            });

            this.socket.on("reconnect", this.authenticate);
    
            Object.getOwnPropertyNames(handlers).forEach(
                x => this.socket.on(x, handlers[x].bind(undefined, this))
            );
    
            if(this.isConnected()) {
                return resolve();
            } else {
                this.socket.on("connect_error", reject);
                this.socket.on("connect", () => {
                    this.socket.off("connect_error", reject);
                    return resolve();
                });
            }
        }
    });

    authenticate = () => new Promise((resolve, reject) => {
        let token = redux.getState().auth.token;
        this.sendData("auth:jwt", { token });
        return resolve();
    });

    createJob = ({ event, payload, timeout = 1000 * 30 }) => new Promise((resolve, reject) => {
        try {
            let requestId = genId();
            console.log("Creating job", requestId);
            this.socket.emit(event, { requestId, ...payload });

            let timer;

            let cb = response => {
                console.log("Got a response for job", requestId);
                clearTimeout(timer);
                this.socket.off(`job:${requestId}`, cb);
                this.socket.off(`job:${requestId}:fail`, cbErr);
                
                return resolve(response);
            };

            let cbErr = error => {
                console.log("Got an error response for job", requestId);
                clearTimeout(timer);
                this.socket.off(`job:${requestId}`, cb);
                this.socket.off(`job:${requestId}:fail`, cbErr);

                return reject(error);
            };

            timer = setTimeout(() => {
                console.log("Failed to get a response for job", requestId);
                this.socket.off(`job:${requestId}`, cb);
                this.socket.off(`job:${requestId}:fail`, cbErr);
                return reject("timeout");
            }, timeout);

            this.socket.on(`job:${requestId}`, cb);
            this.socket.on(`job:${requestId}:fail`, cbErr);
        } catch(ex) {
            return reject(ex);
        }
    });

    isConnected = () =>
        !!get(this.socket, "id");

    close = () => {
        try {
            this.socket.removeAllListeners();
            this.socket.close();
            this.socket = null;
        } catch(ex) {
            console.error(ex);
        }
    }
}

const inst = new SocketService();
global.SocketService = inst;
export default inst;