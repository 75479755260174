import React, { Component } from "react";
import except from "except";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { classNames } from "~/hoc/StyleComposer";

export default class Card extends Component {
    renderCardTitle() {
        let {
            title,
            "title-classes": titleClasses,
            "title-overlay": overlayTitle,
            "center-title": centerTitle,
            "title-color": titleColor = "",
            "title-text-color": titleTextColor = "",
            "title-icon": titleIcon,
            "title-image": titleImage,
            "title-color-shade": titleShade = "",
            "title-color-accent": titleAccent = "",
            "title-icon-props": titleIconProps = {}
        } = this.props;

        if(title) {
            if(overlayTitle) {
                if(titleImage) {
                    return (
                        <div className="form-header" border={titleColor} color-shade={titleShade} color-accent={titleAccent} text-color={titleTextColor} style={{background: `url(${titleImage}) no-repeat center`, backgroundSize: "cover", padding: "2rem 1rem"}}>
                            <h3 className={classNames("card-title", centerTitle && "text-center")}>
                                {titleIcon && (<span><FontAwesomeIcon icon={titleIcon} {...titleIconProps} />{"\u00A0"}</span>)}{title}
                            </h3>
                        </div>
                    );
                } else {
                    return (
                        <div className="form-header" color={titleColor || "teal"} color-shade={titleShade} color-accent={titleAccent} text-color={titleTextColor}>
                            <h3 className={classNames("card-title", centerTitle && "text-center")}>
                                {titleIcon && (<span><FontAwesomeIcon icon={titleIcon} {...titleIconProps} />{"\u00A0"}</span>)}{title}
                            </h3>
                        </div>
                    );
                }
            } else {
                return (
                    <h4 className={classNames("card-title", titleClasses, centerTitle && "text-center")} color={titleColor} color-shade={titleShade} color-accent={titleAccent} text-color={titleTextColor}>
                        {titleIcon && (<span><FontAwesomeIcon icon={titleIcon} {...titleIconProps} />{"\u00A0"}</span>)}{title}
                    </h4>
                );
            }
        }
    }

    renderHeader() {
        let {
            header,
            "header-class": headerClass,
            "header-color": headerColor = "",
            "header-text-color": headerTextColor = "",
            "header-color-shade": headerShade = "",
            "header-color-accent": headerAccent = "",
            "header-icon": headerIcon = ""
        } = this.props;

        if(header) {
            return (
                <div className={classNames("card-header", headerClass)} color={headerColor} color-shade={headerShade} color-accent={headerAccent} text-color={headerTextColor}>
                    {headerIcon && (<span><FontAwesomeIcon icon={headerIcon} />{"\u00A0"}</span>)}{header}
                </div>
            );
        }
    }

    renderTop() {
        let {
            "top-color": topColor,
            "top-icon": topIcon = null
        } = this.props;

        if(topColor) {
            return (
                <div className="card-up" color={topColor}>
                    {topIcon && (
                        <FontAwesomeIcon icon={topIcon} />
                    )}
                </div>
            );
        }
    }

    render() {
        let {
            className,
            bodyClasses,
            image,
            "title-underlined": underlineTitle = false,
            "title-underline-classes": underlineClasses,
            "top-color": topColor
        } = this.props;

        let props = except(this.props, ["href", "className", "image", "title", "title-overlay", "title-image", "title-color", "text-center", "title-icon", "title-color-shade", "title-color-accent", "bodyClasses"]);
        const TagName = this.props.href ? Link : "div";
        return (
            <TagName to={this.props.href} className={classNames("card", topColor && "testimonial-card", className)} {...props}>
                {this.renderHeader()}
                {this.renderTop()}
                {image && ( <img className="img-fluid" src={image} alt="" /> )}
                <div className={classNames("card-body", bodyClasses)}>
                    {this.renderCardTitle()}
                    {underlineTitle && (<hr className={underlineClasses} />)}
                    {this.props.children}
                </div>
            </TagName>
        );
    }
}