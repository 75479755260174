import React from "react";
import { observer } from "mobx-react";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import SchedulingState from "../state";

export default observer(() => (
    <FormControlLabel className="mr-auto" control={
        <Checkbox color="primary"
            checked={SchedulingState.fetchPrevious}
            onChange={SchedulingState.toggleFetchPrevious} />
    } label="Fetch Previous Month's Data" />
));