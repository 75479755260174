import React from "react";
import classNames from "~/lib/classNames";
import { Scrollbars as Scroller } from "react-custom-scrollbars";

const HorizontalScrollbar = ({ className, ...props }) => (
    <div {...props} className={classNames(className, "scrollbar scroll-horizontal")} style={{...props.style, height: null}} />
);

const VerticalScrollbar = ({ className, ...props }) => (
    <div {...props} className={classNames(className, "scrollbar scroll-vertical")} style={{...props.style, width: null}} />
);

const HorizontalThumb = ({ className, ...props }) => (
    <div {...props} className={classNames(className, "scrollbar thumb-horizontal")} />
);

const VerticalThumb = ({ className, ...props }) => (
    <div {...props} className={classNames(className, "scrollbar thumb-vertical")} />
);

const ScrollView = ({ className, ...props }) => (
    <div {...props} className={classNames(className, "scrollbar scroll-view")} />
);

export const Scrollbars = props => (
    <Scroller
        renderView={ScrollView}
        renderTrackHorizontal={HorizontalScrollbar}
        renderTrackVertical={VerticalScrollbar}
        renderThumbHorizontal={HorizontalThumb}
        renderThumbVertical={VerticalThumb}
        {...props} />
);

export default Scrollbars;