import { get, post } from "~/services/net";
import { AUTHENTICATION_ERROR, SET_TOKEN } from "./definitions";

export const ACCOUNT_ERROR = "user_getAcc_err";
export const FETCH_ACCOUNT = "user_getAcc";

export const PASSWORD_RESET_ERROR = "user_passwordReset_error";
export const PASSWORD_RESET_SENT = "user_passwordReset_sent";
export const PASSWORD_RESET = "user_passwordReset_succeed";

export const logout = () => (dispatch) => {
    try {
        // The below delcaration seems not to be working anymore.
            // global.forceLogout(true);
        window.location.href = "https://cfofxn.com";
        localStorage.clear();
    } catch(ex) {
        return dispatch({
            type: AUTHENTICATION_ERROR,
            payload: ex
        });
    }
};

export const setToken = (token) => ({
    type: SET_TOKEN,
    payload: { token }
});

export const forgotPassword = (email, callback) => (dispatch) => {
    try {
        post({
            endpoint: "security/resetpassword",
            data: { email },
            auth: false
        }).then(({error, payload, status}) => {
            if(error) return { type: PASSWORD_RESET_ERROR, payload: error };
            
            return { type: PASSWORD_RESET_SENT, payload };
        }).then((result) => {
            dispatch(result);
            callback(result);
        });
    } catch(ex) {
        return dispatch({
            type: PASSWORD_RESET_ERROR,
            payload: ex
        });
    }
};

export const getAccountDetails = (callback) => (dispatch) => {
    try {
        get({
            endpoint: "portaluser"
        }).then(({ error, payload, status }) => {
            if(status === 403) return { type: ACCOUNT_ERROR, payload: "Unauthorized" }; //Damnit, Swagger!

            if(error)
                return { type: ACCOUNT_ERROR, payload: error };

            return { type: FETCH_ACCOUNT, payload };
        }).then((result) => {
            dispatch(result);
            return callback(result);
        });
    } catch(ex) {
        let resp = {
            type: ACCOUNT_ERROR,
            payload: ex
        };

        dispatch(resp);
        callback(resp);
    }
};

export const resetPassword = (token, password, callback) => (dispatch) => {
    try {
        post({
            endpoint: "security/passwordreset",
            data: { resettoken: token, password },
            auth: false
        }).then(({ error, payload, status }) => {
            if(error) return { type: PASSWORD_RESET_ERROR, payload: error };
            
            return { type: PASSWORD_RESET, payload };
        }).then((result) => {
            dispatch(result);
            callback(result);
        });
    } catch(ex) {
        return dispatch({
            type: PASSWORD_RESET_ERROR,
            payload: ex
        });
    }
};