import React from "react";
import { injectState } from "~/hoc/Mobx";
import { observe, toJS } from "mobx";
import * as yup from "yup";
import shortid from "shortid";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Slide from "@material-ui/core/Slide";

import Tooltip from "~/components/material/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";

import SetupView from "./components/Details";
import TargetsView from "./components/Targets";

import { showPopup } from "~/components/Popup";

import IntegrationService from "~/services/api/integration";
import Notifications from "~/state/NotificationsStore";
import NotificationDropdown from "~/components/Notifications/state";

import state from "./state";

const transition = props => (
    <Slide direction="right" {...props} />
);

const schema = yup.object({
    name: yup.string().min(4).max(48).required(),
    token: yup.string().length(64).required(),
    target: yup.string().min(12).required(),
    divisions: yup.array().of(yup.number()).min(1).ensure().required()
});

const Body = injectState(state, false)(class Body extends React.Component {
    componentDidMount() {
        let { name, target, token, selectedDivisions: divisions, setResult, setReady } = this.props;

        setReady(true);
        setResult({ name, target, token, divisions });

        observe(state.selectedDivisions, () => {
            let { name, target, token, selectedDivisions, setReady, setResult } = this.props;
    
            let divisions = toJS(selectedDivisions);

            if(schema.isValidSync({ name, target, token, divisions })) {
                setReady(true);
                setResult({ name, target, token, divisions });
            } else setReady(false);
        }, true);
    }

    componentWillUnmount() {
        this.props.reset();
    }

    render() {
        let { selectedTab, selectTab } = this.props;

        return (
            <React.Fragment>
                <AppBar position="static" color="default" className="tab-bar">
                    <Tabs classes={{indicator: "indicator-wrapper"}} value={selectedTab} onChange={(_, tab) => selectTab(tab)} indicatorColor="primary" textColor="primary" variant="fullWidth" TabIndicatorProps={{ children: <div className="indicator" /> }}>
                        <Tab className="tab" label="Configuration" value={1} />
                        <Tab className="tab" label="Access Control" value={2} />
                    </Tabs>
                </AppBar>

                {selectedTab == 1 && (
                    <div className="p-3">
                        <SetupView />
                    </div>
                )}

                {selectedTab == 2 && (
                    <TargetsView />
                )}
            </React.Fragment>
        );
    }
});

const Actions = injectState(state, false)(({
    name,
    target,
    token,
    ready,
    closePopup
}) => {
    if(!ready) return null;

    return (
        <Tooltip position="right" title="Generate Integration">
            <IconButton text-color="white" className="m-0" onClick={() => closePopup(true)}>
                <Icon>save</Icon>
            </IconButton>
        </Tooltip>
    );
});

export default async() => {
    let data = null;

    let ok = await showPopup({
        title: "CFOfxn Integration Wizard",
        className: "modal-lg",
        TransitionComponent: transition,
        disableEnforceFocus: true,
        barProps: {
            position: "relative"
        },
        actions: props => (
            <Actions {...props} />
        ),
        children: props => (
            <Body {...props} setResult={x => data = x} />
        )
    });

    if(ok) {
        let notificationId = shortid();

        try {
            NotificationDropdown.openNotifications();
            Notifications.createNotification({
                uid: notificationId,
                contents: "Generating Integration",
                details: "This may take a few minutes...",
                icon: "sync",
                color: "blue",
                pinned: true,
                spinningIcon: true,
                type: "custom:generic",
                date: +new Date(),
                priority: 1
            });

            let zip = await IntegrationService.generateIntegrationZip(data);

            if(zip.error) {
                console.error(zip.error);

                return Notifications.modifyNotification({
                    uid: notificationId,
                    icon: "cancel",
                    contents: "Integration Error!",
                    details: "An exception occurred (err 515) while generating the integration. Please contact an administrator.",
                    color: "red",
                    pinned: false,
                    priority: 2,
                    spinningIcon: false
                });
            }

            await new Promise(r => setTimeout(r, 3000)); //TODO: For testing, remember to remove

            let a = document.createElement("a");
            let blob = new Blob([ zip.payload ]);
            var url = URL.createObjectURL(blob);

            a.href = url;
            a.download = "CFOfxn QuickBooks Integration.zip";
            document.body.appendChild(a);

            Notifications.modifyNotification({
                uid: notificationId,
                icon: "check",
                contents: "Integration Ready!",
                details: "Click here to download it",
                color: "green",
                pinned: false,
                spinningIcon: false,
                actions: {
                    listAction: () => {
                        a.click();

                        setTimeout(function () {
                            document.body.removeChild(a);
                            window.URL.revokeObjectURL(url);
                            Notifications.deleteNotification(notificationId);
                        }, 0);
                    }
                }
            });
        } catch(ex) {
            Notifications.modifyNotification({
                uid: notificationId,
                icon: "cancel",
                contents: "Integration Error!",
                details: "An exception occurred (err 516) while generating the integration. Please contact an administrator.",
                color: "red",
                pinned: false,
                priority: 2,
                spinningIcon: false
            });

            console.error(ex);
        }
    }
};