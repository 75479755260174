import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import except from "except";

import Template from "~/views/_template";

const stateMap = ({ auth: { token } }) => ({
    authenticated: token && token.length
});

class AuthenticatedRoute extends Component {
    render() {
        if(!this.props.authenticated)
            return <Redirect to={`/login#redirect=${encodeURIComponent(window.location.pathname + window.location.search)}`} />;

        if(this.props.render) {
            return (
                <Route {...this.props} />
            );
        } else {
            let Component = this.props.component;
            return (
                <Route {...except(this.props, "component", "render")} render={(props) => (
                    <Template>
                        <Component {...props} />
                    </Template>
                )} />
            );
        }
    }
}

export default connect(stateMap)(AuthenticatedRoute);