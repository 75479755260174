import React from "react";
import Loadable from "react-loadable";
import { Switch, Route } from "react-router-dom";
import {NotFound} from "~/views/Errors";
import LoadingSpinner from "~/components/LoadingSpinner";

const CompanyListView = Loadable({
    loader: () => import("~/views/admin/company/CompanyListView"),
    loading: LoadingSpinner
});

export default ({ match }) => (
    <Switch>
        <Route path={`${match.url}/`} component={CompanyListView} />
        <Route component={NotFound} />
    </Switch>
);