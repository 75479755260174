import { library, config } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fab } from  "@fortawesome/free-brands-svg-icons";


import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-select/dist/react-select.css";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/slide.css";
import "react-s-alert/dist/s-alert-css-effects/flip.css";

import smoothscroll from "smoothscroll-polyfill";

import "./styles/waves.css";
import "./styles/material.css";
import "./styles/spreadsheet.css";
import "./lib/colors";
import "./styles/slim_modal.css";
import "./styles/buttons.css";
import "./styles/index.css";
import "./styles/cfo-green.css";

import "./services/ui/UIEvents";

smoothscroll.polyfill();

library.add(fas, far, fab);
config.replacementClass = "fa";
// window.FontAwesomeConfig.replacementClass = "fa";
// window.FontAwesomeConfig.searchPseudoElements = true;