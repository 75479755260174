import {TENANT_DETAILS, GET_TENANT} from "~/actions/tenant";

export const tenantData = (state = { color: "blue", name: null }, {type, payload}) => {
    if(type === TENANT_DETAILS) {
        return {
            color: payload.color,
            name: payload.name
        };
    }

    return state;
};

export const tenant = (state = null, {type, payload}) => {
    if(type === GET_TENANT) return payload;
    return state;
};