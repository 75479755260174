import axios from "axios";
export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const createRequest = (auth = true, headers = {}, form = false, watcher = null, overrideTimeout = false, extraOpts = {}) => {
    let opts = {
        baseURL: BASE_URL,
        timeout: overrideTimeout ? 0 :60000,
        validateStatus: false,
        ...extraOpts
    };

    if(auth) opts.headers = { "Authorization": `Bearer ${localStorage.getItem("token")}` };

    opts.headers = {...opts.headers, ...headers};
    if(form) opts.headers["Content-Type"] = "multipart/form-data";
    if(!form) opts.headers["Content-Type"] = "application/json";
    
    if(watcher) opts.onUploadProgress = watcher;

    return axios.create(opts);
};

export const handleError = (ex) => {
    if(ex.code && ex.code === "ECONNABORTED") return { payload: null, error: "Could not contact the server, please contact an administrator" };
    return {payload: null, error: ex};
};

export const handleResponse = (res) => {
    if(res.status === 401) return { payload: null, error: res.data.message, status: res.status };
    if(!([400, 304, 200, 500].includes(res.status))) return { payload: null, error: res.message, status: res.status };
    
    let data = res.data;

    if(res.status === 400) {
        let allErrors = data.errors.reduce((errors,error) => {
            errors.push(...error.errors.map(e => ({
                message: e.message,
                path: e.path[0]
            })));
            return errors;
        }, []);

        allErrors.type = "validation";

        return {
            payload: null,
            error: allErrors,
            status: res.status
        };
    }
    
    if(data.isError) return { payload: null, error: data.message, status: res.status };
    
    if(!data.isError && data.message && data.message.length) {
        return {
            payload: data.message,
            error: null,
            status: res.status
        };
    }

    return {
        payload: data.data,
        error: null,
        status: res.status
    };
};

export function get({endpoint, watcher = null, data, auth = true, form = false, headers = {}, extraOpts = {}}) {
    return Promise.resolve()
        .then(() => createRequest(auth, headers, form, watcher, undefined, extraOpts))
        .then((req) => req.get(endpoint, data))
        .then(handleResponse)
        .catch(ex => handleError(ex));
}

export function post({endpoint, watcher = null, data, auth = true, form = false, headers = {}, overrideTimeout = false, extraOpts = {}}) {
    return Promise.resolve()
        .then(() => createRequest(auth, headers, form, watcher, overrideTimeout, extraOpts))
        .then((req) => req.post(endpoint, data))
        .then(handleResponse)
        .catch(ex => handleError(ex));
}

export function put({endpoint, watcher = null, data, auth = true, form = false, headers = {}, extraOpts = {}}) {
    return Promise.resolve()
        .then(() => createRequest(auth, headers, form, watcher, undefined, extraOpts))
        .then((req) => req.put(endpoint, data))
        .then(handleResponse)
        .catch(ex => handleError(ex));
}

//Unfortunately, delete is a reserved token in JS
export function remove({endpoint, watcher = null, data, auth = true, form = false, headers = {}, extraOpts = {}}) {
    return Promise.resolve()
        .then(() => createRequest(auth, headers, form, watcher, undefined, extraOpts))
        .then((req) => req.delete(endpoint, data))
        .then(handleResponse)
        .catch(ex => handleError(ex));
}

//Just a generic way to get/post some data. Used in the list views
export const makeRequest = async (method, url, props, watcher = null) => {
    if(method === "get") {
        let { payload, error } = await get({ endpoint: url, watcher, data: props || {} });
        if(error) throw error;

        return payload;
    } else {
        let { payload, error } = await post({ endpoint: url, watcher, data: props || {} });
        if(error) throw error;

        return payload;
    }
};

global.get = get;