import React from "react";

import WeekDaySelector from "../../DayOfWeekSelector";
import { TimePicker } from "material-ui-pickers";
import moment from "moment";
import isEqual from "lodash/isEqual";

export default class WeeklyFields extends React.PureComponent {
    state = {
        weeks: 0,
        selectedDays: {},
        time: moment.now()
    }

    componentDidMount() {
        let { initialData } = this.props;

        if(initialData) {
            this.setState({
                weeks: initialData.details.dates.weeks,
                selectedDays: initialData.details.dates.on.reduce((a,k) => ({...a, [k]: true}), {}),
                time: moment(initialData.details.time)
            });
        }
    }
    
    onChangeSelectedDays = selectedDays => this.setState({ selectedDays });
    onChangeTime = time => this.setState({ time });

    onChangeWeeks = e => {
        e.preventDefault();
        this.setState({ weeks: Number(e.target.value.replace(/[^\d]/g, "")) });
    }

    componentDidUpdate(prevProps, prevState) {
        let { weeks, selectedDays, time } = this.state;

        if(!isEqual(this.state, prevState)) {
            if(weeks > 0 && selectedDays && Object.keys(selectedDays).length && time)
                this.props.onChange({ dates: { weeks, on: Object.keys(selectedDays) }, time });
        }
    }

    render() {
        let { selectedDays, weeks, time } = this.state;

        return (
            <React.Fragment>
                <div className="d-flex align-items-center">
                    Repeat every {(
                        <input type="text" className="small-size m-0 mx-3 text-center"
                            value={weeks}
                            onFocus={e => e.target.setSelectionRange(0, e.target.value.length)}
                            onChange={this.onChangeWeeks} />
                    )} weeks
                </div>
                <div className="d-flex align-items-center my-3">
                    On {(
                        <WeekDaySelector
                            value={selectedDays} onChange={selectedDays => this.setState({ selectedDays })} />
                    )}
                </div>
                <div className="ml-2 d-flex flex-row align-items-center">
                    Execute import at {(
                        <TimePicker className="mx-3" value={time} onChange={this.onChangeTime} />
                    )}
                </div>
            </React.Fragment>
        );
    }
}