import React from "react";
import uniq from "lodash/uniq";

import { withStyles, classNames } from "~/hoc/StyleComposer";
import { FormControl,InputLabel, Input, Chip, MenuItem, ListSubheader, Select } from "@material-ui/core";

import { metrics, groups } from "./util";
import stylesheet from "./style";
import analyticsStore from "./AnalyticsState"; 

let parentMetrics = {
    _amounts: "#Amounts",
    _percentage: "#Percentage",
    _days: "#Days"
};

const MenuProps = {
    style: { maxHeight: "25rem" }
};

@withStyles(stylesheet)
export default class AnalyticsLinksModalGraphSelector extends React.Component {
    state = {
        selectOpen: false,
        selectedMetrics: [],
        dataRenderable: null
    }

    handleOpen = () => this.setState({ selectOpen: true });
    handleClose = () => this.setState({ selectOpen: false });

    onChangeSelection = e => {
        let selected = [...this.state.selectedMetrics];
        let id = e.target.value;

        let target = id.find(x => !selected.includes(x)) || selected.find(x => !id.includes(x));
        id = target;
        if(!id) return null;

        if(id[0] == "_") { //Parent-level selected
            let children = groups.find(x => x.id == id).children;
            
            if(selected.includes(id)) {
                selected = selected.filter(x => !children.includes(x) && x != id);
            } else {
                selected.push(...children, id);
            }
        } else {
            let parent = groups.find(x => {
                return x.children.includes(id);
            });

            if(selected.includes(id)) {
                selected = selected.filter(x => x != id);
            } else {
                selected.push(id);
            }

            if(selected.filter(x => parent.children.includes(x)).length == parent.children.length) {
                selected.push(parent.id);
            } else {
                selected = selected.filter(x => x != parent.id);
            }
        }

        this.setState({
            selectedMetrics: uniq(selected)
        }, analyticsStore.setSelectedLinkMetrics(uniq(selected)));
    }

    renderMenuItems = () => {
        let { classes } = this.props;
        let out = [];

        out.push(
            <ListSubheader key="___header" classes={{ root: classes.closeSection }}>
                <button className="btn btn-flat float-right waves-effect m-0" text-color="green" onClick={this.handleClose}>Close</button>
            </ListSubheader>
        );

        let detailsStyle = {
            fontSize: ".7rem",
            fontWeight: "normal",
            marginLeft: ".4rem",
            paddingTop: ".1rem"
        };

        groups.filter(x => x.id != "_income").forEach(group => {
            out.push(
                <MenuItem key={group.id} value={group.id} className="bold">
                    {group.name} <span style={detailsStyle}><i text-color="red">*</i> Click to toggle all {group.name} accounts</span>
                </MenuItem>
            );

            group.children.forEach(child => {
                out.push(
                    <MenuItem key={child} value={child}>{"        " + metrics[child]}</MenuItem>
                );
            });
        });

        return out;
    }

    renderChips = selected => {
        let { classes } = this.props;
 
        return selected.reduce((chips, metric) => {
            if(metric[0] == "_") {
                chips.push(
                    <Chip key={metric} label={parentMetrics[metric]} className={classes.boldchip} />
                );
            } else {
                let parent = groups.find(x => x.children.includes(metric));

                if(!selected.includes(parent.id)) {
                    chips.push(
                        <Chip key={metric} label={metrics[metric]} className={classes.chip} />
                    );
                }
            }
            
            return chips;
        }, []);
    }

    render() {
        let { classes } = this.props;
        let visible = true;
        let { selectedMetrics, selectOpen } = this.state;
        return (
            <div className={classNames("overflow-auto w-100", !visible && "d-none", classes.box)}>
                <div className="col" style={{maxWidth: "100%", margin: "2rem auto"}}>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="select-multiple-chip">Graphed Metrics</InputLabel>
                        <Select multiple value={selectedMetrics} onChange={this.onChangeSelection} MenuProps={MenuProps}
                            input={<Input className="w-100" id="select-multiple-chip" />}
                            open={selectOpen}
                            onClose={this.handleClose}
                            onOpen={this.handleOpen}
                            renderValue={selected => (
                                <div className={classes.chips}>
                                    {this.renderChips(selected)}
                                </div>
                            )}>
                        {this.renderMenuItems()}
                        </Select>
                    </FormControl>
                </div>
            </div>
        );
    }
}