import React from "react";
import { observer } from "mobx-react";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const Row = ({
    id, name,
    onSelectDivision,
    checked
}) => (
    <tr data-id={id}>
        <td>{name}</td>
        <td>
            <FormControlLabel  control={
                <Checkbox checked={checked} color="primary" onChange={onSelectDivision} />
            } />
        </td>
    </tr>
);

export default observer(({
    selectedCompany,
    divisions,
    selectedDivisions,
    onSelectDivision,
    onSelectAllDivisions
}) => (
    <div className="col-md-8 p-0 pr-1 tableFixHead mh-100 overflow-hidden d-flex flex-column">
        <h4 className="my-1 ml-2">
            Divisions
        </h4>
        <div className="mh-100 overflow-hidden overflow-y">
            <table className="table table-no-bottom-margin table-bordered table-striped table-hover instant mb-0">
                <thead>
                    <tr>
                        <th className="w-100 bold">Name</th>
                        <th className="w-0 bold">Allow Access</th>
                    </tr>
                </thead>
                <tbody>
                    <Row checked={selectedDivisions.includes(selectedCompany.value)}
                        id={selectedCompany.value} name={selectedCompany.label}
                        onSelectDivision={() => onSelectDivision(selectedCompany.value)} />
                    {!!divisions && divisions.map(division => (
                        <Row key={division.id} checked={selectedDivisions.includes(division.id)}
                            id={division.id} name={division.name}
                            onSelectDivision={() => onSelectDivision(division.id)} />
                    ))}
                </tbody>
            </table>
        </div>
    </div>
));