import React from "react";
import { injectState } from "~/hoc/Mobx";
import Portal from "~/components/Portal";
import Modal from "~/components/material/Modal/Modal";

import classNames from "~/lib/classNames";
import IconToggler from "~/components/IconToggler";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "~/components/material/Tooltip";
import Icon from "@material-ui/core/Icon";
import { Scrollbars } from "~/components/ScrollableArea";

import { actions as assistantTopics } from "./dictionary";

import "./style.css";
import state from "./state";

const Footer = ({ onCloseModal }) => (
    <div className="row">
        <button type="button"
            className="btn btn-flat waves-effect"
            onClick={onCloseModal}>Close</button>
    </div>
);

const NewAssistantTile = ({
    toggleable, toggled = false,
    icon, activeIcon,
    tooltip, onClick,
    component: Component = IconButton
}) => (
    <Tooltip placement="right" title={tooltip}>
        <Component className="cfo-assistant-topic" onClick={onClick}>
            {toggleable ? (
                <IconToggler icon={icon} toggledIcon={activeIcon} toggled={toggled} />
            ) : (
                typeof icon == "string" ? (
                    <Icon>{icon}</Icon>
                ) : icon
            )}
        </Component>
    </Tooltip>
);

const NewAssistant = ({
    open,
    modalOpen,
    topic = {},
    onToggle,
    onSelectTopic,
    onClose,
    onCloseModal
}) => (
    <React.Fragment>
        <div className="cfo-assistant-blocker" />
        <Portal>
            <div className={classNames("cfo-assistant", open && "open")} onClick={onClose}>
                <div className="assistant-sidebar">
                    <Scrollbars>
                        <div className="placeholder" />
                        <hr />
                        {assistantTopics.map(action => (
                            <NewAssistantTile key={action.name}
                                tooltip={action.label}
                                icon={action.icon}
                                onClick={action.onClick(onSelectTopic)} />
                        ))}
                    </Scrollbars>
                </div>

                <NewAssistantTile toggleable icon="help" activeIcon="close" toggled={open} onClick={onToggle} tooltip={open ? "Close" : "CFO Assistant"} />
            </div>

            <Modal controlled visible={modalOpen} footer={<Footer onCloseModal={onCloseModal} />} title={topic.title} hideLines closeOnClick onModalClose={onCloseModal}>
                {topic.note && (
                    <p className="cfo-assistant-note"><span>*</span> Note: {topic.note}</p>
                )}
                <p>
                    { typeof topic.text === "function" ? topic.text() : topic.text }
                </p>
            </Modal>
        </Portal>
    </React.Fragment>
);

export default injectState(state)(NewAssistant);