import React from "react";
import { connect } from "react-redux";
import { setPageTitle } from "~/actions/ui";

import { Card } from "~/components/material";
import Background from "~/components/Background";
import { getSearchParameters } from "~/lib/subdomain";

import AuthService from "~/services/api/auth";

const REDIRECT_TIMEOUT = 1000 * 7;

@connect(null, { setPageTitle })
export default class EmailConfirmation extends React.PureComponent {
    state = {
        sent: false,
        error: null
    }

    constructor(props) {
        super(props);
        props.setPageTitle(null);
    }

    async componentDidMount() {
        let params = getSearchParameters(this.props.location.search);
        
        if(!params.token) {
            return this.setState({ sent: true, error: "Invalid token. Please contact an administrator." });
        }

        try {
            let { error } = await AuthService.confirmEmail(params.token);

            if(error)
                return this.setState({ error, sent: true });

            return this.setState({
                error: null, sent: true
            }, () => setTimeout(() => global.redirect("/"), REDIRECT_TIMEOUT));
        } catch(ex) {
            return this.setState({
                sent: true,
                error: ex.message || ex
            });
        }
    }

    render() {
        let { sent, error } = this.state;
        
        let message = sent ? (
            error ? "Error!" : "Email Confirmed!"
        ) : (
            "Confirming Email..."
        );

        let icon = sent ? (
            error ? "exclamation-triangle" : "check"
        ) : "sync";

        let body = sent ? (
            error ? (
                <div text-color="red">{error}</div>
            ) : "Success! You will be redirected to the login shortly..."
        ) : "This process may take a minute...";

        return (
            <React.Fragment>
                <Background image="/res/login-cover-lg.jpg" />

                <Card className="mt-3 login-card" bodyClasses="text-center" title={message} title-icon={icon} title-icon-props={{spin: !sent}} title-overlay="true" title-color={error ? "red" : "green"}>
                    {body}
                </Card>
            </React.Fragment>
        );
    }
}