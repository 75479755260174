import React from "react";
import $ from "~/lib/elemutil";
import shortid from "shortid";
import classNames from "~/lib/classNames";

import Portal from "~/components/Portal";

let openModals = [];

export default class Modal extends React.Component {
    modalId = shortid();
    outer = null;

    shouldComponentUpdate() {
        return !this.props.shouldNeverUpdate;
    }
    
    open = () => {
        if(!openModals.includes(this.modalId)) {
            openModals.push(this.modalId);
        }

        $(this.outer).addClass("show");
        $("body").addClass("modal-open");
    }
    
    close = () => {
        if(openModals.includes(this.modalId)) {
            openModals.splice(openModals.indexOf(this.modalId), 1);
        }

        if(!openModals.length) {
            $("body").removeClass("modal-open");
        }

        if(this.props.onModalClose) {
            this.props.onModalClose();
        }
    }

    toggle = () => {
        if($(this.outer).hasClass("show")) {
            this.close();
        } else this.open();
    }

    tryClose = e => {
        if(e.target && e.target.matches && e.target.matches(".modal") && this.props.closeOnClick) {
            this.close();
        }
    }

    componentDidUpdate(props) {
        if(props.visible !== this.props.visible && !!!this.props.visible) {
            this.close();
        } else if(props.controlled && props.visible !== this.props.visible && !!this.props.visible) {
            this.open();
        }
    }

    componentWillUnmount() {
        this.close();
    }

    render() {
        let {
            children,
            title,
            head,
            footer,

            showClose = true,
            showTitle = true,
            hideLines = false,
            "title-color": titleColor,
            visible,

            className,
            bodyStyle,
            bodyClass,
            headClass,
            titleClass,
            modalClass,
            wrapperClass
        } = this.props;

        if(this.props.hasOwnProperty("visible") && !visible)
            return null;
        
            return (
            <Portal>
                <div key={this.modalId} data-modal-id={this.modalId} ref={r => this.outer = r} className={classNames("modal fade", visible && "show", modalClass)} tabIndex="-1" role="dialog" onClick={this.tryClose}>
                    <div className={classNames("modal-dialog animated fadeIn", className)} role="document">
                        <div className={classNames("modal-content", wrapperClass)}>
                            {head && (
                                <div className={classNames("modal-header", headClass)}>
                                    {head}
                                    {showClose && (
                                        <button type="button" className="close" onClick={this.close}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    )}
                                </div>
                            )}
                            {showTitle && (
                                <div className="modal-header" color={titleColor}>
                                    {title && (
                                        <h5 className={classNames("modal-title", titleClass)}>{title}</h5>
                                    )}
                                    {showClose && (
                                        <button type="button" className="close" onClick={this.close}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    )}
                                </div>
                            )}
                            <div className={classNames("modal-body", bodyClass)} style={bodyStyle}>{children}</div>
                            {footer && (
                                <div className={classNames("modal-footer", hideLines && "hide-bar")}>{footer}</div>
                            )}
                        </div>
                    </div>
                </div>
            </Portal>
        );
    }
}