import React from "react";
import { TermsAndConditions as TermsAndConditionsService } from "~/services/api";
import Alert from "~/services/ui/alerts";
export default class TermsAndConditions extends React.Component {
    state = {
        loading: true,
        termsAndConditions: null
    }

    async componentDidMount() {
        let { error, res } = await TermsAndConditionsService.getLatestTermsAndConditions();

        if(error) {
            return Alert.showError(error);
        }

        this.setState({ termsAndConditions: res[0].TermsAndConditionsText, loading: false });
    }
    
    render() {
        return (
            <React.Fragment>
                {this.state.loading  === true ? <h3 style={{ textAlign: "center" }}>getting terms and conditions...</h3> : <div dangerouslySetInnerHTML={{__html: this.state.termsAndConditions}}></div>}
            </React.Fragment>
        );
    }
}