import React from "react";
import Portal from "./Portal";
import LoadingSpinner from "./material/LoadingSpinner";

export default ({ color = "green" }) => (
    <Portal>
        <div className="fullscreen">
            <div className="center">
                <LoadingSpinner color={color} size="big" />
            </div>
        </div>
    </Portal>
);