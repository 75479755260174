import {
    insertIntoHierarchy,
    generateTree
} from "./SummaryReportParser";

import sortBy from "lodash/sortBy";
import unique from "lodash/uniq";
import get from "lodash/get";

export const reduce = (report, Dates, AllDates, mode, Raw) => {
    return report.reduce((Report, row, i) => {
        //No hierarchy
        Report.Raw.push(row);
        
        //Level-based hierarchy
        let level = Report.Levels[row.Level] || [];
        Report.Levels[row.Level] = [...level, row];

        //ParentId-Child-based hierarchy
        if(!row.ParentAccountId) {
            Report.Hierarchy[row.StandardAccountId] = row;
        } else {
            Report.Hierarchy = insertIntoHierarchy(Report.Hierarchy, row);
        }

        return Report;
    }, { Levels: [], Hierarchy: {}, Raw: [], Dates, AllDates, Mode: mode });
};

export const parse = (report, mode, type) => {
    if(!report.Dates) report.Dates = report.dates;
    
    let sorted = sortBy(report, "StandardAccountId");
    let standardAccounts = unique(sorted.map(x => x.StandardAccountId));

    let merged = [];

    for(let standardAccountId of standardAccounts) {
        let shell = sorted.find(x => x.StandardAccountId == standardAccountId);

        let amounts = report.dates.map(({ id }) => (
            sorted.filter(x => x.DateId == id && x.StandardAccountId == standardAccountId).reduce((a,b) => a + get(b, "Amount", 0), 0)
        ));

        if(type === 4) {
            let netChange, percentChange;
            netChange = amounts[0] - amounts[1];
            percentChange = (netChange / amounts[1]) * 100;
            amounts.push(netChange, percentChange);
        }

        merged.push({
            StandardAccountId: standardAccountId,
            Level: shell.Level,
            Name: shell.Name,
            EBITDA: !!sorted.find(x => x.StandardAccountId == standardAccountId && x.EBITDA),
            ParentAccountId: shell.ParentAccountId,
            Dates: report.dates,
            Amounts: amounts,
            SortPriority: shell.SortPriority
        });
    }

    return reduce(merged, report.dates, report.dates, mode, sorted);
};

export const convertToTree = (report, mode) => {
    return {
        parsed: generateTree(report, mode),
        dates: report.Dates,
        allDates: report.AllDates
    };
};