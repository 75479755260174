import React from "react";
import { computed } from "mobx";
import { observer } from "mobx-react";
import get from "lodash/get";

export const injectState = (StateClass, options = true) => Component => {
    let WrappedComponent = observer(Component);

    let createNew;

    if(typeof options === "boolean") {
        createNew = options;
    } else {
        createNew = get(options, "createNew", false);
    }

    return observer(class MobXInjectedStateComponent extends React.Component {
        stateInst = null;
        stateProps = null;
    
        UNSAFE_componentWillMount() {
            let stateInst;
            if(createNew) {
                stateInst = new StateClass();
            } else stateInst = StateClass;

            let stateProps = Object.getOwnPropertyNames(stateInst).concat(Object.getOwnPropertyNames(Object.getPrototypeOf(stateInst))).filter(x => x != "constructor" && typeof stateInst[x] === "function");

            this.stateInst = stateInst;
            this.stateProps = stateProps;
        }
    
        componentWillUnmount() {
            this.stateInst = null;
            this.stateProps = null;
        }
    
        @computed
        get mappedStateProps() {
            return this.stateProps.reduce((set,key) => ({...set, [key]: this.stateInst[key]}), {});
        }
    
        render() {
            return (
                <WrappedComponent {...this.props} {...this.stateInst} {...this.mappedStateProps} />
            );
        }
    });
};

export const withState = (stateClass, prop) => Component => {
    let ObserverComponent = observer(Component);

    class MobXInjectedStateComponent extends React.Component {
        constructor(...args) {
            super(...args);
            this.stateObj = new stateClass();
        }

        componentWillUnmount() {
            this.stateObj = null;
        }

        render = () => (
            <ObserverComponent {...this.props} {...{ [prop]: this.stateObj }} />
        );
    }

    return observer(MobXInjectedStateComponent);
};

export default injectState;