import React from "react";

import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";

let dataset = [
    { key: "sunday", label: "S" },
    { key: "monday", label: "M" },
    { key: "tuesday", label: "T" },
    { key: "wednesday", label: "W" },
    { key: "thursday", label: "T" },
    { key: "friday", label: "F" },
    { key: "saturday", label: "S" }
];

export default class DayOfWeekSelector extends React.PureComponent {
    state = {
        selected: {}
    }

    onClick = id => () => {
        let { disabled, isRadio } = this.props;

        if(disabled) return;

        if(isRadio) {
            return this.props.onChange({ [id]: true });
        }
        
        let selected = {...this.props.value};

        if(id in selected) {
            delete selected[id];
        } else selected[id] = true;

        this.props.onChange(selected);
    }

    render() {
        let { disabled, value = {} } = this.props;

        return (
            <Grid container alignItems="center" className="day-of-week-selector">
                {dataset.map(({ key, label }) => (
                    <Avatar disabled={disabled}
                        key={key}
                        color={key in value ? "green" : "grey"}
                        color-shade={disabled ? "7" : "5"}
                        onClick={this.onClick(key)}
                        children={label} />
                ))}
            </Grid>
        );
    }
}