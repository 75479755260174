import { Component } from "react";

export default class AsyncComponent extends Component {
    updateState(state) {
        return new Promise((resolve, reject) => {
            try {
                this.setState(state, resolve);
            } catch(ex) {
                return reject(ex);
            }
        });
    }
}