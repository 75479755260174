import React, { Component } from "react";
import { Select } from "~/components/material";

export const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
]; //1 - January, 2 - February...

const MONTH_FIELDS = MONTHS.map((v,i) => ({ label: v, value: i + 1 }));
export default class MonthSelector extends Component {
    getMonths() {
        let { monthFilter, monthsWithData, disableMonthsWithData = false, forceExistsCheck } = this.props;

        if(monthFilter) {
            if(forceExistsCheck) {
                return MONTH_FIELDS.filter(x => monthFilter.find(y => (y.Month || y) == x.value));
            }
            
            let out = [];

            for(let target of monthFilter) {
                let field = MONTH_FIELDS.find(x => x.value == (target.Month || target));

                if(target.Exists == 2) {
                    out.push({
                        ...field,
                        label: <em text-color="blue-grey">{field.label}</em>
                    });
                } else {
                    out.push(field);
                }
            }

            return out;
        } else if(monthsWithData) {
            return MONTH_FIELDS.map(item => {
                if(monthsWithData.includes(item.value)) {
                    return {...item, label: item.label + " (Data Exists)", disabled: disableMonthsWithData ? true : item.disabled };
                } else return item;
            });
        } else return MONTH_FIELDS;
    }

    render() {
        return (
            <Select
                ref={ref => this.select = ref}
                data={this.getMonths()}
                isClearable={false}
                {...this.props}/>
        );
    }
}