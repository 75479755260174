import React from "react";

import { TimePicker } from "material-ui-pickers";
import moment from "moment";
import isEqual from "lodash/isEqual";

export default class DailyFields extends React.PureComponent {
    state = {
        days: 0,
        time: moment.now()
    }

    componentDidMount() {
        let { initialData } = this.props;

        if(initialData) {
            this.setState({
                days: initialData.details.dates,
                time: moment(initialData.details.time)
            });
        }
    }
    
    onChangeTime = time => this.setState({ time });

    onChangeDays = e => {
        e.preventDefault();
        this.setState({ days: Number(e.target.value.replace(/[^\d]/g, "")) });
    }

    componentDidUpdate(prevProps, prevState) {
        let { days, time } = this.state;

        if(!isEqual(this.state, prevState)) {
            if(days > 0 && time)
                this.props.onChange({ dates: days, time });
        }
    }

    render() {
        let { days, time } = this.state;

        return (
            <React.Fragment>
                <div className="ml-2 d-flex align-items-center">
                    Repeat every {(
                        <input type="text" className="small-size m-0 mx-3 text-center"
                            value={days}
                            onFocus={e => e.target.setSelectionRange(0, e.target.value.length)}
                            onChange={this.onChangeDays} />
                    )} days
                </div>
                <div className="ml-2 d-flex flex-row align-items-center">
                    Execute import at {(
                        <TimePicker className="mx-3 center-input" value={time} onChange={this.onChangeTime} />
                    )}
                </div>
            </React.Fragment>
        );
    }
}