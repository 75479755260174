export default function getSubdomain(defaultValue = "") {
    let parts = window.location.host.split(".");
    
    if (parts.length >= 3) //test.example.com
        return parts[0];
    
    return defaultValue;
}

const parseType = str => {
    if(/^-?\d+$/.test(str)) return parseInt(str);
    if(str === "true" || str === "false") return str === "true";

    return str;
};

export const getSearchParameters = search => {
    let out = {};
    let params = new URLSearchParams(search);
    let iterated = [...params.entries()];

    for(let pair of iterated) {
        out[pair[0]] = parseType(pair[1]);
    }

    return out;
};

export const getHashParameters = hash => {
    if(!hash || !hash.length) return {};

    let test = "?" + hash.substring(1);
    return getSearchParameters(test);
};