import React from "react";
import { observe } from "mobx";
import { observer } from "mobx-react";
import classNames from "~/lib/classNames";
import moment from "moment";
import { showConfirmationModal } from "~/components/material/Modal/ConfirmationModal";
import { VariableSizeList } from "react-window";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import NotificationHeader from "./NotificationHeader";

import NotificationStore from "~/state/NotificationsStore";

const NotificationLink = React.forwardRef(({ className, children, ...props } ,ref) => (
    <a {...props} ref={ref} className={classNames(className, "m-0")}>{children}</a>
));

const paperStyle = {
    maxHeight: "15rem"
};

@observer
export default class NotificationList extends React.Component {
    state = {
        anchorElement: null,
        dropdownTarget: null
    };

    onOpen = (uid, e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ dropdownTarget: uid, anchorElement: !!this.state.anchorElement ? null : e.currentTarget });
    }

    onClose = () =>
        this.setState({ dropdownTarget: null, anchorElement: null });

    onClearSimilar = () => {
        let { dropdownTarget } = this.state;
        this.setState({ anchorElement: null, dropdownTarget: null }, () => NotificationStore.clearSimilar(dropdownTarget));
    }

    onClearDivision = () => {
        let { dropdownTarget } = this.state;
        let notif = NotificationStore.notifications.find(x => x.uid == dropdownTarget);

        if(!notif || !notif.metadata || !notif.metadata.companyId) {
            this.setState({ anchorElement: null, dropdownTarget: null }, () => {
                NotificationStore.deleteNotification(dropdownTarget);

                showConfirmationModal({
                    title: "Error",
                    hideOkay: true,
                    cancelLabel: "Close",
                    children: "This notification does not have an associated Company ID. We have cleared that notification, but no others have been affected"
                });
            });
        } else {
            this.setState({ anchorElement: null, dropdownTarget: null }, () => NotificationStore.clearForDivision(dropdownTarget));
        }
    }

    getRowSize = index => {
        let item = NotificationStore.notifications[index];
        return 86 + (20 * Math.floor(item.details.length / 72));
    }

    //Recalculate the size of each notification when the count changes (otherwise sizes are assigned to index, so the next notification gets resized)
    listRef = () => {
        let ref = React.createRef();

        observe(NotificationStore, "notificationCount", () => {
            if(ref.current) {
                ref.current.resetAfterIndex(0);
            }
        });

        return ref;
    }

    renderRow = ({ index: i, style }) => {
        let { wrapClickHandler } = this.props;

        let item = NotificationStore.notifications[i];
        let itemProps = { className: classNames("notification", item.read && "read", item.priority == 2 && "important", item.pinned && "pinned") };
            
        if(item.actions && item.actions.listAction) {
            itemProps.component = NotificationLink;
            itemProps.button = true;
            itemProps.onClick = wrapClickHandler(item);
        }

        return (
            <React.Fragment key={item.uid}>
                <ListItem {...itemProps} data-color={item.color} style={style}>
                    <ListItemIcon>
                        <Icon className={classNames(item.spinningIcon && "spinning")}>{item.icon}</Icon>
                    </ListItemIcon>
                    <ListItemText className="notif-title" primary={item.contents} secondary={
                        <React.Fragment>
                            {item.details}
                            <abbr className="details d-block" title={moment(item.date).toLocaleString()}>{moment(item.date).fromNow()}</abbr>
                        </React.Fragment>
                    } />
                    <div className="notif-actions" aria-label="Actions" aria-haspopup="true">
                        <IconButton onClick={e => this.onOpen(item.uid, e)}>
                            <Icon>more_vert</Icon>
                        </IconButton>
                    </div>
                </ListItem>
                {!!item.pinned && !!(NotificationStore.notifications[i + 1] && !NotificationStore.notifications[i + 1].pinned) && (
                    <Divider />
                )}
            </React.Fragment>
        );
    }

    render() {
        let { anchorElement } = this.state;
        let open = !!anchorElement;

        if(!NotificationStore.notifications.length) return (
            <React.Fragment>
                <NotificationHeader />
                <p className="no-notifications">No new notifications</p>
            </React.Fragment>
        );

        return (
            <React.Fragment>
                <NotificationHeader />
                <VariableSizeList ref={this.listRef()} height={450} width={560} itemSize={this.getRowSize} itemCount={NotificationStore.notifications.length}>
                    {this.renderRow}
                </VariableSizeList>
                <Menu anchorEl={anchorElement} open={open} onClose={this.onClose} PaperProps={{style: paperStyle}}>
                    <MenuItem onClick={this.onClearSimilar}>Clear Similar</MenuItem>
                    <MenuItem onClick={this.onClearDivision}>Clear for Division</MenuItem>
                </Menu>
            </React.Fragment>
        );
    }
}