import React from "react";
import debounce from "lodash/debounce";

/**
 * Knows when it's being rendered by a mobile device, and passes the isMobile prop to the composed component
 */
export const isResponsive = Component => class extends React.PureComponent {
    state = {
        mobile: this.isMobile
    }

    get isMobile() {
        return window.innerWidth < 960;
    }

    onResize = debounce(() => this.setState({ mobile: this.isMobile }), 250)

    componentDidMount = () => {
        window.addEventListener("resize", this.onResize);
    }

    componentWillUnmount = () => {
        window.removeEventListener("resize", this.onResize);
    }

    render() {
        return (
            <Component
                {...this.props}
                isMobile={this.state.mobile} />
        );
    }
};

/**
 * Renders a different component if being rendered by a mobile device
 * @param {React.Component} OtherComponent - Component to render if mobile 
 */
export const isHotswapResponsive = OtherComponent => Component => props => class extends React.PureComponent {
    state = {
        mobile: this.isMobile
    }

    get isMobile() {
        return window.innerWidth < 960;
    }

    onResize = () => {
        this.setState({ mobile: this.isMobile });
    }

    UNSAFE_componentWillMount = () => {
        window.addEventListener("resize", this.onResize);
    }

    componentWillUnmount = () => {
        window.removeEventListener("resize", this.onResize);
    }

    render() {
        if(this.state.mobile)
            return (<OtherComponent {...this.props} />);

        return (
            <Component {...this.props} />
        );
    }
};