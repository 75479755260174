import React, { Component } from "react";
import classNames from "~/lib/classNames";

export default class LoadingSpinner extends Component {
    render() {
        let { size = "small", color = "red", className } = this.props;
        
        return (
            <div className={classNames("preloader-wrapper", "active", size, className)}>
                <div className="spinner-layer" border-color={color}>
                    <div className="circle-clipper left"><div className="circle" /></div>
                    <div className="gap-patch"><div className="circle" /></div>
                    <div className="circle-clipper right"><div className="circle" /></div>
                </div>
            </div>
        );
    }
}