import React from "react";
import { Switch, Route } from "react-router-dom";
import {NotFound} from "~/views/Errors";

import AccountAdminRoutes from "./admin/AccountRoutes";
import AliasAdminRoutes from "./admin/AliasRoutes";
import BalanceAdminRoutes from "./admin/BalanceRoutes";
import BudgetRoutes from "./admin/BudgetRoutes";
import CashFlowRoutes from "./admin/CashFlowRoutes";
import CompanyAdminRoutes from "./admin/CompanyAdminRoutes";
import DivisionAdminRoutes from "./admin/DivisionRoutes";
import FinancialNotesRoutes from "./admin/FinancialNotesRoutes";
import ForecastRoutes from "./admin/ForecastRoutes";
import ImportAdminRoutes from "./admin/ImportAdminRoutes";
import IncomeAdminRoutes from "./admin/IncomeRoutes";
import SystemReportsRoutes from "./admin/SystemReportsRoutes";
import UserAdminRoutes from "./admin/UserAdminRoutes";
import Disclaimer from "~/views/Disclaimer";

export default ({ match }) => (
    <Switch>
        <Route path={`${match.url}/account`} component={AccountAdminRoutes} />
        <Route path={`${match.url}/aliasing`} component={AliasAdminRoutes} />
        <Route path={`${match.url}/balance`} component={BalanceAdminRoutes} />
        <Route path={`${match.url}/budget`} component={BudgetRoutes} />
        <Route path={`${match.url}/cashflow`} component={CashFlowRoutes} />
        <Route path={`${match.url}/companies`} component={CompanyAdminRoutes} />
        <Route path={`${match.url}/divisions`} component={DivisionAdminRoutes} />
        <Route path={`${match.url}/forecast`} component={ForecastRoutes} />
        <Route path={`${match.url}/import`} component={ImportAdminRoutes} />
        <Route path={`${match.url}/income`} component={IncomeAdminRoutes} />
        <Route path={`${match.url}/notes`} component={FinancialNotesRoutes} />
        <Route path={`${match.url}/reports`} component={SystemReportsRoutes} />
        <Route path={`${match.url}/users`} component={UserAdminRoutes} />
        <Route path={`${match.url}/disclaimer`} component={Disclaimer} />
        <Route component={NotFound} />
    </Switch>
);