import React, { Component, Fragment } from "react";
import { Field } from "redux-form";
import StateSelector from "./StateSelector";
import { TextInput } from "~/components/material";

import { zipcodeMask } from "~/lib/masks";
import { zipCodeNotRequired } from "~/lib/validation";

export default class AddressFieldset extends Component {
    render() {
        let { disabled = false } = this.props;
        return (
            <Fragment>
                <Field component={TextInput} name="Address" label="Address" disabled={disabled} />
                <Field component={TextInput} name="City" label="City" disabled={disabled} />
                <div className="row">
                    <Field component={StateSelector} name="State" label="State" colspan="col-6" disabled={disabled} />
                    <Field component={TextInput} {...zipcodeMask} validate={[zipCodeNotRequired]} name="Zip" label="Zip" colspan="col-6" style={ { marginTop: 0, height: "3rem", padding: 0 } } disabled={disabled} />
                </div>
            </Fragment>
        );
    }
}