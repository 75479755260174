import React, { Component } from "react";
import UselessLink from "./UselessLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

export default class BackButton extends Component {
    static contextTypes = {
        router: PropTypes.object
    }

    render() {
        return (
            <UselessLink onClick={e => this.context.router.history.goBack()}>
                <FontAwesomeIcon text-color="black" icon="arrow-left" transform="shrink-3" />&nbsp;
            </UselessLink>
        );
    }
}