import React from "react";
import AbstractForm from "~/components/generic/GenericForm";
import get from "lodash/get";

const generateFields = (state, setValue) => [
    {
        name: "company",
        useNewBehavior: true,
        type: "select",
        select: "company"
    },
    {
        name: "divisions",
        type: "divisions",
        includeCompany: true,
        ignoreInput: true,
        disabled: !state.companyId,
        companyId: state.companyId,
        companyLabel: state.companyName,
        selectedCompany: state.company,
        value: state.divisionsId,
        onSelectDivision: value => setValue("divisions", value)
    }
];

const convertToArray = obj => {
    let out = [];
    let c = true;
    let i = 0;
    while(c) {
        let val = obj[i];
        
        if(val) {
            out.push(val);
            i++;
        } else c = false;
    }

    return out;
};

//TODO: 90% of this is just copy-pasted from the system reports... maybe there's a way to abstract this out further?
const mergeValues = (a, b) => {
    let c = Object.assign({ company: null, divisions: null }, a, b);

    Object.getOwnPropertyNames(c).forEach(key => {
        if(b[key] === undefined && a[key] !== undefined) {
            c[key] = a[key];
        }
    });

    if(!c.divisions) {
        c.divisions = [];
    } else if(!Array.isArray(c.divisions)) {
        c.divisions = [ c.divisions ];
    }

    c.companyId = get(c, "company.value", null);
    c.companyName = get(c, "company.label", null);
    c.divisionsId = get(c, "divisions.0.value", null);

    return c;
};

export default class ReportingDefaultsBody extends React.Component {
    state = {};

    isReady = () => {
        return this.state.companyId && this.state.divisions && this.state.divisions.length;
    }

    componentDidMount() {
        let { modalProps } = this.props;

        if(modalProps) {
            modalProps.hideOkay = !this.isReady();
        }
    }

    componentDidUpdate() {
        let { companyId, divisions } = this.state;
        let { modalProps } = this.props;

        if(modalProps) {
            modalProps.hideOkay = !this.isReady();
        }

        if(companyId && divisions) {
            this.props.setResult(this.state);
        }
    }

    _updateField(name, val) {
        this.form.setFieldValue(name, val);
        this.setState({ [name]: val });
    }

    _updateFields(...fields) {
        let vals = fields.reduce((all, [key, value]) => {
            this.form.setFieldValue(key, value);

            return {
                ...all,
                [key]: value
            };
        }, {});

        this.setState({
            ...vals
        });
    }

    onUpdateField = (val, _, __, name) => {
        if(name == "statementType") {
            this._updateFields(
                ["statementType", _],
                ["dates", []]
            );
        } else if(name == "dates") {
            let dates = val;
        
            if(!Array.isArray(dates) && !!dates) {
                if(dates.hasOwnProperty("0")) {
                    dates = convertToArray(dates);
                } else {
                    dates = [dates];
                }
            }
    
            if(dates.length) {
                this._updateField("dates", dates);
            }
        } else if(name == "divisions") {
            if(!Array.isArray(val)) {
                if(val.hasOwnProperty("0")) {
                    this._updateFields(
                        ["divisions", convertToArray(val)],
                        ["dates", []]
                    );
                } else {
                    this._updateFields(
                        ["divisions", [ val ]],
                        ["dates", []]
                    );
                }
            } else {
                this._updateFields(
                    ["divisions", val],
                    ["dates", []]
                );
            }
        }
    }

    onUpdateForm = values => {
        let newVals = mergeValues(this.state, values);

        if(!!this.state.companyId && this.state.companyId !== newVals.companyId) {
            if(!isNaN(Number(newVals.company)) && newVals.company == this.state.companyId) return;

            this.form.setFieldValue("divisions", []);
            newVals.divisions = [];
        }

        this.setState(newVals);
    }

    render() {
        let { company, divisions } = this.state;
        let values = { company, divisions };
        let fields = generateFields(this.state, (k, v) => this.onUpdateField(v, null, null, k));

        return (
            <AbstractForm useNewBehavior
                innerRef={ref => this.form = ref}
                data={values}
                name="reportingDefaultsModal"
                fields={fields}
                onSubmit={e => null}
                overridingOnChange={this.onUpdateField}
                onUpdate={this.onUpdateForm} />
        );
    }
}