import React, { PureComponent } from "react";
import shortid from "shortid";
import PropTypes from "prop-types";

export const createEventContext = eventName => {
    let handler = () => undefined;

    const subscribe = Component => props => {
        props = Object.assign({}, props);

        return React.createElement(Component, {
            ...props,
            events: Object.assign({}, props.events, {
                [eventName]: (...args) => handler(...args)
            })
        });
    };

    class EventContext extends PureComponent {
        UNSAFE_componentWillMount() {
            handler = this.props.handler;
        }

        componentWillUnmount() {
            handler = () => undefined;
        }

        render() {
            return this.props.children;
        }
    }

    return { EventContext, subscribe };
};

//TODO: This isn't much better than Pipeline, but it's better. See if we can go full React
export const createEventHandlerContext = () => {
    let handlers = {};
    const execute = (...params) => {
        Object.keys(handlers).forEach(id => {
            handlers[id](...params);
        });
    };

    class EventHandler extends PureComponent {
        static propTypes = {
            onExecute: PropTypes.func.isRequired
        }

        componentDidMount() {
            this._eventHandlerId = shortid();
            handlers[this._eventHandlerId] = this.props.onExecute;
        }

        componentWillUnmount() {
            delete handlers[this._eventHandlerId];
        }

        render = () => null;
    }

    return { EventHandler, execute };
};