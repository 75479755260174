import $ from "~/lib/elemutil";
import Waves from "~/lib/waves";
import Pipeline from "~/lib/pipeline";
import { COLLAPSE_DROPDOWNS } from "~/actions/definitions";
import MouseContext from "~/context/MouseContext";

const hideDropdowns = e => {
    let target = $(e.target);
    if(target.parent(".dropdown").length === 0) {
        Pipeline.emit({ type: COLLAPSE_DROPDOWNS });
    }
};

$(() => {
    Waves.init();
    $.on("click", e => {
        hideDropdowns(e);
        MouseContext.execute(e.target);
    });
});