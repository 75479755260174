import React, { Component } from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";
import AsyncComponent from "~/components/AsyncComponent";
import generateUid from "shortid";
import Modal from "./Modal";

let showModal = e => null;

export const showConfirmationModal = (props) =>
    showModal(props);

//TODO: react-s-alert type of deal where you can call ConfirmationModal.show(...) and it'll pop up in the document root
//That'd be super dope.
export class ConfirmationModalProvider extends AsyncComponent {
    modals = {};

    state = {
        modalsVisible: []
    }

    componentWillMount() {
        showModal = async({ onClose = x => x, ...modalProps }) => {
            let modalId = generateUid();

            await this.updateState({ modalsVisible: [...this.state.modalsVisible, { key: modalId, props: modalProps }] });
            let modal = this.modals[modalId];
            let result = await modal.async.popup();
            await this.updateState({ modalsVisible: this.state.modalsVisible.filter(x => x.key != modalId) });
            delete this.modals[modalId];
            
            return onClose(result);
        };
    }

    render() {
        let { modalsVisible } = this.state;
        return modalsVisible.reduce((modals, modal) => (
            <ConfirmationModal key={modal.key} ref={ref => this.modals[modal.key] = ref} {...modal.props} />
        ), []);
    }
}

@observer
export default class ConfirmationModal extends Component {
    id = generateUid();
    @observable overrideProps = {
        hideOkay: false,
        hideCancel: false
    };

    constructor() {
        super();

        this.okayCallback = null;
        this.cancelCallback = null;
        this.state = {
            open: false
        };

        this.body = undefined;
    }

    open(body) {
        this.body = body;

        return new Promise((resolve) => {
            this.okayCallback = () => {
                this.body = undefined;
                return resolve(true);
            };

            this.cancelCallback = () => {
                this.body = undefined;
                return resolve(false);
            };

            this.setState({ open: true }, () => this.modal.open());
        });
    }

    get async() {
        let doPopup = this.popup;
        return {
            popup: () => new Promise((resolve) => {
                doPopup(() => resolve(true), () => resolve(false));
            })
        };
    }
    
    popup = (onAccept, onCancel) => {
        this.okayCallback = onAccept;
        this.cancelCallback = onCancel;
        this.setState({ open: true }, () => this.modal.open());
    }

    close(cb = () => null) {
        this.setState({ open: false }, () => cb());
    }

    onAnswerYes = () => {
        this.setState({ open: false }, () => {
            if(this.okayCallback) {
                this.okayCallback();
                this.okayCallback = null;
                this.cancelCallback = null;
            }
        });
    }

    onAnswerNo = () => {
        this.setState({ open: false }, () => {
            if(this.cancelCallback) {
                this.cancelCallback();
                this.okayCallback = null;
                this.cancelCallback = null;
            }
        });
    }

    renderFooter() {
        let {
            hideOkay = false,
            hideCancel = false,
            okayLabel = "Okay",
            cancelLabel = "Cancel",
            okayDisabled = false,
            ExtraFooter
        } = this.props;

        return (
            <div className="row w-100 justify-content-end">
                {ExtraFooter && (
                    <ExtraFooter closeModal={this.onAnswerYes} />
                )}
                {!(hideOkay || this.overrideProps.hideOkay) && (
                    <button disabled={okayDisabled}
                        type="button"
                        className="btn btn-success waves-effect"
                        color="green" text-color="white"
                        onClick={this.onAnswerYes}>{okayLabel}</button>
                )}
                {!(hideCancel || this.overrideProps.hideCancel) && (
                    <button type="button" className="btn btn-flat waves-effect" onClick={this.onAnswerNo}>{cancelLabel}</button>
                )}
            </div>
        );
    }
    
    shouldComponentUpdate(nextProps, nextState) {
        if(this.state.open !== nextState.open) return true;
        if(this.props.children !== nextProps.children) return true;

        return !nextProps.shouldNeverUpdate;
    }

    render() {
        let { children: Child, renderChildrenWhenHidden = true, showTitle = false, ...props } = this.props;

        let children;
        if(typeof Child == "function") {
            children = (<Child key={`modal_children_${this.id}`} modalRef={this} modalProps={this.overrideProps} />);
        } else children = Child;

        return (
            <Modal key={`modal_${this.id}`} visible={this.state.open} showTitle={showTitle} hideLines={true} ref={ref => this.modal = ref} footer={this.renderFooter()} {...props}>
                {((!this.state.open && renderChildrenWhenHidden) || this.state.open) && (
                    this.body || children
                )}
            </Modal>
        );
    }
}