import React, { Component } from "react";
import except from "lodash/omit";
import isEqual from "lodash/isEqual";
import { reduxForm, Field, change as changeValue } from "redux-form";
import { Select, TextInput, TextArea, Checkbox } from "~/components/material";
import AddressFields from "~/components/form/fields/AddressFieldset";
import MonthSelector from "~/components/form/fields/MonthSelector";
import StateSelector from "~/components/form/fields/StateSelector";
import CompanyDropdown from "~/components/form/fields/CompanyDropdown";
import DivisionDropdown from "~/components/form/fields/DivisionDropdown";
import MultipleDateSelector from "~/components/form/fields/MultipleDateSelector";
import MultipleDateRangeSelector from "~/components/form/fields/MultipleDateRangeSelector";
import AnalyticsDatesSelector from "~/components/form/fields/AnalyticsDatesSelector";
import DateSelector from "~/components/form/fields/DateSelector";

import { phoneMask, zipcodeMask } from "~/lib/masks";
import {
    required as requiredValidator,
    phoneNumber,
    phoneNumberNotRequired,
    zipCode,
    zipCodeNotRequired,
    emailAddress,
    emailAddressNotRequired,
    maxLength
} from "~/lib/validation";

const HiddenInput = (props) => (
    <input type="hidden" {...props.input} {...except(props, "input", "meta", "genericChangeHandler", "innerRef")} />
);

const convertField = (field, i, disabled, ref) => {
    let {
        type,
        mask,
        editable = null,
        required = false,
        label,
        name,
        validation: validate = [],
        classes: className,
        focused,
        props: fieldProps,
        overrides = {},
        handler: genericChangeHandler,
        useNewBehavior,
        ...otherFieldProps
    } = field;

    let props = {
        required, "show-required": "true",
        key: i, label, name, validate,
        className, focused, props: fieldProps,
        disabled, innerRef: ref,
        overrides, genericChangeHandler,
        ...otherFieldProps
    };

    if(!Array.isArray(props.validate)) {
        props.validate = [];
    }

    if(mask) {
        Object.assign(props, mask);
    }

    if(editable === false || field.disabled) {
        props.disabled = true;
    }

    if(props.required) {
        props.validate.push(requiredValidator);
    }

    if(field.maxLength) {
        props.validate.push(maxLength(field.maxLength));
    }

    if(props.overrides && props.overrides.component) {
        return (
            <div className="md-form" key={i}>
                <Field component={props.overrides.component} {...props} />
            </div>
        );
    }

    if(!type || type === "text") {
        props.component = TextInput;
    } else if (type === "phone") {
        props.component = TextInput;
        Object.assign(props, phoneMask);
        
        if(props.required) {
            props.validate.push(phoneNumber);
        } else props.validate.push(phoneNumberNotRequired);
    } else if(type === "zipcode") {
        props.component = TextInput;
        Object.assign(props, zipcodeMask);
        
        if(props.required) {
            props.validate.push(zipCode);
        } else props.validate.push(zipCodeNotRequired);
    } else if(type === "email") {
        props.component = TextInput;

        if(props.required) {
            props.validate.push(emailAddress);
        } else props.validate.push(emailAddressNotRequired);
    } else if(type === "password") {
        props.component = TextInput;
        props.type = "password";
    } else if(type === "textarea") {
        props.component = TextArea;
    } else if(type === "hidden") {
        props.component = HiddenInput;
    } else if(type === "address") {
        return (
            <AddressFields {...props} />
        );
    } else if(type === "month") {
        props.component = MonthSelector;
    } else if(type === "select") {
        if(field.select === "state") {
            props.component = StateSelector;
        } else if(field.select === "company") {
            props.component = CompanyDropdown;
            props.useNewBehavior = useNewBehavior;
        } else if(field.select === "division") {
            props.component = DivisionDropdown;
        }else {
            props.component = Select;
            
            if(field.async) {
                props.async = true;
                props.loadOptions = field.fetchData;
            } else {
                props.data = field.data.map(o => ({ label: o.name, value: o.val }));
            }
        }
    } else if(type === "check" || type === "checkbox") {
        return (
            <div className="md-form" key={i}>
                <Field component={Checkbox} {...props} />
            </div>
        );
    } else if(type === "group") {
        let defaultValues = [];

        let out;
        if(props.columnation) {
            out = (
                <div key={i} className="mt-4 mb-5">
                    <p>{field.label}</p>
                    <div className="row">
                        {field.children.map((c,j) => {
                            if(c.defaultValue) {
                                defaultValues.push({ name: c.name, value: c.defaultValue });
                            }
    
                            return (
                                <div key={c.name} className={props.columnation}>{convertField(c,j,disabled)}</div>
                            );
                        })}
                    </div>
                </div>
            );
        } else {
            out = (
                <div key={i} className="mt-4 mb-5">
                    <p>{field.label}</p>
                    <ul style={{listStyle: "none"}}>
                        {field.children.map((c,j) => {
                            if(c.defaultValue) {
                                defaultValues.push({ name: c.name, value: c.defaultValue });
                            }
    
                            return (
                                <li key={c.name}>{convertField(c,j,disabled)}</li>
                            );
                        })}
                    </ul>
                </div>
            );
        }
        
        if(defaultValues.length)
            return { ____values: defaultValues, out };

        return out;
    } else if(type === "row") {
        return (
            <div key={i} className="form-row">
                {field.children.map((c,j) => (
                    <div key={j} className="col">
                        {convertField(c,j)}
                    </div>
                ))}
            </div>
        );
    } else if(type === "date") {
        props.component = DateSelector;
        props.useNewBehavior = useNewBehavior;
    } else if(type === "dates") {
        props.component = MultipleDateSelector;
        props.useNewBehavior = useNewBehavior;
    } else if(type === "dateRanges") {
        props.component = MultipleDateRangeSelector;
        props.useNewBehavior = useNewBehavior;
    } else if(type === "divisions") {
        props.component = DivisionDropdown;
        props.useMultiOutput = true;
        props.useNewBehavior = useNewBehavior;
    } else if(type === "analyticsDates") {
        props.component = AnalyticsDatesSelector;
        props.useNewBehavior = useNewBehavior;
    } else if(typeof type !== "string") { //Pass the component in directly
        props.component = type;
    } else return null;

    return (<Field {...props} />);
};

export const buildForm = ({ fields, defaults = {}, handlers = {}, disabled = false, overrides = {}, useNewBehavior, overridingOnChange }) => {
    let defaultValues = {};
    let instances = {};
    let setFirst = false;

    let fieldset = fields.map((field,i) => {
        field.useNewBehavior = useNewBehavior;
        if(field.defaultValue) defaultValues[field.name] = field.defaultValue;
        if(defaults[field.name]) defaultValues[field.name] = defaults[field.name];
        
        if(!setFirst && field.type !== "hidden") {
            setFirst = true;
            field.focused = true;
        }

        if(handlers[field.name]) {
            field.handler = handlers[field.name];
        }

        if(overrides[field.name]) {
            field.overrides = overrides[field.name];
        }

        if(overridingOnChange) {
            field.onChange = overridingOnChange;
        }
        
        let converted = convertField(field, field.name, disabled, ref => instances[field.name] = ref);

        if(converted.____values) {
            converted.____values.forEach(x => defaultValues[x.name] = x.value);
            return converted.out;
        }
        
        return converted;
    });

    return {
        defaultValues,
        fieldset,
        instances
    };
};

export const generateForm = ({ useNewBehavior, data = {}, name, fields, onSubmit, onValidate = () => null, onUpdate, handlers = {}, defaults = {}, disabled = false, overrides = {}, innerRef = e => null }) => {
    let { defaultValues, fieldset: mappedFields, instances } = buildForm({ fields, defaults, handlers, disabled, overrides, useNewBehavior });
    innerRef(instances);

    class FormRenderer extends Component {
        shouldComponentUpdate(nextProps) {
            return !isEqual(nextProps.children, this.props.children);
        }

        render() {
            return (
                <form onSubmit={!!!disabled ? this.props.handleSubmit(onSubmit) : undefined}>
                    {mappedFields}
                    {this.props.children}
                </form>
            );
        }
    }
    
    return reduxForm({
        initialValues: Object.assign({}, defaultValues, data),
        validate: onValidate,
        form: name,
        onChange: onUpdate
    })(FormRenderer);
};

class NewFormBody extends Component {
    constructor(props) {
        super(props);

        if(props.innerRef) {
            props.innerRef(this);
        }
    }

    setFieldValue = (field, value) => this.props.dispatch(changeValue(this.props.newBehaviorProps.name, field, value));

    render() {
        let { disabled, onSubmit } = this.props.newBehaviorProps;
        let { fieldset } = buildForm(this.props.newBehaviorProps);
        
        return (
            <form onSubmit={!!!disabled ? this.props.handleSubmit(onSubmit) : undefined}>
                {fieldset}
                {this.props.children}
            </form>
        );
    }
}

export default class GenericForm extends Component {
    constructor(props) {
        super(props);

        if(props.neverChanges) {
            this.Form = generateForm(props);
        } else if(props.useNewBehavior) {
            let { name, onValidate = () => null, onUpdate, defaultValues = {}, data } = props;

            this.Form = reduxForm({
                initialValues: Object.assign({}, defaultValues, data),
                validate: onValidate,
                form: name,
                onChange: onUpdate
            })(NewFormBody);
        }

        if(props.formRef) {
            props.formRef(this);
        }
    }

    regenerateForm = cb => {
        this.Form = generateForm(this.props);
        this.setState({ _: +new Date() }, cb);
    }

    render() {
        let { children, innerRef, ...otherProps } = this.props;
        let Form;

        if(otherProps.useNewBehavior) {
            Form = this.Form;
        } else {
            Form = (!!this.Form ? this.Form : generateForm(this.props));
        }

        return (
            <Form
                children={children}
                newBehaviorProps={otherProps}
                innerRef={innerRef} />
        );
    }
}