import React from "react";

import Icon from "@material-ui/core/Icon";
import Avatar from "@material-ui/core/Avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const info = {
    assistant: {
        title: "About CFOfxn",
        text: "Welcome to CFOfxn.com. To learn more on a specific topic, select a category from the CFO assistant in the app bar of any page. For further assistance, contact a CFOfxn administrator."
    },
    users: {
        title: "Adding Users",
        text: "Go to the menu under your name and select User Administration. Click Add User and complete the requested form with the users information and also assign access to Companies and Divisions for which you are authorized and desire to do so. CFOfxn.com will notify the user via email that an account has been established."
    },
    companies: {
        title: "Adding Companies",
        text: "Go to the menu under your name and select Companies Administration. Click Add Company and complete the requested form. After creating a Company you may Import balance sheets and income statements into CFOfxn.com, and perform or view budgeting, forecasting and analytic tasks as well as create financial notes."
    },
    divisions: {
        title: "Adding Divisions",
        note: "While CFOfxn.com uses the term Divisions, from a business standpoint this can represent subsidiaries, related entities, classes or divisions.",
        text: "Go to the menu under your name and select Companies Administration.  Go to an existing Company and create a new Division. After creating a Company you may Import balance sheets and income statements into CFOfxn.com, and perform or view budgeting, forecasting and analytic tasks as well as create financial notes."
    },
    selectingCompany: {
        title: "Selecting a Company",
        text: "Go to the menu under your name and choose Select Company. Choose the Company you would like to work with."
    },
    balanceSheet: {
        title: "Balance Sheet",
        text: "Select the tile that represents your balance sheet (it is oftentimes called Balance Sheet, Statement of Position, Statement of Net Worth, etc). Within this tab you can view asset, liability and equity information for one Company, a Division or consolidated Company and Divisions."
    },
    incomeStatement: {
        title: "Income Statement",
        text: "Select the tile that represents your income statement (it is oftentimes called Income Statement, Statement of Activities, Statement of Changes in Activities, Profit and Loss, etc). Within this tab you can view revenue, cost of sales and expense information for one Company, a Division or consolidated Company and Divisions."
    },
    budget: {
        title: "Budgeting",
        text: "Select the tile called Budget to prepare your Budget by starting from scratch and entering amounts, entering unit price times unit volume or using the debt or depreciation calculation tools or by copying from an existing income statement, budget and forecast and applying percentage changes."
    },
    forecast: {
        title: "Forecasting",
        text: "Select the tile called Forecast to prepare your Forecast by starting from scratch and entering amounts, entering unit price times unit volume or using the debt or depreciation calculation tools or by copying from an existing income statement, budget and forecast and applying percentage changes."
    },
    cashFlow: {
        title: "Cash Flow",
        text: "Select the tile called Cash Flow to either record historical operating, investing and financing cash flows or forecast future cash flows by modeling future periods."
    },
    import: {
        title: "Import",
        text: () => (
            <>
            <p>
                Select the Import tile to import balance sheet and/or income statement information for Companies and Divisions directly from QuickBooks by syncing your QuickBooks file location in Companies Administration.  Alternatively, import an Excel balance sheet and income statement.
            </p>
            <p>
                The  format for the Excel file can be found in the Account Import Tile by selecting a Company, Division, Balance Sheet or Income Statement, Edit Mapping and then selecting the Help menu.
            </p>
            <p>
                After importing the information you will need to map your accounts so the system knows how to calculate certain totals, EBITDA, analytics and for efficiency purposes.
            </p>
            </>
        )
    },
    notifications: {
        title: "Notifications",
        text: "Certain processes in CFOfxn can produce notifications, some of which allow or require user interaction. Click on a notification's actions or click on a notification from the notification list to interact with it. Mark notifications as read or clear all notifications using the buttons available in the notifications list. You should clear all notifications when they are no longer needed."
    },
    notes: {
        title: "Financial Notes",
        text: "Select the Financial Notes tab in order to document important information about your organization."
    }
};

export const actions = [
    {
        name: "assistant",
        label: "About CFOfxn",
        icon: <Icon>help</Icon>,
        onClick: cb => e => cb(info["assistant"])
    },
    {
        name: "users",
        label: "Adding Users",
        icon: <Icon>person_add</Icon>,
        onClick: cb => e => cb(info["users"])
    },
    {
        name: "companies",
        label: "Adding Companies",
        icon: <Icon>domain</Icon>,
        onClick: cb => e => cb(info["companies"])
    },
    {
        name: "divisions",
        label: "Adding Divisions",
        icon: <Icon>store_mall_directory</Icon>,
        onClick: cb => e => cb(info["divisions"])
    },
    {
        name: "selectingCompany",
        label: "Selecting a Company",
        icon: <Icon>check</Icon>,
        onClick: cb => e => cb(info["selectingCompany"])
    },
    {
        name: "balanceSheet",
        label: "Balance Sheet",
        icon: <FontAwesomeIcon icon="balance-scale" style={{marginLeft: "-2px", marginRight: "-3px"}} />,
        onClick: cb => e => cb(info["balanceSheet"])
    },
    {
        name: "incomeStatement",
        label: "Income Statement",
        icon: <FontAwesomeIcon icon={["far", "money-bill-alt"]} style={{marginLeft: "-2px", marginRight: "-3px"}} />,
        onClick: cb => e => cb(info["incomeStatement"])
    },
    {
        name: "budget",
        label: "Budgeting",
        icon: <FontAwesomeIcon icon="calculator" />,
        onClick: cb => e => cb(info["budget"])
    },
    {
        name: "forecast",
        label: "Forecasting",
        icon: <Avatar src="/res/forecast-white.svg" />,
        onClick: cb => e => cb(info["forecast"])
    },
    {
        name: "cashFlow",
        label: "Cash Flow",
        icon: <FontAwesomeIcon icon={["far", "money-check-alt"]} style={{marginLeft: "-2px", marginRight: "-3px"}} />,
        onClick: cb => e => cb(info["cashFlow"])
    },
    {
        name: "import",
        label: "Import",
        icon: <Icon>autorenew</Icon>,
        onClick: cb => e => cb(info["import"])
    },
    {
        name: "notifications",
        label: "Notifications",
        icon: <Icon>notifications</Icon>,
        onClick: cb => e => cb(info["notifications"])
    },
    {
        name: "notes",
        label: "Financial Notes",
        icon: <FontAwesomeIcon icon="sticky-note" style={{marginLeft: "-2px", marginRight: "-3px"}} />,
        onClick: cb => e => cb(info["notes"])
    }
];