import Notifications from "~/state/NotificationsStore";

let exports = {};

exports["error:unauthorized"] = (server, data) => console.error(data);
exports["error:validation"] = (server, data) => console.error(data);
exports["error:generic"] = (server, data) => console.error(data);

exports["auth:success"] = (server, data) => console.log("Authenticated with CFOfxn!");

exports["alert:notification"] = (server, data) => Notifications.createNotification(data);
exports["notifications:list"] = (server, data) => Notifications.replaceNotifications(data);
exports["notifications:update"] = (server, data) => Notifications.modifyNotification(data);

export default exports;