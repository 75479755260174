import React, { Component } from "react";
import { BrowserRouter, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AuthenticatedRoute from "~/components/AuthenticatedRoute";
import TemplatedRoute from "~/components/TemplatedRoute";

import PasswordRoutes from "./PasswordRoutes";
import AdminRoutes from "./AdminRoutes";

import { NotFound } from "~/views/Errors";
import Index from "~/views/Index";
import Login from "~/views/housekeeping/Login";
import EmailConfirmation from "~/views/housekeeping/EmailConfirmation";

import { UserConfirmationContext } from "~/app";

export const RedirectProvider = withRouter(class RedirectProvider extends Component {
    componentDidMount() {
        let { history } = this.props;
        global.redirect = (url) => history.push(url);
        global.goBack = () => history.goBack();
    }

    render = () => null;
});

class Routes extends Component {
    render() {
        return (
            <UserConfirmationContext.Consumer>
                {UserConfirmation => (
                    <BrowserRouter getUserConfirmation={UserConfirmation.getUserConfirmation} >
                        <Switch>
                            <AuthenticatedRoute exact={false} path="/home" component={Index} />
                            <TemplatedRoute exact={false} path="/login" component={Login} />
                            <TemplatedRoute exact path="/confirmEmail" component={EmailConfirmation} />
        
                            <TemplatedRoute exact={false} path="/password" component={PasswordRoutes} />
                            <AuthenticatedRoute exact={false} path="/admin" component={AdminRoutes} />
        
                            <TemplatedRoute exact path="/" render={() => (
                                <Redirect to={this.props.authenticated ? "/home" : "/login"} />
                            )} />
                            <TemplatedRoute component={NotFound} />
                        </Switch>
                    </BrowserRouter>
                )}
            </UserConfirmationContext.Consumer>
        );
    }
}

const stateMap = ({ auth: { token } }) => ({
    authenticated: token && token.length
});

export default connect(stateMap)(Routes);