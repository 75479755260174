import { API } from "./index";
import { get, put, post, remove } from "../net";
import Permissions from "~/services/PermissionsDictionary";

import { objectKeysToLowerCase } from "~/lib/validation";

import {
    UPDATE_USER, UPDATE_USER_ERROR,
    CREATE_USER, CREATE_USER_ERROR,
    FETCH_USERS, FETCH_USERS_ERROR,
    FETCH_ACCOUNT, ACCOUNT_ERROR,
    PASSWORD_RESET_SENT, PASSWORD_RESET_ERROR, PASSWORD_RESET,
    AUTHENTICATED, AUTHENTICATION_ERROR
} from "~/actions/definitions";

class UserService {
    async updateUser(data) {
        let request = put({
            endpoint: `portalusers/${data.PortalUserId}`,
            data: objectKeysToLowerCase(data),
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error, UPDATE_USER, UPDATE_USER_ERROR);
        } catch(ex) {
            return API.handleResponse(request, null, ex, UPDATE_USER, UPDATE_USER_ERROR);
        }
    }

    async createUser(data) {
        let request = post({
            endpoint: "portalusers",
            data: objectKeysToLowerCase(data),
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error, CREATE_USER, CREATE_USER_ERROR);
        } catch(ex) {
            return API.handleResponse(request, null, ex, CREATE_USER, CREATE_USER_ERROR);
        }
    }
    
    async getAllUsers() {
        let request = get({
            endpoint: "portalusers",
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error, FETCH_USERS, FETCH_USERS_ERROR);
        } catch(ex) {
            return API.handleResponse(request, null, ex, FETCH_USERS, FETCH_USERS_ERROR);
        }
    }

    async getUserById(id, ignoreDeleted = false) {
        let request = get({
            endpoint: `portalusers/${id}?ignoreDeleted=${ignoreDeleted?1:0}`,
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async getAccountDetails() {
        let request = get({
            endpoint: "portaluser",
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;

            if(error)
                return API.handleResponse(request, payload, error, FETCH_ACCOUNT, ACCOUNT_ERROR);

            
            Permissions.update(payload.permissions || []);
            return API.handleResponse(request, payload, error, FETCH_ACCOUNT, ACCOUNT_ERROR);
        } catch(ex) {
            return API.handleResponse(request, null, ex, FETCH_ACCOUNT, ACCOUNT_ERROR);
        }
    }

    async getAssignedCompanies(userId) {
        let request = get({
            endpoint: `portalusers/${userId}/companies`,
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async assignCompanyToUser(userId, companyId) {
        let request = post({
            endpoint: `portalusers/${userId}/companies/${companyId}`,
            data: {},
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async dissociateCompanyFromUser(userId, companyId) {
        let request = remove({
            endpoint: `portalusers/${userId}/companies/${companyId}`,
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async updateProfile(data) {
        let request = post({
            endpoint: "portaluser",
            data: objectKeysToLowerCase(data),
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error, UPDATE_USER, UPDATE_USER_ERROR);
        } catch(ex) {
            return API.handleResponse(request, null, ex, UPDATE_USER, UPDATE_USER_ERROR);
        }
    }

    async forgotPassword(email) {
        let request = post({
            endpoint: "security/resetpassword",
            data: { email },
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error, PASSWORD_RESET_SENT, PASSWORD_RESET_ERROR);
        } catch(ex) {
            return API.handleResponse(request, null, ex, PASSWORD_RESET_SENT, PASSWORD_RESET_ERROR);
        }
    }

    async resetPassword(token, password) {
        let request = post({
            endpoint: "security/passwordreset",
            watcher: this.watcher,
            data: {
                resettoken: token,
                password
            }
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error, PASSWORD_RESET, PASSWORD_RESET_ERROR);
        } catch(ex) {
            return API.handleResponse(request, null, ex, PASSWORD_RESET, PASSWORD_RESET_ERROR);
        }
    }

    async saveUserPermissions(userId, permissions, otherPermissions) {
        permissions = permissions.filter(x => x && x.roleid > 0); //Filter out nulls and "No Access"
        let request = post({
            endpoint: `portalusers/${userId}/permissions`,
            watcher: this.watcher,
            data: { permissions, otherPermissions }
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async login(alias, email, password) {
        let request = post({
            endpoint: "security/login",
            data: {alias, email, password},
            auth: false
        });

        try {
            let { error, payload } = await request;

            if(error)
                return API.handleResponse(request, payload, error,  AUTHENTICATED, AUTHENTICATION_ERROR);

            Permissions.update(payload.permissions || []);
            return API.handleResponse(request, payload, error,  AUTHENTICATED, AUTHENTICATION_ERROR);
        } catch(ex) {
            return API.handleResponse(request, null, ex, AUTHENTICATED, AUTHENTICATION_ERROR);
        }
    }
}

const inst = new UserService();
export default inst;