import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Portal from "~/components/Portal";

export default class ScrollUpFob extends React.Component {
    render() {
        let props = {
            type: "button",
            affixed: "bottom-right",
            className: ["btn-floating scroll-top-fob", this.props.className].join(" "),
            color: "white",
            "text-color": "black",
            onClick: this.props.onClick
        };

        let contents = (
            <button {...props}>
                <FontAwesomeIcon icon="chevron-up" />
            </button>
        );

        if(this.props.portal) {
            return (
                <Portal>{contents}</Portal>
            );
        } else return contents;
    }
}