import { API } from "./index";
import { get, post, put, remove } from "../net";
import qs from "querystring";

class AnalyticsLinksService {
    async getAnalyticsLinks(userId) {
        let request = get({
            endpoint:  `analyticsLinks?${qs.stringify({ userId })}`,
            watcher: this.watcher
        });
        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.error(request, ex);
        }
    }

    async deleteAnalyticsLink(analyticsLinkId) {
        let request = remove({
            endpoint:  `analyticsLinks/${analyticsLinkId}`,
            watcher: this.watcher
        });
        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.error(request, ex);
        }
    }

    async createAnalyticsLink(data) {
        let request = post({
            endpoint:  `addAnalyticsLink`,
            data,
            watcher: this.watcher
        });
        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.error(request, ex);
        }
    }

    async updateAnalyticsLink(data) {
        let request = put({
            endpoint:  `analyticsLink/update`,
            data,
            watcher: this.watcher
        });
        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.error(request, ex);
        }
    }
}

export default new AnalyticsLinksService();