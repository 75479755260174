import { API } from "./index";
import { post } from "../net";

class StatementService {
    async generateReport(data) {
        let request = post({ endpoint: "reports/generateReport", data, watcher: this.watcher });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async generateSystemReport(data) {
        let request = post({ endpoint: "reports/generateSystemReport", data: { reports: data }, watcher: this.watcher });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    safe = {
        generateReport: async(data) => {
            try {
                let req = await this.generateReport(data);
                if(req.error)
                    return { active: [], inactive: [] };

                if(req.payload && req.payload.active && req.payload.inactive)
                    return req.payload;

                return { active: [], inactive: [] };
            } catch(ex) {
                return { active: [], inactive: [] };
            }
        },

        generateSystemReport: async(data) => {
            try {
                let req = await this.generateSystemReport(data);
                if(req.error)
                    return {};

                return req.payload;
            } catch(ex) {
                return {};
            }
        }
    }
}

const inst = new StatementService();
export default inst;