import sortBy from "lodash/sortBy";
import uniq from "lodash/uniq";
import get from "lodash/get";

import {
    insertIntoHierarchy,
    generateTree
} from "./SummaryReportParser";

export const getCompanyIds = values => {
    if(values.parameters && Array.isArray(values.parameters)) {
        return sortBy(get(values.parameters.find(x => x.name === "companyIds"), "val", []).map(x => Number(x)));
    } else {
        return sortBy(get(values, "divisions", []).map(x => Number(x)));
    }
};

export const reduce = (report, ids, Mode, Raw) => {
    return report.reduce((Report, row, i) => {
        //Level-based hierarchy
        let level = Report.Levels[row.Level] || [];
        Report.Levels[row.Level] = [...level, row];

        //ParentId-Child-based hierarchy
        if(!row.ParentAccountId) {
            Report.Hierarchy[row.StandardAccountId] = row;
        } else {
            Report.Hierarchy = insertIntoHierarchy(Report.Hierarchy, row);
        }

        return Report;
    }, { Levels: [], Hierarchy: {}, Raw, RawReport: report, Companies: ids, Mode });
};

const getStandardAccounts = (input) => {
    let out = { companies: [], accounts: [] };
    for(let row of input) {
        if(!out.accounts.includes(row.StandardAccountId) && !row.Consolidated) {
            out.accounts.push(row.StandardAccountId);
        }
        
        out.companies.push(row.CompanyId);
    }

    out.companies = sortBy(uniq(out.companies));

    return out;
};

//TODO: Optimize this even more
export const parse = (report, mode, values) => {
    let { accounts } = getStandardAccounts(report);
    let companies = getCompanyIds(values);
    
    let merged = [];
    for(let id of accounts) {
        let rows = sortBy(report.filter(x => x.StandardAccountId == id), "CompanyId");
        
        let values = companies.map(id => rows.filter(x => x.CompanyId == id && !x.Consolidated).map(x => x.Amount || 0).reduce((a,b) => a + b, 0));
        /*
        -FDS-234: 
           >Changed calculation for consolidated column
           >from client, it should be sum of the other columns, both vertically and horizontally
        */
        //let consolidated = rows.filter(x  => x.Consolidated).slice(0, 1).map(x => x.Amount || 0);
        //values.push(consolidated.reduce((a,b) => a + b, 0));
        let consolidated = values.reduce((a,b) => a + b,  0);
        values.push(consolidated);

        let row = rows.find(x => !x.Consolidated);

        merged.push({
            StandardAccountId: row.StandardAccountId,
            Level: row.Level,
            Name: row.Name,
            ParentAccountId: row.ParentAccountId,
            EBITDA: rows.reduce((ebitda, { EBITDA }) => ebitda || EBITDA, false),
            Amounts: values,
            SortPriority: row.SortPriority
        });
    }
    
    return reduce(merged, companies, mode, report);
};

export const convertToTree = (report, mode) => {
    return {
        parsed: generateTree(report, mode),
        companies: report.Companies
    };
};