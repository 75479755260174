import React, { Component } from "react";
import except from "lodash/omit";
import $ from "~/lib/elemutil";

export class ControlledCheckbox extends Component {
    render() {
        return (
            <React.Fragment>
                <input ref={ref => this.check = ref} type="checkbox" {...this.props} />
                <label onClick={this.props.onChange} />
            </React.Fragment>
        );
    }
}

export default class Checkbox extends Component {
    constructor(props) {
        super(props);
        this.state = { checked: (!!props.checked || !!props.defaultChecked) ? true : false };

        if(props.innerRef) {
            props.innerRef(this);
        }
    }

    componentDidUpdate(props) {
        if(props.checked !== this.props.checked) {
            this.setState({ checked: this.props.checked });
        }
    }

    onCheckClick(e) {
        let elem = $(e.target)._siblings("input[type=\"checkbox\"]")[0];
        if(!elem.disabled) {
            e.preventDefault();
            this.updateCheckedState();
        }
    }

    updateCheckedState(checked = !this.state.checked) {
        if(this.props.input) { this.props.input.onChange(checked); }
        
        if(this.props.fixed) {
            if(this.props.genericChangeHandler) {
                this.props.genericChangeHandler(this.state.checked);
            }

            if(this.props.onChange) {
                this.props.onChange(!this.props.checked);
            }
        } else {
            this.setState({ checked: checked }, () => {
                if(this.props.genericChangeHandler) {
                    this.props.genericChangeHandler(this.state.checked);
                }
    
                if(this.props.onChange) {
                    this.props.onChange(checked);
                }
            });
        }
    }

    get checked() {
        return this.state.checked;
    }

    set checked(checked) {
        this.setState({ checked });
    }

    render() {
        let { label, disabled = false, input, fixed, overrides = {}, formStyle = {}, ...otherProps } = this.props;

        let props = {
            checked: !!this.state.checked
        };

        if(input) {
            props.checked = !!input.value;
        }

        if(fixed) {
            props.checked = this.props.checked || false;
        }

        if(disabled) {
            props.disabled = true;
            props.readOnly = true;
            props.onChange = () => null;
        } else {
            props.onChange = () => this.updateCheckedState();
        }

        props = {
            ...props,
            ...overrides
        };

        let main = (
            <React.Fragment>
                <input ref={ref => this.check = ref} type="checkbox" {...except(otherProps, "checked", "noWrapper", "genericChangeHandler", "defaultChecked", "innerRef")} {...props} />
                <label onClick={e => this.onCheckClick(e)}>
                    {label}{(this.props.required && this.props["show-required"]) && (<span text-color="red">&nbsp;*</span>)}
                </label>
            </React.Fragment>
        );

        if(!this.props.noWrapper) {
            return (
                <div className="form-group" style={formStyle}>{main}</div>
            );
        }
        
        return main;
    }
}