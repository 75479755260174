import React from "react";

import { action, observable, computed, toJS } from "mobx";
import { showConfirmationModal } from "~/components/material/Modal/ConfirmationModal";
import Alert from "~/services/ui/alerts";

import ModalBody from "./components/ScheduleEditorModal";
import AutoImportFooter from "./components/AutoImportFooter";

import moment from "moment";

import * as dictionary from "./components/ScheduleEditorModal/dictionaries";

import SchedulingService from "~/services/api/scheduling";

const pageSize = 20;

const DateConvert = {
    UTCToLocal: date => moment.utc(date).local(),
    localToUTC: date => moment(date).utc()
};

class CommonState {
    @observable fetchPrevious = false;
    @observable schedules = [];
    @observable page = 0;
    @observable totalPages = 0;

    @computed get currentPage() {
        return this.schedules.slice(
            this.page * pageSize, Math.min(pageSize + this.page * pageSize, this.totalSize)
        );
    }

    @action define = (schedules) => {
        this.schedules = schedules;
        this.totalPages = Math.floor(schedules.length / pageSize);
        this.totalSize = schedules.length;
    }

    @action reset = () => {
        this.schedules = [];
        this.page = 0;
        this.totalPages = 0;
        this.totalSize = 0;
    }

    @action nextPage = () => {
        this.page = Math.min(this.page + 1, this.totalPages);
    }

    @action previousPage = () => {
        this.page = Math.max(this.page - 1, 0);
    }

    @action jumpToPage = page => {
        this.page = Math.min(this.totalPages, Math.max(0, page));
    }

    @action toggleFetchPrevious = () => {
        this.fetchPrevious = !this.fetchPrevious;
    }

    @action deleteSchedule = async(uid, cb = e => null) => {
        await SchedulingService.deleteSchedule(uid);
        await this.loadSchedulesFromServer(); //TODO: Very inefficient for large datasets
        // this.schedules.replace(this.schedules.filter(x => x.uid != uid)); 
        return cb();
    }

    @action loadSchedulesFromServer = async() => {
        try {
            let { error, payload } = await SchedulingService.getSchedules();

            if(error) {
                console.error(error);
                return Alert.showGenericError();
            }
            
            this.define(payload.map(({ uid, name, companyName, date, fetchPrevious, author, ...details }) => ({
                uid,
                name,
                fetchPrevious,
                time: DateConvert.UTCToLocal(details.time).format("hh:mm A"),
                target: companyName,
                date: dictionary.periods.find(x => x.value == details.period).label,
                statementTypes: details.statementTypes,
                details: {
                    ...details,
                    time: DateConvert.UTCToLocal(details.time),
                    fetchPrevious
                }
            })));
        } catch(ex) {
            console.error(ex);
            return Alert.showGenericError();
        }
    }

    @action onCreateSchedule = async() => {
        let data = null;
        let ok = await showConfirmationModal({
            className: "modal-lg",
            title: "Schedule Editor",
            showTitle: true,
            okayLabel: "Save",
            ExtraFooter: AutoImportFooter,
            children: props => (
                <ModalBody {...props} setResult={x => data = x} />
            )
        });

        if(ok) {
            try {
                await SchedulingService.addSchedule({ ...data.details, time: DateConvert.localToUTC(data.details.time).format(), fetchPrevious: this.fetchPrevious });
                await this.loadSchedulesFromServer();
            } catch(ex) {
                console.error(ex);
                Alert.showGenericError();
            }
        }

        this.fetchPrevious = false;
    }

    @action onEditSchedule = async uid => {
        let row = this.schedules.find(x => x.uid == uid);
        this.fetchPrevious = row.fetchPrevious;

        let delet = cb => this.deleteSchedule(uid, cb);

        let data = null;
        let ok = await showConfirmationModal({
            className: "modal-lg",
            title: "Schedule Editor",
            showTitle: true,
            okayLabel: "Save",
            ExtraFooter: ({ closeModal }) => (
                <React.Fragment>
                    <AutoImportFooter />
                    <button type="button" className="btn waves-effect" color="red" text-color="white"
                        onClick={() => delet(closeModal) }>Delete</button>
                </React.Fragment>
            ),
            children: props => (
                <ModalBody {...props} initialData={toJS(row)} setResult={x => data = x} />
            )
        });

        if(ok) {
            try {
                await SchedulingService.updateSchedule(uid, { ...data.details, time: DateConvert.localToUTC(data.details.time).format(), fetchPrevious: this.fetchPrevious });
                await this.loadSchedulesFromServer();
            } catch(ex) {
                console.error(ex);
                Alert.showGenericError();
            }
        }

        this.fetchPrevious = false;
    }

    getSchedules = () => toJS(this.schedules);
}

const state = new CommonState();
global.SchedulingState = state;

export default state;