import React from "react";
import Loadable from "react-loadable";
import { Switch, Route } from "react-router-dom";
import { NotFound } from "~/views/Errors";
import LoadingSpinner from "~/components/LoadingSpinner";

const ForgotPassword = Loadable({
    loader: () => import("~/views/housekeeping/ForgotPassword"),
    loading: LoadingSpinner
});

const ResetPassword = Loadable({
    loader: () => import("~/views/housekeeping/ResetPassword"),
    loading: LoadingSpinner
});

export default ({ match }) => (
    <Switch>
        <Route path={`${match.url}/forgot`} component={ForgotPassword} />
        <Route path={`${match.url}/reset/:token?`} component={ResetPassword} />
        <Route component={NotFound} />
    </Switch>
);