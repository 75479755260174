import React, { Component } from "react";
import MonthSelector from "./MonthSelector";
import YearSelector from "./YearSelector";
import get from "lodash/get";
import isEqual from "lodash/isEqual";

import MetaService from "~/services/api/meta";

export default class DateSelector extends Component {
    state = {
        selectedYear: get(this.propsSelected, "year", null),
        selectedMonth: get(this.propsSelected, "month", null)
    }

    getSelectedFromProps = ({ input, value } = this.props) => {
        let newInput = get(input, "value", value);

        if(newInput == "" || (Array.isArray(newInput) && !newInput.length))
            newInput = { year: null, month: null };

        if(Array.isArray(newInput)) {
            newInput = newInput[0];
        }

        if(newInput == "" || (Array.isArray(newInput) && !newInput.length) || !newInput)
            newInput = { year: null, month: null };

        if(!newInput.year)
            newInput.month = null;

        return newInput;
    }

    get propsSelected() {
        let { input, value } = this.props;
        let newInput = get(input, "value", value);

        if(newInput == "" || (Array.isArray(newInput) && !newInput.length))
            newInput = { year: null, month: null };

        if(Array.isArray(newInput)) {
            newInput = newInput[0];
        }

        if(newInput == "" || (Array.isArray(newInput) && !newInput.length) || !newInput)
            newInput = { year: null, month: null };

        if(!newInput.year)
            newInput.month = null;

        return newInput;
    }

    updateSelectionsFromProps() {
        let val = this.propsSelected;

        console.log("Updating from props");

        this.setState({
            selectedYear: val.year,
            selectedMonth: val.month
        }, this.updateParent);
    }

    updateParent = () => {
        let { input, onChange = e => null } = this.props;
        get(input, "onChange", onChange)({ year: this.state.selectedYear, month: this.state.selectedMonth });
    }

    onChangeYear = option => this.setState({ selectedYear: get(option, "value"), selectedMonth: null }, this.updateMonthsWithData);
    onChangeMonth = option => this.setState({ selectedMonth: get(option, "value") }, this.updateParent);

    updateYearsWithData = async(newCompany = false) => {
        if(this.props.ignoreFilters) {
            this.setState({ yearsWithData: null });
        } else if(this.props.autoUpdateSelectionFilters) {
            let yearsWithData = await (
                this.props.statementType ? MetaService.safe.getYearsWithData(this.props.selectedCompanies, this.props.statementType) : this.props.fetchYears()
            );

            let newState = { yearsWithData };

            let selectedYear = this.state.selectedYear;
            let selectedMonth = this.state.selectedMonth;

            let latest;
            if(this.props.statementType && (!this.getSelectedFromProps(this.props).month) || newCompany) {
                latest = await MetaService.safe.getLatestDateWithDataForCompanies(this.props.statementType, this.props.selectedCompanies);
                newState.selectedYear = latest.year;
                newState.selectedMonth = latest.month;
            } else if(yearsWithData && !yearsWithData.includes(selectedYear) && selectedYear) {
                newState.selectedYear = null;
                newState.selectedMonth = null;
                selectedYear = null;
                selectedMonth = null;
            }

            this.setState(newState, () => {
                let selected = latest || this.propsSelected;

                if(selectedMonth != selected.month || selectedYear != selected.year) {
                    this.setState({
                        selectedYear: selected.year,
                        selectedMonth: selected.month
                    }, () => selected.month && this.updateMonthsWithData());
                } else if(selectedYear) {
                    this.updateMonthsWithData();
                }
            });
        }
    }

    updateMonthsWithData = async() => {
        let {
            ignoreFilters,
            showMonth = true,
            autoUpdateSelectionFilters,
            statementType,
            selectedCompanies,
            fetchMonths
        } = this.props;

        if(ignoreFilters || !showMonth) {
            this.setState({ monthsWithData: null }, this.updateParent);
        } else if(autoUpdateSelectionFilters && this.state.selectedYear) {
            let monthsWithData;

            if(statementType) {
                monthsWithData = await MetaService.safe.getMonthsWithData(selectedCompanies, this.state.selectedYear, statementType);
            } else {
                monthsWithData = await fetchMonths(this.state.selectedYear);
            }

            this.setState({ monthsWithData }, this.updateParent);
        }
    }

    componentDidMount() {
        if(this.props.statementType || this.props.fetchYears) {
            this.updateYearsWithData();
        }
    }

    hasntUpdatedFromProps = (prevProps) => {
        let old = this.getSelectedFromProps(prevProps);
        let neu = this.getSelectedFromProps(this.props);

        return old.year === neu.year && old.month === neu.month;
    }

    componentDidUpdate(prevProps) {
        let { disabled, input, value } = this.props;
        
        if(prevProps.disabled !== disabled || !isEqual(prevProps.selectedCompanies, this.props.selectedCompanies) || prevProps.statementType !== this.props.statementType) {
            this.updateYearsWithData(true);
        } else if(this.hasntUpdatedFromProps(prevProps) && this.state.selectedYear && !get(this.props, "value.year")) {
            this.updateParent();
        } else if(input) {
            if(!prevProps.input) {
                return this.updateSelectionsFromProps();
            }

            let oldInput = get(prevProps.input, "value", prevProps.value);
            let newInput = get(input, "value", value);

            if(oldInput == "" || (Array.isArray(oldInput) && !oldInput.length))
                oldInput = { year: null, month: null };
                
            if(newInput == "" || (Array.isArray(newInput) && !newInput.length))
                newInput = { year: null, month: null };

            if(!isEqual(oldInput, newInput)) {
                return this.updateSelectionsFromProps();
            }
        }
    }
    
    render() {
        let { disabled, autoUpdateSelectionFilters, showMonth = true } = this.props;
        let { selectedYear, selectedMonth } = this.state;

        let monthDisabled = !!!selectedYear || disabled;

        let date = new Date();
        
        return (
            <div className="row">
                <YearSelector colspan={showMonth ? "col-6" : "col-12"}
                    disabled={disabled}
                    start={date.getUTCFullYear() - 25}
                    end={date.getUTCFullYear() + 25}
                    placeholder="Select a Year"
                    onChange={this.onChangeYear}
                    yearFilter={autoUpdateSelectionFilters ? this.state.yearsWithData : this.props.yearsWithData}
                    value={selectedYear} />

                {showMonth && (
                    <MonthSelector colspan="col-6"
                        disabled={monthDisabled}
                        placeholder="Select a Month"
                        monthFilter={autoUpdateSelectionFilters ? this.state.monthsWithData : this.props.monthsWithData}
                        value={selectedMonth}
                        onChange={this.onChangeMonth} />
                )}
            </div>
        );
    }
}