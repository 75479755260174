import { API } from "./index";
import { get, post, remove, put } from "../net";

class SchedulingService {
    async getSchedules() {
        let request = get({
            endpoint: "integration/qb/schedules",
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async addSchedule(details) {
        let request = post({
            endpoint: "integration/qb/schedules",
            data: details,
            watcher: this.watcher
        });

        console.log(details);

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async updateSchedule(id, details) {
        let request = put({
            endpoint: `integration/qb/schedules/${id}`,
            data: details,
            watcher: this.watcher
        });

        console.log(details);

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }

    async deleteSchedule(id) {
        let request = remove({
            endpoint: `integration/qb/schedules/${id}`,
            watcher: this.watcher
        });

        try {
            let { error, payload } = await request;
            return API.handleResponse(request, payload, error);
        } catch(ex) {
            return API.handleResponse(request, null, ex);
        }
    }
}

const inst = new SchedulingService();
export default inst;