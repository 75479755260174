const exports = {};

exports["integration:import:pending"] = () => ({
    icon: "sync"
});

exports["integration:import:success"] = () => ({
    icon: "check",
    color: "green"
});

exports["integration:import:fail"] = () => ({
    icon: "report",
    color: "red"
});

exports["integration:import:badMapping"] = ({ metadata }) => ({
    icon: "report",
    color: "yellow",
    actions: {
        listAction: () => global.redirect(`/admin/import?companyId=${encodeURIComponent(metadata.companyId)}&divisionId=${encodeURIComponent(metadata.divisionId)}`),
        notificationActions: [{
            label: "Resolve",
            onClick: () => global.redirect(`/admin/import?companyId=${encodeURIComponent(metadata.companyId)}&divisionId=${encodeURIComponent(metadata.divisionId)}`)
        }]
    }
});

exports["integration:import:unmapped"] = ({ metadata }) => ({
    icon: "sync_problem",
    color: "yellow",
    actions: {
        listAction: () => global.redirect(`/admin/import?failed=1&companyId=${encodeURIComponent(metadata.companyId)}`),
        notificationActions: [{
            label: "Resolve",
            onClick: () => global.redirect(`/admin/import?failed=1&companyId=${encodeURIComponent(metadata.companyId)}`)
        }]
    }
});

exports["integration:import:noData"] = () => ({
    icon: "cloud_off",
    color: "yellow"
});

export default exports;