//A patch to hack in Cash Flow support to the projections UI, so I don't have to rewrite the same code with tiny tweaks.
import { convertToTree } from "~/views/admin/statement/ReportParser";

import {
    CashFlow as CashFlowAccounts,
    IncomeStatement as IncomeStatementAccounts
} from "~/lib/tables/Accounts";

export const normalAccounts = [
    "ebitda adjustments",
    "total ebitda",
    "gross profit",
    "net income",
    //Cash Flow
    "ending cash",
    "change in cash",
    "change in operating activity",
    "change in investing activity",
    "change in financing activity"
];

export const shouldBeNormal = node =>
    (node.id && normalAccounts.includes(node.id.toString().toLowerCase())) || node.fake;

export const getType = (calculations, type, ebitda = false) =>
    Object.keys(calculations)
    .map(id => calculations[id])
    .filter(x => x.accountType === type && (ebitda ? x.ebitda === ebitda : true))
    .map(x => parseFloat(x.hasOwnProperty("calculated") ? x.calculated : x.amount))
    .reduce((a,b) => a + b, 0);

export const runCalculations = (isCashFlow, calculations) => {
    if(isCashFlow) {
        let operating = getType(calculations, "operating");
        let investing = getType(calculations, "investing");
        let financing = getType(calculations, "financing");
        let cash = getType(calculations, "cash");

        let change = operating + investing + financing;
        return {
            change,
            ending: cash + change
        };
    } else {
        let revenue = getType(calculations, "revenue");
        let expenses = getType(calculations, "expense");
        let cogs = getType(calculations, "cogs");

        let ebitdaRevenue = getType(calculations, "revenue", true);
        let ebitdaExpenses = getType(calculations, "expense", true);
        let ebitdaCogs = getType(calculations, "cogs", true);

        let grossProfit = revenue - cogs;
        let netIncome = grossProfit - expenses;
        
        return {
            grossProfit,
            netIncome,
            ebitda: netIncome + (ebitdaExpenses + ebitdaCogs - ebitdaRevenue)
        };
    }
};

export const getAccountType = (isCashFlow, standardAccountId) => {
    if(isCashFlow) {
        return {2000:"operating",2001:"investing",2002:"financing",2003:"cash"}[Number(standardAccountId)];
    } else {
        return {4: "revenue", 5: "cogs", 6: "expense"}[Number(standardAccountId)];
    }
};

export const convertMode = val => {
    if(/^\d$/.test(`${val}`.trim())) {
        return {2:"incomeStatement", 3:"budget",4:"forecast",6:"cashFlow"}[Number(val)];
    } else return {"budget":3,"forecast":4,"cashFlow":6}[val];
};

export const getNonParentAccounts = (isCashFlow, data) => {
    if(isCashFlow) {
        return data.filter(x => !([2000, 2001, 2002].includes(Number(x.StandardAccountId))));
    } else {
        return data.filter(x => !([4, 5, 6].includes(Number(x.StandardAccountId))));
    }
};

//Targets:
//Budget/Forecast - (1 = Revenue, 2 = Expense, 3 = CoGS, 4 = All)
//Cash Flow - (1 = Operating Activity, 2 = Investing Activity, 3 = Financing Activity, 4 = Beginning Cash, 5 = All)
export const runPercentageAdjustment = (isCashFlow, { calculations, standardAccounts }, setState, { target, percent }) => {
    percent = 1 + (Number(percent) / 100);
    let targetIds = [];
    
    if(isCashFlow) {
        if(target === 1) {
            targetIds = Object.keys(calculations).filter(x => x in standardAccounts[CashFlowAccounts.OperatingActivity].children);
        } else if(target === 2) {
            targetIds = Object.keys(calculations).filter(x => x in standardAccounts[CashFlowAccounts.InvestingActivity].children);
        } else if(target === 3) {
            targetIds = Object.keys(calculations).filter(x => x in standardAccounts[CashFlowAccounts.FinancingActivity].children);
        } else if(target === 4) {
            targetIds = [CashFlowAccounts.BeginningCash];
        } else if(target === 5) {
            targetIds = Object.keys(calculations);
        }
    } else {
        if(target === 1) {
            targetIds = Object.keys(calculations).filter(x => x in standardAccounts[IncomeStatementAccounts.Revenue].children);
        } else if(target === 2) {
            targetIds = Object.keys(calculations).filter(x => x in standardAccounts[IncomeStatementAccounts.Expense].children);
        } else if(target === 3) {
            targetIds = [
                IncomeStatementAccounts.CostOfGoodsSold,
                ...Object.keys(calculations).filter(x => x in standardAccounts[IncomeStatementAccounts.CostOfGoodsSold].children)
            ];
        } else if(target === 4) {
            targetIds = Object.keys(calculations);
        }
    }

    let newCalculations = {};

    targetIds.forEach(id => {
        let calc = calculations[id];

        if(!calc) return;

        newCalculations[id] = {
            type: "static",
            accountType: calc.accountType,
            ebitda: calc.ebitda
        };

        const tempAmount = calc.type === "calculated" ? calc.calculated : calc.amount;
        newCalculations[id].amount = (Number(tempAmount) * percent).toFixed(2);
    });

    setState({
        calculations: {...calculations, ...newCalculations}
    });
};

export const getTargetData = (isCashFlow, props) => {
    let { data = [], __raw, calculations } = props;
    let target = data;

    if(__raw && __raw.report) {
        target = convertToTree(1, { mapped: __raw.report }, isCashFlow ? "cashFlow" : "income-statement").mapped;
    }

    let advancedCalculations = runCalculations(isCashFlow, calculations);

    if(isCashFlow) {
        return target.map(x => {
            let row = {...x};
            
            if(row.children && row.children.length) {
                row.value = row.children.reduce((total, child, i) => {
                    let calculation = calculations[child.id];

                    if(calculation) {
                        let val = Number(calculation.type === "static" ? calculation.amount : calculation.calculated);

                        row.children[i].value = val;
                        total += val;
                    }
                    return total;
                }, 0);
            } else if(!x._hasParent) {
                let calculation = calculations[x.id];

                if(calculation) {
                    let val = Number(calculation.type === "static" ? calculation.amount : calculation.calculated);
                    row.value = val;
                }
            }

            if(x.id == "Change in Cash") {
                row.value = advancedCalculations.change;
            } else if(x.id == "Ending Cash") {
                row.value = advancedCalculations.ending;
            }

            return row;
        });
    } else {
        return target.map((x, index, array) => {
            let row = {...x, show: true};
            let adjustmentsRow;
            
            if(row.id === "EBITDA") {
                adjustmentsRow = row.children.pop();
                row.children = row.children.map(x => ({...x, fake: true }));
            }

            if(row.children && row.children.length) {
                row.value = row.children.reduce((total, child, i) => {
                    let calculation = calculations[child.id];

                    if(calculation) {
                        let val = Number(calculation.type === "static" ? calculation.amount : calculation.calculated);
                        if(row.id === "EBITDA" && child._ebitda && child.parentAccountId === 4) {
                            val = -val;
                        }
                        row.children[i].value = val;
                        total += val;
                    }
                    return total;
                }, 0);
            } else if(!x._hasParent) {
                let calculation = calculations[x.id];

                if(calculation) {
                    let val = Number(calculation.type === "static" ? calculation.amount : calculation.calculated);
                    row.value = val;
                }
            }

            const showGrossProfit = array.reduce((acc, x) => acc || ([IncomeStatementAccounts.Revenue, IncomeStatementAccounts.CostOfGoodsSold].includes(x.id) && x.children.length > 0) , false);
            
            //We fix EBITDA in post
            if(adjustmentsRow) {
                let revenue = getType(calculations, "revenue", true);
                let expenses = getType(calculations, "expense", true);
                let cogs = getType(calculations, "cogs", true);

                adjustmentsRow.value = expenses + cogs - revenue;
                adjustmentsRow.fake = true;
                row.children.push(adjustmentsRow);
                row.value = advancedCalculations.ebitda;
                row.fake = true;
            } else if(row.id === "Gross Profit") {
                row.value = advancedCalculations.grossProfit;
                row.fake = true;
                row.show = showGrossProfit;
            } else if(row.id === "Net Income") {
                delete row.highlight;
                row.value = advancedCalculations.netIncome;
                row.fake = true;
            }
            
            return row;
        });
    }
};

export default {
    normalAccounts,
    shouldBeNormal,
    getType,
    runCalculations,
    getAccountType,
    convertMode,
    getNonParentAccounts,
    runPercentageAdjustment,
    getTargetData
};