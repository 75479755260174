import React from "react";
import { store } from "./store";
import { Provider } from "react-redux";
import { loadLocalToken, startTokenJuggler } from "./services/auth";
import NewPermissionsDictionary from "./services/PermissionsDictionary";
import Routes from "./routes";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import greenColor from "@material-ui/core/colors/green";
import { ConfirmationModalProvider } from "~/components/material/Modal/ConfirmationModal";
import { PopupProvider } from "~/components/Popup";
import { SnackbarProvider } from "notistack";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { Provider as NotificationProvider } from "~/state/NotificationsStore";
import LoadingSpinner from "~/components/LoadingSpinner";

export const UserConfirmationContext = React.createContext();

global.forceLogout = goToWordpress => {
    localStorage.clear();
    window.location.href = goToWordpress ? "https://cfofxn.com/" : "/";
};

const theme = createMuiTheme({
    palette: {
        primary: {
            main: greenColor[500]
        }
    },

    typography: {
        useNextVariants: true
    },

    overrides: {
        MuiButtonBase: {
            root: {
                fontSize: ".8rem",
                padding: ".85rem 2.13rem",
                margin: "6px",
                borderRadius: "2px",
                whiteSpace: "normal",
                wordWrap: "break-word"
            }
        }
    }
});

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true });
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, info);
    }

    render() {
        if (this.state.hasError)
            return <p>An unexpected error has occurred that prevents the application from running. Please contact an administrator.</p>;
        
        return this.props.children;
    }
}

global.AppPermissions = NewPermissionsDictionary; //As a shim for old code
export default class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = { loaded: false };
    }

    componentDidMount() {
        loadLocalToken(() => this.setState({ loaded: true }));
        startTokenJuggler();
    }

    componentDidCatch(error, info) {
        console.error("Uncaught exception!");
        console.log(error);
        console.log(info);
    }

    setUserConfirmation = getUserConfirmation => this.setState({ getUserConfirmation });

    render() {
        if(this.state.loaded) {
            return (
                <Provider store={store}>
                    <MuiThemeProvider theme={theme}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <UserConfirmationContext.Provider value={{ getUserConfirmation: this.state.getUserConfirmation, setUserConfirmation: this.setUserConfirmation }}>
                                <SnackbarProvider anchorOrigin={{vertical:"bottom",horizontal:"right"}} maxSnack={4} classes={{root: "notification-wrapper"}}>
                                    <Routes />
                                    <NotificationProvider />
                                </SnackbarProvider>
                                <PopupProvider />
                                <ConfirmationModalProvider />
                            </UserConfirmationContext.Provider>
                        </MuiPickersUtilsProvider>
                    </MuiThemeProvider>
                </Provider>
            );
        } else return (
            <LoadingSpinner />
        );
    }
}