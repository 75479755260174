import { createTextMask } from "redux-form-input-masks";

export const phoneMask = createTextMask({
    pattern: "(999) 999-9999",
    placeholder: " "
});

export const zipcodeMask = createTextMask({
    pattern: "99999",
    guide: false
});

export const maxLength = (length = 50) => createTextMask({
    pattern: Array(length).fill("A").join("")
});

export const formatPhoneNumber = s => {
    let s2 = (""+s).replace(/\D/g, "");
    let m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
    return (!m) ? null : "(" + m[1] + ") " + m[2] + "-" + m[3];
};