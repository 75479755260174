import React from "react";
import * as Defaults from "~/lib/tables/DefaultValues";
import { Card } from "~/components/material";

export default function Disclaimer(){
    return (
        <div className="container disclaimer">
            <Card title="Export Disclaimer">
                <p>{Defaults.disclaimer}</p>
            </Card>
        </div>   
        );
}