import React from "react";

import { faEdit, faTrash, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import { Link } from "react-router-dom";
import AsyncComponent from "~/components/AsyncComponent";
import { Select, TextInput } from "~/components/material";
import Modal from "~/components/material/Modal/Modal";
import { AnalyticsLinksAPI } from "~/services/api";
import shortid from "shortid";
import UselessLink from "~/components/material/UselessLink";
import { isEmpty } from "lodash";
import { observer } from "mobx-react";
import { toJS } from "mobx";

import Alert from "~/services/ui/alerts";

import CompanyDropdown from "~/components/form/fields/CompanyDropdown";
import DivisionDropdown from "~/components/form/fields/DivisionDropdown";
import MonthDropdown from "~/components/form/fields/MonthSelector";
import YearDropdown from "~/components/form/fields/YearSelector";

import AnalyticsLinksModalGraphSelector from "./AnalyticsLinksModalGraphSelector";

import { Metadata as MetaService } from "~/services/api";
import analyticsStore from "./AnalyticsState"; 

@observer
export default class AnalyticsLinksModal extends AsyncComponent {

    constructor() {
        super();
        this.state = {
            anchorEl: null,
            open: false,
            mode: "add",
            modalOpen: false,
            confirmationModalOpen: false,
            links: [],
            reportTypes: [
                { label: "Actual", value: "actual" },
                { label: "Budget", value: "budget" },
                { label: "Forecast", value: "forecast" }
            ],
            selectedReportName: null,
            selectedReportType: null,
            selectedCompany: null,
            selectedDivision: null,
            selectedYear: null,
            selectedMonth: null,
            filteredYears: [],
            filteredMonths: [],
            showDates: false,
            selectedDatesGroup: []
        };
    }

    async componentDidMount() {
        const { error, payload = [] } = await AnalyticsLinksAPI.getAnalyticsLinks(this.props.userId);
        
        if (error) Alert.showError("An error was encountered while retrieving analytics.");

        this.setState({ links: payload });
    }

    handleClick = (event) => {
        this.setState({ ...this.state, anchorEl: event.currentTarget, open: true });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
            open: false,
            modalOpen: false,
            selectedReportName: null,
            selectedReportType: null,
            selectedCompany: null,
            selectedDivision: null,
            selectedYear: null,
            selectedMonth: null,
            filteredYears: [],
            filteredMonths: []
        });
    };

    handleModalOpen = async (analyticsLinkId) => {
        if (analyticsLinkId) {
            const selectedLink = this.state.links?.filter(link => link?.analyticsLinkId === analyticsLinkId);
            
            const selectedLinkData = isEmpty(selectedLink[0]?._data) ? {} : JSON.parse(selectedLink[0]?._data);
            this.setState(prev => ({
                ...prev,
                modalOpen: true,
                anchorEl: null,
                open: false,
                mode: "edit",
                analyticsLinkId,
                selectedReportName: selectedLinkData?.reportName,
                selectedCompany: {
                    id: selectedLinkData?.company
                },
                selectedDivision:  selectedLinkData?.division,
                selectedDatesGroup: Array.isArray(selectedLinkData?.reportData) ? selectedLinkData.reportData : []
                
            }), this.loadYearsWithData);
        } else {
            this.setState(prev => ({
                ...prev,
                modalOpen: true,
                anchorEl: null,
                open: false,
                mode: "add"
            }));
        }
    }

    handleModalClose = (event, link) => {
        if(link) {
            let clickedLinkData = Object.assign({}, JSON.parse(link._data));
            analyticsStore.setClickedLinkData(clickedLinkData);
        }

        this.setState({
            anchorEl: event?.currentTarget,
            open: false,
            modalOpen: false,
            selectedReportName: null,
            selectedReportType: null,
            selectedCompany: null,
            selectedDivision: null,
            selectedYear: null,
            selectedMonth: null,
            filteredYears: [],
            filteredMonths: []
        });
    }

    handleConfirmationModalOpen = () => {
        this.setState({ confirmationModalOpen: true });
    }

    handleConfirmationModalClose = () => {
        this.setState({ confirmationModalOpen: false });
    }

    validate = () => {
        if (this?.state?.selectedDatesGroup.length <= 1) {
            Alert.showError("Analytics report requires minimum of 2 dates");
            return false;
        }

        if (!this?.state?.selectedCompany?.id) {
            Alert.showError("Company is required");
            return false;
        }

        if (!this?.state?.selectedDivision) {
            Alert.showError("Division is required");
            return false;
        }

        return true;
    }

    saveAnalyticsLink = async () => {
        if (this.validate()) {
            let { selectedLinkMetrics } = analyticsStore;
            let selectedLinkMetricsFromStore = toJS(selectedLinkMetrics);

            const _data = {
                reportName: this?.state?.selectedReportName,
                company: this?.state?.selectedCompany?.id,
                selectedCompany: this?.state?.selectedCompany,
                division: this?.state?.selectedDivision,
                selectedDivision: this?.state?.division,
                reportData: this.state.selectedDatesGroup,
                selectedMetrics: selectedLinkMetricsFromStore
            };
            
            const payload = {
                userId: this.props.userId,
                _data: _data
            };
            if (this.state.mode === "edit") {
                const result = await AnalyticsLinksAPI.updateAnalyticsLink({
                    ...payload,
                    analyticsLinkId: this?.state?.analyticsLinkId
                });
                if (result?.req?.status != 200) return Alert.showError("An error was encountered while updating.");
                this.handleModalClose();
                Alert.showMessage("Analytics Links updated successfully");
            } else {
                const result = await AnalyticsLinksAPI.createAnalyticsLink(payload);
                if(result?.req?.status != 200) {
                    this.handleConfirmationModalOpen();
                    setTimeout(() => {
                        this.handleConfirmationModalClose();
                    }, 2000);
                    return;
                }
                this.handleModalClose();
                Alert.showMessage("Analytics Link created successfully");
            }

            await this.componentDidMount();
        }
    }

    handleDeleteLink = async (id) => {
        await AnalyticsLinksAPI.deleteAnalyticsLink(id);

        await this.componentDidMount();
    }

    onSelectReportName = e => {
        this.setState({ selectedReportName: e.target.value });
    }

    onSelectCompany = selectedCompany => {
        this.setState({ selectedCompany, selectedDivision: null, selectedYear: null, selectedMonth: null });
    }

    onSelectDivision = (selectedDivision) => {
        this.setState({ selectedDivision: selectedDivision.value, division: selectedDivision, selectedYear: null, selectedMonth: null });
    }

    onSelectReportType = (selectedReportType) => {
        this.setState({ selectedReportType,  selectedYear: null, selectedMonth: null }, this.loadYearsWithData);
    }


    onSelectYear = ({ value }) => {
        this.setState({ selectedYear: value, selectedMonth: null }, this.loadMonthsWithData);
    }

    onSelectMonth = ({ value }) => {
        this.setState({ selectedMonth: value });
    }

    getType =(selectedReportType)=> {
        let selectedType = 0;
        switch(selectedReportType) {
            case "actual":
                selectedType = 2;
                break;
            case "budget":
                selectedType = 3;
                break;
            case "forecast":
                selectedType = 4;
                break;
        }
        return selectedType;
    }

    loadYearsWithData = async () => {
        try {
            const selectedType = this.getType(this?.state?.selectedReportType?.value.toLowerCase());
            const yearsWithData = await MetaService.safe.getYearsWithData(this.state.selectedDivision, selectedType);

            const latest = await MetaService.safe.getLatestDateWithData(selectedType, this.state.selectedDivision);

            let newState = { filteredYears: yearsWithData };

            if (this.state?.selectedYear && this.state?.selectedMonth) {
                newState.selectedYear = this.state?.selectedYear;
                newState.selectedMonth = this.state?.selectedMonth;
            } else if (latest.year && latest.month) {
                newState.selectedYear = latest.year;
                newState.selectedMonth = latest.month;
            }

            if (newState.selectedYear) {
                const filteredMonths = await MetaService.safe.getMonthsWithData(this.state.selectedDivision, newState.selectedYear, selectedType);
                newState.filteredMonths = filteredMonths.map(x => x.Month);
            }

            this.setState(newState);
        } catch (ex) {
            console.log(ex);
            Alert.showGenericError();
        }
    }

    loadMonthsWithData = async () => {
        try {
            const selectedType = this.getType(this?.state?.selectedReportType?.value.toLowerCase());
            const filteredMonths = await MetaService.safe.getMonthsWithData(this.state.selectedDivision, this.state.selectedYear, selectedType);

            this.setState({ filteredMonths: filteredMonths.map(x => x.Month) });
        } catch (ex) {
            console.log(ex);
            Alert.showGenericError();
        }
    }

    showDateGroup = () => {
        this.setState({
            showDates: !this.state.showDates
        });
    }

    addNewDateRow = () => {
        this.setState({
            selectedDatesGroup: [
                ...this.state.selectedDatesGroup,
                { 
                    date: { year: this.state.selectedYear, month: this.state.selectedMonth }, 
                    id: shortid(),
                    reportType: this.state.selectedReportType 
                }
            ],
            selectedReportType: null,  
            selectedYear: null, 
            selectedMonth: null, 
            showDates: false
        });
    }

    removeDateFromGroup = (id) => {
        this.setState({
            selectedDatesGroup: this.state.selectedDatesGroup.filter(x => x.id !== id)
        }); 
    }

    getPath =(link)=> {
        return "/admin/income?mode=analytics&linkId=" + link.analyticsLinkId;
    }

    render() {
        const date = new Date();

        return (
            <React.Fragment>
                {this.props.isMobile ? (
                    <IconButton onClick={this.handleClick}>
                        <Icon>link</Icon>
                    </IconButton>
                ) : (
                    <span className="text-success" onClick={this.handleClick}>
                        <Link to="#">
                            <img
                                src="/res/graph-links.svg"
                                alt="analytics icon"
                                title="Analytics Links"
                                height={65}
                            />
                        </Link>
                    </span>
                )}
                <Popover
                    id="simple-popover"
                    open={this.state.open}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left"
                    }}
                >
                    <Typography className="p-3">
                        <div className="w-100 d-flex justify-content-center align-items-center flex-column">
                            <img src="/res/graph-links.svg" alt="analytics links logo" width="50px" />
                            <span className="font-weight-bold">Analytics Links</span>
                        </div>
                        <br />
                        <table className="table" style={{ width: "300px" }}>
                            <thead className="thead-dark">
                                <tr>
                                    <th align="center" scope="col" className="d-flex justify-content-between align-items-center p-2">
                                        <span>Report Name</span>
                                        <button className="btn btn-sm btn-success font-weight-bold" onClick={() => this.handleModalOpen()}>ADD NEW LINK</button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {this.state.links?.map(link => (
                                        <td key={link.analyticsLinkId} className="d-flex justify-content-between align-items-center p-2" style={{ height: "50px" }}>
                                            <Link to={this.getPath(link)} className="text-primary" onClick={(e) => this.handleModalClose(e, link)}>{JSON.parse(link._data)?.reportName}</Link>
                                            <div>
                                                <IconButton
                                                    text-color="gray"
                                                    size="small"
                                                    onClick={() => this.handleModalOpen(link.analyticsLinkId)}
                                                    
                                                >
                                                    <FontAwesomeIcon icon={faEdit} style={{ cursor: "pointer" }} />
                                                </IconButton>
                                                {" "}
                                                <IconButton
                                                    text-color="red"
                                                    size="small"
                                                    onClick={() => this.handleDeleteLink(link.analyticsLinkId)}
                                                >
                                                    <FontAwesomeIcon icon={faTrash} style={{ cursor: "pointer" }} />
                                                </IconButton>
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </Typography>
                </Popover>
                <Modal
                    controlled
                    visible={this.state.modalOpen}
                    footer={<Footer onCloseModal={this.handleModalClose} mode={this.state.mode} saveAnalyticsLink={this.saveAnalyticsLink} />}
                    title={"Custom Analytics Link Editor"}
                    hideLines
                    showClose={false}
                    titleClass={"mx-auto font-weight-bold"}>
                    <TextInput
                        name="Report Name"
                        label="Report Name"
                        placeholder="Report Name"
                        value={this.state.selectedReportName}
                        required={true}
                        show-required="true"
                        onChange={this.onSelectReportName}
                        labelActive={true}
                    />
                    {
                        <CompanyDropdown
                            onSelectCompany={this.onSelectCompany}
                            value={this.state.selectedCompany?.id}
                        />
                    }
                    {this.state.selectedCompany && (
                        <DivisionDropdown requiredCompanyPermission={`${this.state.mode}.update`}
                            value={this.state.selectedDivision}
                            companyId={this.state.selectedCompany?.id}
                            includeCompany
                            selectedCompany={this.state.selectedCompany?.raw}
                            companyLabel={this.state.selectedCompany?.name}
                            onSelectDivision={this.onSelectDivision} />
                    )}
                    {this.state.showDates && (
                        <>
                            <Select
                            label="Report Type"
                            placeholder="Report Type"
                            value={this.state.selectedReportType}
                            data={this.state.reportTypes}
                            required={true}
                            show-required="true"
                            onChange={this.onSelectReportType} />
                            {this.state.selectedReportType && (
                                <YearDropdown
                                    start={date.getUTCFullYear() - 25}
                                    end={date.getUTCFullYear() + 25}
                                    placeholder={"Select a Year"}
                                    value={this.state.selectedYear}
                                    yearFilter={this.state.filteredYears}
                                    onChange={this.onSelectYear} />
                            )}
                            {this.state.selectedYear && (
                                <MonthDropdown forceExistsCheck
                                    placeholder="Select Month"
                                    value={this.state.selectedMonth}
                                    onChange={this.onSelectMonth}
                                    monthFilter={this.state.filteredMonths}
                                    noResultsText={this.divisionErrMsg} />
                            )}
                            {
                                this.state.selectedMonth && (
                                    <button type="button" className="btn btn-success w-100" color="green" text-color="white" onClick={this.addNewDateRow}>
                                       Add to Date Group
                                    </button>
                                )
                            }
                        </>
                    )}
                    {this.state.selectedDivision && 
                    (
                        <>
                            <Link to="#" onClick={this.showDateGroup}><FontAwesomeIcon icon={faPlus} /> Add Dates</Link>
                        </>
                    )
                    }
                    {
                        this.state.selectedDatesGroup.length > 1 && (
                            <AnalyticsLinksModalGraphSelector />
                        )
                    }
                    {
                        this.state.selectedDatesGroup.length > 0 && (
                            <table  className="w-100">
                                <tbody>
                                    {
                                        this.state.selectedDatesGroup.map((x, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td><span  className="w-100">{x?.reportType.label} {x?.date?.year} {MONTHS[+x?.date?.month - 1]}</span></td>
                                                    <td className="p-1" style={{width: "3rem"}}><UselessLink onClick={() => this.removeDateFromGroup(x?.id)}><h3>&times;</h3></UselessLink></td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        )
                    }
                    
                </Modal>
                <Modal
                    visible={this.state.confirmationModalOpen}
                    head={false}
                    showClose={false}
                    bodyClass={"bg-danger text-light"}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4
                    }}>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Internal Server error or a duplicate bookkeeping link name. Try some time later or try changing the bookkeeping link name.
                    </Typography>
                    </Box>
                </Modal>
            </React.Fragment>
        );
    }
}

const Footer = ({ saveAnalyticsLink, mode, onCloseModal }) => (
    <div className="row">
        <button type="button"
            className="btn btn-success"
            onClick={saveAnalyticsLink}>{mode === "edit" ? "UPDATE" : "SAVE"}</button>
        <button type="button"
            className="btn btn-flat waves-effect"
            onClick={onCloseModal}>CANCEL</button>
    </div>
);

const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
]; 
