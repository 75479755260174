import * as SummaryParser from "./parsers/SummaryReportParser";
import * as ConsolidatedParser from "./parsers/ConsolidatedReportParser";
import * as ComparativeParser from "./parsers/ComparativeReportParser";
import { IncomeStatement as IncomeStatementAccounts } from "~/lib/tables/Accounts";

import genId from "shortid";
import sortBy from "lodash/sortBy";


//The API now sends the mapped and unmapped data independently, so we need to take it out and only work with the mapped data (like we used to)
//Then, we show the unmapped data in a separate UI piece
//report = {
//    active:   ...,
//    inactive: ...
//}
export const parse = (type, report, mode, values) => {
    let sorters = ["Active", "StandardAccountId"];

    if(type === 2) {
        sorters.push("CompanyId");
    } else if([3, 4, 5].includes(type)) {
        sorters.push("Year", "Month");
    }

    if(mode !== "balance-sheet")
        sorters.push("EBITDA");
    
    let accounts = sortBy(report.active, ...sorters);

    if([1, 6].includes(type)) { //Summary, Assumptions
        return {
            mapped: SummaryParser.parse(accounts, mode, false, type),
            unmapped: report.inactive
        };
    } else if(type === 2) { //Consolidated
        return {
            mapped: ConsolidatedParser.parse(accounts, mode, values),
            unmapped: report.inactive
        };
    } else if([3, 4, 5].includes(type)) { //Comparative, 2YComparative, BFComparison
        accounts.dates = report.dates;
        accounts.Dates = report.Dates;

        return {
            mapped: ComparativeParser.parse(accounts, mode, type),
            unmapped: report.inactive
        };
    } else if (type === 7) { //Consolidation (System Reports)
        return {};
    }
};

export const calculateNetIncome = (report, type, tree, rawReport) => {
    let grossProfit = calculateGrossProfit(rawReport || report, type, tree);
    if([1, 6].includes(type)) {
        let expense = report.Raw.filter(x => x.ParentAccountId == IncomeStatementAccounts.Expense).reduce((a, {Amount:b = 0}) => a + b, 0);
        return grossProfit - expense;
    } else if(type === 2) {
        let expense = tree.parsed.find(x => x.id == IncomeStatementAccounts.Expense) || {value: [0]};
        
        return Array(report.Companies.length + 1).fill(0).map((_,i) => {
            let r = grossProfit[i] || 0;
            let e = expense.value[i] || 0;

            return r - e;
        });
    } else if([3, 4, 5].includes(type)) {
        let expense = tree.parsed.find(x => x.id == IncomeStatementAccounts.Expense) || {value: [0]};

        let vals = Array(report.Dates.length).fill(0).map((_,i) => {
            let r = grossProfit[i] || 0;
            let e = expense.value[i] || 0;

            return r - e;
        });
        
        if(type === 4) {
            vals[2] = vals[0] - vals[1];
            vals[3] = (vals[2] / vals[1]) * 100;
        }

        return vals;
    }
};

export const getCostOfGoodsSold = (report, type, tree) => {
    if([1, 6].includes(type)) {
        let cgs = report.Raw.filter(x => x.ParentAccountId == IncomeStatementAccounts.CostOfGoodsSold); //TODO: Get By Id
        return cgs.reduce((a, {Amount:b = 0}) => a + b, 0);
    } else if(type === 2) {
        let cgs = tree.parsed.find(x => x.id == IncomeStatementAccounts.CostOfGoodsSold) || {value: Array(report.Companies.length + 1).fill(0)};
        return Array(report.Companies.length + 1).fill(0).map((_,i) => cgs.value[i] || 0);
    } else if([3, 4, 5].includes(type)) {
        let cgs = tree.parsed.find(x => x.id == IncomeStatementAccounts.CostOfGoodsSold) || {value: [0]};
        
        if(type === 3 || type === 5) {
            return Array(report.Dates.length).fill(0).map((_,i) => cgs.value[i] || 0);
        } else {
            return Array(report.Dates.length + 2).fill(0).map((_,i) => cgs.value[i] || 0);
        }
    }
};

export const calculateGrossProfit = (report, type, tree) => {
    let cgs = getCostOfGoodsSold(report, type, tree);

    if([1, 6].includes(type)) {
        let revenue = report.Raw.filter(x => x.ParentAccountId == IncomeStatementAccounts.Revenue).reduce((a, {Amount: b = 0}) => a + b, 0);
        return revenue - cgs;
    } else if(type === 2) {
        let revenue = report.Raw.filter(x => x.ParentAccountId == IncomeStatementAccounts.Revenue);
        let len = report.Companies.length;
        let vals = [];
        
        let cRevenue = 0;
        for(let i = 0; i < len; i++) {
            let companyId = report.Companies[i];
            let fRevenue = revenue.filter(x => x.CompanyId == companyId).reduce((a, {Amount: b = 0}) => a + b, 0);
            vals[i] = fRevenue - cgs[i];
            cRevenue += vals[i];
        }
        //FDS-234: Commenting below logic as now consolidated revenue will be sum of companies shown in columns
        //let cRevenue = revenue.filter(x => x.Consolidated && x.CompanyId == 0).reduce((a, {Amount: b = 0}) => a + b, 0);
        //vals.push(cRevenue - cgs[cgs.length - 1]);
        vals.push(cRevenue);
        
        return vals;
    } else if([3, 4, 5].includes(type)) {
        let revenue = report.Raw.filter(x => x.ParentAccountId == IncomeStatementAccounts.Revenue).map(x => x.Amounts);
        let { Dates: dates } = report;
        let vals = [];

        dates.forEach((_,i) => {
            let dateRevenue = revenue.reduce((a, b) => a + b[i], 0);
            vals[i] = dateRevenue - cgs[i];
        });

        if(type === 4) {
            vals[2] = vals[0] - vals[1];
            vals[3] = (vals[2] / vals[1]) * 100;
        }
        
        return vals;
    }
};

export const calculateEBITDA = (report, type, tree, rawReport) => {
    let netIncome = calculateNetIncome(rawReport || report, type, tree);

    if([1, 6].includes(type)) {
        let revenue = report.Raw.filter(x => x.ParentAccountId == IncomeStatementAccounts.Revenue && x.EBITDA).reduce((a, {Amount: b = 0}) => a + b, 0);
        let expense = report.Raw.filter(x => x.ParentAccountId == IncomeStatementAccounts.Expense && x.EBITDA).reduce((a, {Amount: b = 0}) => a + b, 0);

        return netIncome + (expense - revenue);
    } else if(type === 2) {
        var adjustments = calculateEBITDAdjustments(report, type, tree);
        let vals = report.Companies.map((_,i) => netIncome[i] + adjustments[i]);
        vals.push(netIncome[netIncome.length - 1] + adjustments[adjustments.length - 1]);

        return vals;
    } else if([3, 4, 5].includes(type)) {
        let revenue = report.Raw.filter(x => x.ParentAccountId == IncomeStatementAccounts.Revenue && x.EBITDA).map(x => x.Amounts);
        let expense = report.Raw.filter(x => x.ParentAccountId == IncomeStatementAccounts.Expense && x.EBITDA).map(x => x.Amounts);
        let { Dates: dates } = report;
        let vals = [];

        dates.forEach((_,i) => {
            let dateRevenue = revenue.reduce((a, b) => a + b[i], 0);
            let dateExpense = expense.reduce((a, b) => a + b[i], 0);

            vals[i] = netIncome[i] + (dateExpense - dateRevenue);
        });
        
        if(type === 4) {
            vals[2] = vals[0] - vals[1];
            vals[3] = (vals[2] / vals[1]) * 100;
        }
        
        return vals;
    }
};

export const calculateEBITDAdjustments = (report, type, tree) => {
    if([1, 6].includes(type)) {
        let expense = report.Raw.filter(x => x.ParentAccountId == IncomeStatementAccounts.Expense).reduce((a, {Amount: b = 0}) => a + b, 0);
        let revenue = report.Raw.filter(x => x.ParentAccountId == IncomeStatementAccounts.Revenue).reduce((a, {Amount: b = 0}) => a + b, 0);

        return expense - revenue;
    } else if(type === 2) {
        let expense = report.Raw.filter(x => x.ParentAccountId == IncomeStatementAccounts.Expense);
        let revenue = report.Raw.filter(x => x.ParentAccountId == IncomeStatementAccounts.Revenue);

        let vals = report.Companies.map(_ => (expense.filter(x => x.CompanyId == _).reduce((a, {Amount: b = 0}) => a + b, 0)) - (revenue.filter(x => x.CompanyId == _).reduce((a, {Amount: b = 0}) => a + b, 0)));
        vals.push(vals.reduce((a,b) => a+b, 0));

        return vals;
    } else if([3, 4, 5].includes(type)) {
        let expense = report.Raw.filter(x => x.ParentAccountId == IncomeStatementAccounts.Expense).map(x => x.Amounts);
        let revenue = report.Raw.filter(x => x.ParentAccountId == IncomeStatementAccounts.Revenue).map(x => x.Amounts);

        let vals = [];
        for(let i = 0; i < report.Dates.length; i++) {
            vals.push((expense.reduce((a,b) => a + b[i], 0)) - (revenue.reduce((a,b) => a + b[i], 0)));
        }
        
        if(type === 4) {
            vals[2] = vals[0] - vals[1];
            vals[3] = (vals[2] / vals[1]) * 100;
        }

        return vals;
    }
};

const calculateEndingCash = (report, type, tree) => {
    if([1,6].includes(type)) {
        let changeInCash = tree.filter(x => x.id == "Change in Cash").reduce((a,{value:b=0}) => a+b, 0);
        let beginningCash = tree.filter(x => x.id == 2003).reduce((a,{value:b=0}) => a+b, 0);
        return changeInCash + beginningCash;
    } else if(type == 2) {
        let changeInCash = tree.parsed.filter(x => x.id == "Change in Cash").map(x => x.value);
        let beginningCash = tree.parsed.filter(x => x.id == 2003).map(x => x.value);

        let vals = report.Companies.map((_,i) => changeInCash.reduce((a,b) => a + b[i], 0) + beginningCash.reduce((a,b) => a + b[i], 0));
        vals.push(changeInCash.reduce((a,b) => a + b[b.length - 1], 0) + beginningCash.reduce((a,b) => a + b[b.length - 1], 0));

        return vals;
    } else if([3,4,5].includes(type)) {
        let changeInCash = tree.parsed.filter(x => x.id == "Change in Cash").map(x => x.value);
        let beginningCash = tree.parsed.filter(x => x.id == 2003).map(x => x.value);

        let vals = [];
        for(let i = 0; i < report.Dates.length; i++) {
            vals.push(changeInCash.reduce((a,b) => a + b[i], 0) + beginningCash.reduce((a,b) => a + b[i], 0));
        }
        
        if(type === 4) {
            vals[2] = vals[1] - vals[0];
            vals[3] = (vals[2] / vals[0]) * 100;
        }

        return vals;
    }
};

const calculateChangeInCash = (report, type, tree) => {
    if([1,6].includes(type)) {
        return report.Raw.filter(x => x.ParentAccountId).reduce((a,{Amount:b=0}) => a+b, 0);
    } else if(type == 2) {
        let amounts = report.RawReport.filter(x => x.ParentAccountId).map(x => x.Amounts);

        let vals = report.Companies.map((_,i) => amounts.reduce((a,b) => a + b[i], 0));
        vals.push(amounts.reduce((a,b) => a + b[b.length - 1], 0));

        return vals;
    } else if([3,4,5].includes(type)) {
        let amounts = report.Raw.filter(x => x.ParentAccountId).map(x => x.Amounts);
        let vals = [];
        for(let i = 0; i < report.Dates.length; i++) {
            vals.push(amounts.reduce((a,b) => a + b[i], 0));
        }
        
        if(type === 4) {
            vals[2] = vals[1] - vals[0];
            vals[3] = (vals[2] / vals[0]) * 100;
        }

        return vals;
    }
};

export const convertToTree = (type, report, mode) => {
    let tree;

    let highlightParser = x => {
        if(x.label === "Total Liabilities & Equity") {
            x.highlight = "green";
        }

        return x;
    };

    if([1, 6].includes(type)) {
        tree = SummaryParser.convertToTree(report.mapped, mode).map(highlightParser);
    } else if(type === 2) {
        tree = ConsolidatedParser.convertToTree(report.mapped, mode);
        tree.parsed = tree.parsed.map(highlightParser);
    } else if([3, 4, 5].includes(type)) {
        tree = ComparativeParser.convertToTree(report.mapped, mode);
        tree.parsed = tree.parsed.map(highlightParser);
    }

    if(mode === "cashFlow") {
        if([1,6].includes(type)) {
            tree.push({
                id: "Change in Cash",
                label: "Change in Cash",
                bold: true,
                fake: true,
                border: "green",
                value: calculateChangeInCash(report.mapped, type, tree)
            });

            tree.push({
                id: "Ending Cash",
                label: "Ending Cash",
                bold: true,
                fake: true,
                border: "green",
                value: calculateEndingCash(report.mapped, type, tree)
            });
        } else {
            tree.parsed.push({
                id: "Change in Cash",
                label: "Change in Cash",
                bold: true,
                fake: true,
                border: "green",
                value: calculateChangeInCash(report.mapped, type, tree)
            });

            tree.parsed.push({
                id: "Ending Cash",
                label: "Ending Cash",
                bold: true,
                fake: true,
                border: "green",
                value: calculateEndingCash(report.mapped, type, tree)
            });
        }
    } else if(mode !== "balance-sheet") {
        let EBITDA;
        if([1, 6].includes(type)) {
            EBITDA = report.mapped.Raw.filter(x => x.EBITDA);
            
            tree.push({
                id: "Net Income",
                label: "Net Income",
                fake: true,
                bold: true,
                border: "green",
                value: calculateNetIncome(report.mapped, type, tree)
            });

            tree.push({
                id: "Gross Profit",
                label: "Gross Profit",
                fake: true,
                bold: true,
                border: "green",
                value: calculateGrossProfit(report.mapped, type, tree)
            });
        } else if(type === 2) {
            EBITDA = report.mapped.Raw.filter(x => x.EBITDA);
            
            tree.parsed.push({
                id: genId(),
                label: "Net Income",
                fake: true,
                bold: true,
                border: "green",
                value: calculateNetIncome(report.mapped, type, tree)
            });

            tree.parsed.push({
                id: genId(),
                label: "Gross Profit",
                fake: true,
                bold: true,
                border: "green",
                value: calculateGrossProfit(report.mapped, type, tree)
            });
        } else if([3, 4, 5].includes(type)) {
            EBITDA = report.mapped.Raw.filter(x => x.EBITDA);
            
            tree.parsed.push({
                id: genId(),
                label: "Net Income",
                fake: true,
                bold: true,
                border: "green",
                value: calculateNetIncome(report.mapped, type, tree)
            });

            tree.parsed.push({
                id: genId(),
                label: "Gross Profit",
                fake: true,
                bold: true,
                border: "green",
                value: calculateGrossProfit(report.mapped, type, tree)
            });
        }

        if(EBITDA.length) {
            let ebitda = {
                StandardAccountId: "EBITDA",
                Name: "EBITDA"
            };
            
            if([1, 6].includes(type)) { // Summary
                let parsed = SummaryParser.parse(EBITDA, mode, false, type);
                ebitda.Amount = calculateEBITDA(report.mapped, type, tree);
                ebitda.children = parsed.Raw;

                ebitda.children.push({
                    StandardAccountId: "EBITDA Adjustments",
                    Name: "EBITDA Adjustments",
                    Amount: calculateEBITDAdjustments(parsed, type, tree),
                    Border: "green",
                    SortPriority: -99999999999999,
                    Bold: true
                });

                tree.push(SummaryParser.convertItem(ebitda));
            } else if(type === 2) { // Consolidated Note: 2023Mar21:Change in business logic: consolidate column now holds totals of other horizontal values for columns to the left
                let parsed = SummaryParser.parse(EBITDA.filter(x => x.CompanyId != 0), mode, false, type);

                let _ebitda = {
                    Raw: EBITDA,
                    Companies: report.mapped.Companies.filter(x => x.CompanyId != 0)
                };
                
                ebitda.Amount = calculateEBITDA(_ebitda, type, tree, report.mapped);
                ebitda.children = parsed.Raw.map(row => {
                    let matches = EBITDA.filter(x => row.StandardAccountId == x.StandardAccountId);

                    let amounts = _ebitda.Companies.map(companyId => {
                        let result = matches.find(x => x.CompanyId == companyId);

                        if(result) return result.Amount;

                        return 0;
                    });

                    /*
                    Change in business logic: consolidate column now holds totals of other horizontal columns to the left
                    */
                    //amounts.push(matches.find(x => x.CompanyId != 0).Amount);
                    amounts.push(amounts.reduce((a,b) => a + b, 0));

                    row.Amounts = amounts;
                    delete row.Amount;

                    return row;
                }).filter(x => x.Amounts[x.Amounts.length-1] > 0);
                
                ebitda.children.push({
                    id: genId(),
                    Name: "EBITDA Adjustments",
                    Amount: calculateEBITDAdjustments(_ebitda, type, tree),
                    Border: "green",
                    SortPriority: -99999999999999,
                    Bold: true
                });

                tree.parsed.push(SummaryParser.convertItem(ebitda));
            } else if([3, 4, 5].includes(type)) { // Comparative
                let parsed = SummaryParser.parse(EBITDA, mode, false, type);
                parsed.Dates = report.mapped.Dates;
                ebitda.Amount = calculateEBITDA(report.mapped, type, tree);
                ebitda.children = parsed.Raw;

                ebitda.children.push({
                    id: genId(),
                    Name: "EBITDA Adjustments",
                    Amount: calculateEBITDAdjustments(parsed, type, tree),
                    Border: "green",
                    SortPriority: -99999999999999,
                    Bold: true
                });

                tree.parsed.push(SummaryParser.convertItem(ebitda));
            }
        }
    }

    if([1, 6].includes(type)) {
        let sorted = SummaryParser.sortItems(tree, mode);
        
        return {
            mapped: sorted,
            hasUnmapped: report.unmapped && report.unmapped.length > 0
        };
    } else {
        tree.parsed = SummaryParser.sortHierarchy(tree.parsed, mode);
        return {
            mapped: tree,
            hasUnmapped: report.unmapped && report.unmapped.length > 0
        };
    }
};