//POPUP - Full-page modal, essentially
import React, { Component } from "react";
import Portal from "~/components/Portal";
import $ from "~/lib/elemutil";
import except from "lodash/omit";

export default class Popup extends Component {
    constructor(props) {
        super(props);
        this.state = { visible: props.visible || false };
    }

    onTryClose(e) {
        e.preventDefault();

        this.setState({
            visible: !this.state.visible
        });
    }

    componentDidUpdate() {
        if(this.state.visible) {
            $("body").addClass("modal-open");
        } else {
            $("body").removeClass("modal-open");
        }
    }

    componentDidMount() {
        $("body").addClass("modal-open");
    }

    componentWillUnmount() {
        $("body").removeClass("modal-open");
    }

    render() {
        let { showCloseButton = false, title = "Generic Popup", header = null, portal, ...props } = this.props;
        let { visible } = this.state;

        let classes = [
            "Popup",
            this.props.className,
            visible ? "show" : undefined
        ];

        props = except(props, "className", "header", "visible");
        let contents = (
            <div {...props} className={classes.join(" ")} ref={ref => this.popup = ref}>
                {showCloseButton && (
                    <button type="button" className="Popup-close" onClick={e => this.onTryClose(e)}></button>
                )}

                <div className="Popup-content">
                    {(header || title) && (
                        <React.Fragment>
                            {title && (
                                <div className="Popup-title">{title}</div>
                            )}
                            {header}
                        </React.Fragment>
                    )}
                    {this.props.children}
                </div>
            </div>
        );

        if(portal) {
            return (
                <Portal>{contents}</Portal>
            );
        } else return contents;
    }
}